import React, { useState, useEffect } from 'react';
import * as dateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt';

import { Header, PageHeaderDashboard } from 'presentation/components/header';

import {
  GetConferenceDataDashboard,
  GetUserDataDashboard,
} from 'domain/usecases/dashboard/remote';
import { makeRemoteGetConferenceDataDashboard } from 'main/factories/usecases/dashboard/GetConferenceDataDashboardFactory';
import { makeRemoteGetUserDataDashboard } from 'main/factories/usecases/dashboard/GetUserDataDashboardFactory';
import { makeRemoteGetConferenceHistoryDashboard } from 'main/factories/usecases/dashboard/GetConferenceHistoryDashboardFactory';
import {
  Container,
  Body,
  TimeFilterContainer,
  TimeFilter,
} from './styles/styledDashboard';
import UserData from './userData';
import ListUserInfo from './listUserInfo';

interface dataHistory {
  time: string;
  records: number;
  notRecords: number;
}

const DashboardUser: React.FC = (): JSX.Element => {
  const [org, setOrg] = useState('All');
  const [unit, setUnit] = useState('All');
  const [dateFrom, setDateFrom] = useState('');
  const [period, setPeriod] = useState('All');
  const [conferenceData, setConferenceData] =
    useState<GetConferenceDataDashboard.Model>({});
  const [userData, setUserData] = useState<GetUserDataDashboard.Model>({});
  const [conferenceHistory, setConferenceHistory] = useState<dataHistory[]>([]);

  const getScale = (p: string) => {
    if (p === 'All' || p === 'lastYear') return 'month';
    return p === 'lastMonth' ? 'day' : 'hour';
  };

  useEffect(() => {
    const until =
      period === 'today'
        ? `${dateFns.format(new Date(), 'yyyy-MM-dd')}T23:59`
        : dateFns.format(new Date(), 'yyyy-MM-dd');

    makeRemoteGetConferenceDataDashboard()
      .getConferenceData({
        query: {
          ...(org !== 'All' ? { org: Number(org) } : {}),
          ...(unit !== 'All' ? { unit: Number(unit) } : {}),
          until,
          ...(dateFrom !== '' ? { from: dateFrom } : {}),
        },
      })
      .then(res => {
        setConferenceData(res);
      });

    makeRemoteGetUserDataDashboard()
      .getUserData({
        query: {
          ...(org !== 'All' ? { org: Number(org) } : {}),
          ...(unit !== 'All' ? { unit: Number(unit) } : {}),
          until,
          ...(dateFrom !== '' ? { from: dateFrom } : {}),
          disablePagination: true,
        },
      })
      .then(res => {
        setUserData(res);
      });

    makeRemoteGetConferenceHistoryDashboard()
      .getConferenceHistory({
        query: {
          ...(org !== 'All' ? { org: Number(org) } : {}),
          ...(unit !== 'All' ? { unit: Number(unit) } : {}),
          scale: getScale(period),
          until,
          ...(dateFrom !== '' ? { from: dateFrom } : {}),
        },
      })
      .then(res => {
        const auxConferenceHistory: dataHistory[] = [];

        if (res.history !== undefined) {
          const data = res.history[0];
          data.xAxis.forEach((item, index) => {
            let time = item;

            if (getScale(period) === 'month')
              time = dateFns.format(new Date(item), 'MMM', {
                locale: ptBR,
              });
            else if (getScale(period) === 'day')
              time = dateFns.format(new Date(item), 'dd/MM');
            else if (getScale(period) === 'hour')
              time = dateFns.format(new Date(item), 'HH:mm');

            auxConferenceHistory.push({
              time,
              records: data.yAxis[0].data[index],
              notRecords: data.yAxis[1].data[index],
            });
          });
        }

        setConferenceHistory(auxConferenceHistory);
      });
  }, [org, unit, dateFrom, period]);

  return (
    <Container>
      <div style={{ position: 'fixed', width: '100%' }}>
        <Header title="teste" />
        <PageHeaderDashboard
          org={org}
          setOrg={setOrg}
          unit={unit}
          setUnit={setUnit}
          period={period}
          setPeriod={setPeriod}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
        />
      </div>
      <Body style={{ marginTop: '138px' }}>
        <UserData userData={userData} />
        <ListUserInfo ranking={userData.users?.ranking} />
      </Body>
    </Container>
  );
};

export default DashboardUser;
