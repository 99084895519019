import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { iStore } from 'domain/interfaces/models';
import { History } from 'main/routes';
import { Appointment } from 'presentation/pages';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import { makeReduxListConferenceFilteredByACLV2 } from '../../usecases/conference/ListConferenceFilteredByACLV2Factory';
import { makeReduxListOrgAclFilteredV2 } from '../../usecases/org/ListOrgAclFilteredV2Factory';
import { makeReduxListOrgUnitAclFilteredV2 } from '../../usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';

interface iParamsTypes {
  language: string;
}

export const MakeAppointmentFactory: React.FC = () => {
  const { language } = useParams<iParamsTypes>();
  const user = useSelector((store: iStore) => store.auth.user);

  if (language && language.length === 5 && language.split('-').length === 2) {
    localStorage.setItem('i18nextLng', language);
    History.getHistory().push('/');
    window.location.reload();
  } else if (language) {
    History.getHistory().replace('/');
  }

  useEffect(() => {
    if (!user) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    makeReduxListConferenceFilteredByACLV2().list({
      query: {
        limit: 9999,
      },
    });

    makeReduxListOrgAclFilteredV2().list({
      limit: 9999,
    });

    makeReduxListOrgUnitAclFilteredV2().list({
      limit: 9999,
    });

    UpdateListOrgUser(role, user?.org?.id, user?.orgUnit?.id);
  }, [user]);

  return <Appointment />;
};
