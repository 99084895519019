import { iOrg } from 'domain/interfaces/models/Org';
import { OrgTypes } from 'domain/interfaces/redux/org/types';
import { OrgActions } from './actions';

export const initialState: iOrg = {
  loading: false,
  metadata: undefined,
  records: undefined,
};

const reducer = (state = initialState, action: OrgActions): iOrg => {
  switch (action.type) {
    case OrgTypes.LIST:
      return { ...state, loading: true };
    case OrgTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OrgTypes.LIST_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case OrgTypes.LIST_ACL_FILTERED:
      return { ...state, loading: true };
    case OrgTypes.LIST_ACL_FILTERED_FAILED:
      return { ...state, loading: false };
    case OrgTypes.LIST_ACL_FILTERED_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case OrgTypes.LIST_ACL_FILTERED_V2:
      return { ...state, loading: true };
    case OrgTypes.LIST_ACL_FILTERED_V2_FAILED:
      return { ...state, loading: false };
    case OrgTypes.LIST_ACL_FILTERED_V2_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case OrgTypes.SET: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
