import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const InputFields = styled.div`
  width: 50%;

  margin-top: 26px;
  margin-bottom: 20px;

  .email {
    margin-top: 20px;
  }
`;

export const InputPhoneFields = styled.div`
  display: flex;

  margin-top: 20px;
  gap: 20px;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;
