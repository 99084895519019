import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

export const MakeJoinConferenceFactory: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();

  const params = useMemo(
    () => ({
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? undefined,
      m: new URLSearchParams(search).get('m') ?? false,
      c: new URLSearchParams(search).get('c') ?? false,
    }),
    [search],
  );

  useEffect(() => {
    if (params?.t) {
      let query = '';

      Object.entries(params).forEach(([key, value]) => {
        if (value) query += `${key}=${value}&`;
      });

      push(`/appointment/conf?${query}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push, search]);

  return <></>;
};
