import {
  ListConferenceFilteredByACL,
  ListConferenceFilteredByACLV2,
} from 'domain/usecases/conference/remote';

export const ListFilteredByACLV2DTO = (
  rawData: ListConferenceFilteredByACLV2.Model,
): ListConferenceFilteredByACL.Model => {
  const formattedRecords: ListConferenceFilteredByACL.Model['records'] =
    rawData?.records?.map(item => {
      const formattedParticipants: ListConferenceFilteredByACL.Model['records'][0]['participants'] =
        item.participants.map(participant => ({
          id: participant.id,
          role: participant.role,
          scheduled: participant.scheduled,
          fullName: participant.fullName,
          email: participant.email,
          phone: participant.phone,
          passwd: '',
          confirmation: participant.confirmation,
          user: {
            id: participant.userId,
            login: participant.login,
            firstName: participant.firstName,
            lastName: participant.lastName,
            birthDate: participant.birthDate,
            email: participant.email,
            avatar: participant.avatar,
            lastAccess: '',
            fullName: participant.fullName,
          },
          conference: undefined,
        }));

      return {
        id: item.id,
        short: item.short,
        room: item.room,
        descr: item.descr,
        title: item.title,
        type: item.type,
        status: item.status,
        scheduled: item.scheduled,
        timezone: item.timezone,
        expectedDuration: item.expectedDuration,
        allowInvite: item.allowInvite,
        recorded: item.recorded,
        notifyOptions: item.notifyOptions,
        owner: item.owner,
        orgUnit: {
          id: item.orgUnit.id,
          shortname: item.orgUnit.shortname,
          name: item.orgUnit.name,
          descr: item.orgUnit.descr,
          logo: item.orgUnit.logo,
          org: {
            id: item.org.id,
            shortname: item.org.shortname,
            name: item.org.name,
            type: item.org.type,
            reg: item.org.reg,
            logo: item.org.logo,
          },
        },
        members: item.participantsSummary,
        participants: formattedParticipants,
        permission: item.permission,
      };
    });

  return { ...rawData, records: formattedRecords };
};
