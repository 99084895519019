import React, { useState, forwardRef } from 'react';
import { withAccess } from 'presentation/hooks/access';

import { Container, Label, TextAreaContainer, Message } from './styles';

interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name?: string;
  label?: string;
  isLabelBold?: boolean;
  className?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  required?: boolean;
  register?: () => any;
}

const TextArea: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = (
  {
    label,
    isLabelBold = false,
    message,
    className,
    error = false,
    width = '100%',
    height = '48px',
    required = false,
    register,
    value,
    onChange,
    onBlur,
    disabled,
    ...rest
  },
  ref,
) => {
  const [focus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';

  // if the component is using react-hook-form.
  if (register) register();

  let textAreaLabel = label;
  if (required) textAreaLabel = `${label}*`;

  return (
    <Container width={width} className={className}>
      {label && (
        <Label isLabelBold={isLabelBold} disabled={disabled}>
          {textAreaLabel}
        </Label>
      )}
      <TextAreaContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        // disabled={disabled}
        error={error}
      >
        <textarea
          {...rest}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          ref={ref}
        />
      </TextAreaContainer>
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default withAccess(forwardRef(TextArea));
