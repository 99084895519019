/* eslint-disable prefer-destructuring */
import React, { useEffect, useRef } from 'react';
import Translator from 'presentation/components/i18n/Translator';

import { IconBack, IconSearch, IconFilter } from 'presentation/base/icons';
import { useLocation } from 'react-router';
import { Container, RowContainer } from './styles/StyledBackButton';

import { Icon } from '../icon';
import Input from '../UI/input';

interface iStateParams {
  from: string;
}

interface namePages {
  [key: string]: string[];
}

interface ownProps {
  backDescription?: string;
  title?: string;
  hasFilter?: boolean;
  onBack?: () => void;
  id?: string;
  search?: string;
  setSearch?: Function;
  onSearch?: Function;
}

type Props = ownProps;

const namePages = [
  { key: /^home$/, value: Translator('Agenda') },
  { key: /^adminusers$/, value: Translator('Lista de usuários') },
  { key: /^adminusers\d+$/, value: Translator('Detalhes do usuário') },
  { key: /^adminorgs$/, value: Translator('Lista de organizações') },
  { key: /^adminorgs\d+$/, value: Translator('Detalhes de organização') },
  { key: /^adminorgs\d+unit\d+$/, value: Translator('Detalhes do setor') },
  { key: /^appointmentcreate$/, value: Translator('Criação de reunião') },
  { key: /^adminusercreate$/, value: Translator('Criação de usuário') },
  { key: /^adminorgscreatenew$/, value: Translator('Criação de organização') },
  { key: /^appointmentedit\d+$/, value: Translator('Editar reunião') },
];

const HeaderBackButton: React.FC<Props> = ({
  backDescription,
  onBack,
  title,
  hasFilter,
  id,
  search,
  setSearch,
  onSearch,
}): JSX.Element => {
  const from = useLocation<iStateParams>().state?.from;
  const data = Object.entries(namePages);
  let namePageBack = Translator('Voltar');
  if (from) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in namePages) {
      if (from.match(namePages[key].key)) namePageBack = namePages[key].value;
    }
  }
  const backD = backDescription !== undefined ? backDescription : namePageBack;

  const iconRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const keyPressEvent = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        iconRef?.current?.click();
      }
    };

    document.addEventListener('keydown', keyPressEvent);

    return () => {
      document.removeEventListener('keydown', keyPressEvent);
    };
  }, []);

  return (
    <Container id={id}>
      <RowContainer
        data-testid="header-back-btn"
        style={{ cursor: 'pointer' }}
        onClick={onBack}
      >
        {onBack && (
          <>
            <Icon src={IconBack} width="16px" height="16px" />
            {backD}
          </>
        )}
      </RowContainer>
      <RowContainer
        data-testid="header-title"
        style={{ justifyContent: 'center', width: '45%', fontWeight: 'bold' }}
      >
        {title}
      </RowContainer>
      <RowContainer style={{ justifyContent: 'flex-end' }}>
        {hasFilter && (
          <>
            <Input
              placeholder={Translator('Pesquisar')}
              icon={IconSearch}
              value={search}
              iconRef={iconRef}
              onChange={e => setSearch?.(e.target.value)}
              width="300px"
              height="33px"
              onClick={() => onSearch?.()}
            />
            <IconFilter />
          </>
        )}
      </RowContainer>
    </Container>
  );
};

export default HeaderBackButton;
