import React from 'react';
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { MakeAdminFactory, MakeUsersFactory } from 'main/factories/pages/admin';
import {
  MakeAppointmentFactory,
  MakeEditAppointmentFactory,
  MakeNewAppointmentFactory,
} from 'main/factories/pages/appointment';
import {
  MakeDashboardFactory,
  MakeDashboardUserFactory,
} from 'main/factories/pages/dashboard';

import {
  MakeForgotPasswordFactory,
  MakeRecoveryPasswordFactory,
} from 'main/factories/pages/password';

import { MakeConfFactory, MakePreConfFactory } from 'main/factories/pages/conf';
import SectorDetails from 'presentation/pages/Admin/Sectors/SectorDetails';
import EndConf from 'presentation/pages/EndConf';
import { MakeConfirmMeetFactory } from '../factories/pages/confirm/ConfirmMeetFactory';
import { MakeCreateOrgFactory } from '../factories/pages/createOrg/CreateOrgFactory';
import { MakeCreateOrgUnitFactory } from '../factories/pages/createOrgUnit/CreateOrgUnitFactory';
import { MakeCreateUserFactory } from '../factories/pages/createUser/CreateUserFactory';
import { MakeJoinConferenceFactory } from '../factories/pages/join/JoinConferenceFactory';

import { History } from '.';
import PrivateRoute from './PrivateRoute';
import { MakeLoginFactory } from '../factories/pages/login';
import { MakeModalTestFactory } from '../factories/pages/test';
import { MakeRankingFactory } from '../factories/pages/ranking/rankingOrg';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <Switch>
          <Route exact path="/" component={MakeLoginFactory} />
          <Route exact path="/leave" component={EndConf} />

          <PrivateRoute exact path="/home" component={MakeAppointmentFactory} />

          <PrivateRoute
            exact
            path="/sectors/details/:id"
            component={SectorDetails}
          />

          <PrivateRoute
            exact
            path="/appointment/create"
            component={MakeNewAppointmentFactory}
          />

          <PrivateRoute
            exact
            path="/appointment/edit/:id"
            component={MakeEditAppointmentFactory}
          />

          <PrivateRoute
            exact
            path="/dashboard/monitoring"
            component={MakeDashboardFactory}
          />

          <Route exact path="/dashboard/org" component={MakeRankingFactory} />
          <Route
            exact
            path="/dashboard/user"
            component={MakeDashboardUserFactory}
          />

          <Route exact path="/dashboard/org" component={MakeRankingFactory} />

          <PrivateRoute exact path="/admin/orgs" component={MakeAdminFactory} />

          <PrivateRoute
            exact
            path="/admin/orgs/:id"
            component={MakeAdminFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/:id/unit/:orgUnitId"
            component={MakeAdminFactory}
          />

          <PrivateRoute
            exact
            path="/admin/users/:id"
            component={MakeUsersFactory}
          />

          <PrivateRoute
            exact
            path="/admin/users"
            component={MakeUsersFactory}
          />

          <PrivateRoute
            exact
            path="/admin/user/create"
            component={MakeCreateUserFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/create/new"
            component={MakeCreateOrgFactory}
          />

          <PrivateRoute
            exact
            path="/admin/orgs/:id/unit/create/new"
            component={MakeCreateOrgUnitFactory}
          />

          <Route
            exact
            path="/pwd/reset"
            component={MakeRecoveryPasswordFactory}
          />

          <Route
            exact
            path="/pwd/forgot"
            component={MakeForgotPasswordFactory}
          />

          <Route
            exact
            path="/confirm/account"
            component={MakeRecoveryPasswordFactory}
          />

          <Route
            exact
            path="/confirm/meet"
            component={MakeConfirmMeetFactory}
          />

          <Route exact path="/preconf" component={MakePreConfFactory} />

          <Route exact path="/appointment/conf" component={MakeConfFactory} />

          <Route exact path="/join" component={MakeJoinConferenceFactory} />

          <Route exact path="/modals/:id" component={MakeModalTestFactory} />

          <Route
            exact
            path="/Error"
            component={() => <div>Erro no login</div>}
          />

          <Route exact path="/:language" component={MakeAppointmentFactory} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
