import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Button, PageButton, PaginationContainer } from './styles';

interface PaginationProps {
  pageSize: number;
  setPage: (page: number) => void;
  currentPage: number;
  disableMarginTop?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  pageSize,
  setPage,
  currentPage,
  disableMarginTop,
}) => {
  const pageNumbers = Array.from({ length: pageSize }, (_, i) => i + 1);
  const [limitRender, setLimitRender] = useState([0, 5]);

  useEffect(() => {
    if (currentPage + 1 === pageSize) {
      return;
    }
    if (currentPage > limitRender[1]) {
      if (currentPage === pageSize) {
        setLimitRender([currentPage - 5, currentPage]);
        return;
      }
      setLimitRender([currentPage - 1, currentPage + 4]);

      return;
    }
    if (currentPage <= limitRender[0]) {
      setLimitRender([limitRender[0] - 1, limitRender[1] - 1]);
    }
  }, [currentPage, pageSize, limitRender]);

  return (
    <PaginationContainer disableMargin={disableMarginTop}>
      <Button
        data-testid="pagination-button-left"
        type="button"
        onClick={() => setPage(currentPage === 1 ? 1 : currentPage - 1)}
      >
        <FaChevronLeft />
      </Button>
      {pageNumbers.slice(limitRender[0], limitRender[1]).map(pageNumber => (
        <PageButton
          data-testid={`pagination-button-${pageNumber}`}
          type="button"
          key={pageNumber}
          onClick={() => setPage(pageNumber)}
          active={currentPage === pageNumber}
        >
          {pageNumber}
        </PageButton>
      ))}
      {pageNumbers.length > limitRender[1] && (
        <>
          <span>...</span>
          <PageButton type="button" onClick={() => setPage(pageNumbers.length)}>
            {pageNumbers.length}
          </PageButton>
        </>
      )}

      <Button
        data-testid="pagination-button-right"
        type="button"
        onClick={() =>
          setPage(currentPage === pageSize ? pageSize : currentPage + 1)
        }
      >
        <FaChevronRight />
      </Button>
    </PaginationContainer>
  );
};

export default Pagination;
