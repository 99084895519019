import * as Yup from 'yup';

export enum MessageError {
  REQUIRED_FIELD = 'Campo obrigatório',
}

export const appointmentSchema = Yup.object().shape({
  title: Yup.string()
    .max(128, 'O título deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),
  descr: Yup.string()
    .max(1024, 'A descrição deve conter no máximo 1024 caracteres.')
    .optional(),
});
