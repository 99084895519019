/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as dateFns from 'date-fns';
import { iStore } from 'domain/interfaces/models';
import { useLocation } from 'react-router-dom';
import ptBR from 'date-fns/locale/pt';
import { makeRemoteGetConference } from 'main/factories/usecases/conference/GetConferenceFactory';
import { addMinutesToDate, padWithZeroes } from 'utils/formattedDate';
import { ownProps, externalProps } from '../appointmentForm';
import { times } from '../externValues';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const { pathname } = useLocation();
    const { records } = useSelector((store: iStore) => store.conference);

    let data: ownProps['data'] = {
      selectDate: new Date(),
      title: '',
      timeStart: 0,
      timeEnd: 0,
      description: '',
      notifyOptions: {
        email: false,
        sms: false,
        whatsapp: false,
      },
      participants: [],
    };

    if (pathname.includes('edit')) {
      const conferenceId = Number(pathname.split('/').pop());
      const conference = records?.filter(el => {
        return el.id === conferenceId;
      });

      if (conference) {
        const auxDate = new Date(conference[0].scheduled);
        const endDate = addMinutesToDate(
          auxDate,
          conference[0].expectedDuration,
        );

        data = {
          selectDate: new Date(conference[0].scheduled),
          title: conference[0].title,
          timeStart: getTime(
            `${padWithZeroes(auxDate.getHours(), 2)}:${padWithZeroes(
              auxDate.getMinutes(),
              2,
            )}`,
          ),
          timeEnd: getTime(
            `${padWithZeroes(endDate.getHours(), 2)}:${padWithZeroes(
              endDate.getMinutes(),
              2,
            )}`,
          ),
          description: conference[0].descr,
          conferenceId: conference[0].id,
          notifyOptions: conference[0].notifyOptions,
          participants: conference[0].participants,
        };
      }
    }

    Component.defaultProps = {
      data,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}

export const roundTime = (hours: number, minutes: number): string => {
  const roundMinutes = (parseInt(String((minutes + 7.5) / 15), 10) * 15) % 60;
  const roundHours = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;

  return `${padWithZeroes(roundHours, 2)}:${padWithZeroes(roundMinutes, 2)}`;
};

export const getTime = (time: string) => {
  const hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);

  const roundedTime = roundTime(hours, minutes);

  return times.findIndex(el => {
    return el === roundedTime;
  });
};
