import { takeLatest } from 'redux-saga/effects';
import { OrgTypes } from 'domain/interfaces/redux/org/types';
import { onList, onListSuccess, onListFailed } from './List';
import {
  onListAclFiltered,
  onListAclFilteredSuccess,
  onListAclFilteredFailed,
} from './ListAclFiltered';
import {
  onListAclFilteredV2,
  onListAclFilteredV2Success,
  onListAclFilteredV2Failed,
} from './ListAclFilteredV2';

const tasks = [
  takeLatest(OrgTypes.LIST, onList),
  takeLatest(OrgTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(OrgTypes.LIST_FAILED, onListFailed),
  takeLatest(OrgTypes.LIST_ACL_FILTERED, onListAclFiltered),
  takeLatest(OrgTypes.LIST_ACL_FILTERED_SUCCESS, onListAclFilteredSuccess),
  takeLatest(OrgTypes.LIST_ACL_FILTERED_FAILED, onListAclFilteredFailed),
  takeLatest(OrgTypes.LIST_ACL_FILTERED_V2, onListAclFilteredV2),
  takeLatest(OrgTypes.LIST_ACL_FILTERED_V2_SUCCESS, onListAclFilteredV2Success),
  takeLatest(OrgTypes.LIST_ACL_FILTERED_V2_FAILED, onListAclFilteredV2Failed),
];

export default tasks;
