import { AnyAction, combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import user from './user';
import conference from './conference';
import org from './org';
import message from './message';
import modal from './modal';
import orgUsers from './orgUsers';
import orgUnits from './orgUnits';
import dashboardOrg from './dashboard';

const reducer = combineReducers({
  auth,
  user,
  conference,
  org,
  message,
  modal,
  orgUsers,
  orgUnits,
  dashboardOrg,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (
    action.type === '@auth/LOGOUT_SUCCESS' ||
    action.type === '@auth/LOGOUT_FAILED'
  ) {
    storage.removeItem('persist:@agenda2');
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key) => {
    const blacklistPathsForKey = blacklistPaths
      .filter(path => path.startsWith(`${String(key)}.`))
      .map(path => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: '@agenda2',
  storage,
  blacklist: ['message', 'modal', 'showModal'],
  transforms: [
    blacklistProperty([
      'auth.loading',
      'modal.visible',
      'conference.controller.showDetails',
    ]),
  ],
};

export default persistReducer(authPersistConfig, rootReducer);
