/* eslint-disable no-nested-ternary */
import { iStore } from 'domain/interfaces/models';
import { OrgInfos } from 'domain/usecases/org/remote';
import _ from 'lodash';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import { IconHealth } from 'presentation/base/icons';
import { OrgNavigator } from 'presentation/components/OrgNavigator';
import Pagination from 'presentation/components/Pagination';
import { Button } from 'presentation/components/UI';
import { Header, HeaderBack } from 'presentation/components/header';
import Translator from 'presentation/components/i18n/Translator';
import Org from 'presentation/components/list/Org';
import SectorsPage from 'presentation/pages/Admin/Sectors';
import SettingsPage from 'presentation/pages/Admin/Settings';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getRole } from 'utils/getRole';
import { makeRemoteGetFilteredOrgs } from 'main/factories/usecases/org/GetFilteredOrgs';
import { IOrderBy } from 'presentation/components/list/Users';
import { iListOrgs } from 'presentation/components/list/interface';
import BillingPage from './Billing';
import OrgPage from './Orgs';
import {
  Body,
  Container,
  Content,
  Left,
  ListOrgs,
  Right,
} from './styles/styledAdmin';

export interface OrgUnitInfos {
  id: 0;
  shortname: string;
  name: string;
  descr: string;
  logo: string;
  notificationChannels: {
    email: true;
    whatsapp: true;
    sms: true;
  };
  address: {
    type: string;
    zipcode: string;
    province: string;
    city: string;
    country: string;
    neighborhood: string;
    street: string;
    number: 0;
    complement: string;
  };
  org: {
    id: 0;
    shortname: string;
    name: string;
    type: string;
    reg: string;
    logo: string;
    notificationChannels: {
      email: true;
      whatsapp: true;
      sms: true;
    };
    skin: {
      id: 0;
      name: string;
      org: 0;
      descr: string;
      skin: {};
    };
  };
  parent: string;
}

interface iParams {
  id: string;
  orgUnitId: string;
}

const Admin: React.FC = (): JSX.Element => {
  const url = window.location.pathname;
  const params = useParams<iParams>();
  const [allOrderBy, setAllOrderBy] = useState<IOrderBy[]>([
    {
      order: 1,
      attribute: 'NAME',
      direction: 'ASC',
    },
    {
      order: 1,
      attribute: 'SHORTNAME',
      direction: 'DESC',
    },
  ]);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [orgsFiltered, setOrgsFiltered] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(0);
  const [selectedOrgUnitName, setSelectedOrgUnitName] = useState<string | null>(
    null,
  );
  const [selectedOrg, setSelectedOrg] = useState<OrgInfos>({} as OrgInfos);
  const responseOrg = useSelector((store: iStore) => store.org);

  const [navigation, setNavigation] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [haveUpdated, setHaveUpdated] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const user = useSelector((store: iStore) => store.auth.user);

  const itemsPerPage = 15;

  const { pathname } = useLocation();
  const { push, goBack } = useHistory();

  const role = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const displayData = useMemo(() => {
    // const orderedRecords = _.orderBy(
    //   responseOrg.records,
    //   item => item.name.toLowerCase(),
    //   ['asc'],
    // );

    const start = (page - 1) * itemsPerPage;

    return {
      ...orgsFiltered,
      records: orgsFiltered?.slice(start, start + itemsPerPage),
    };
  }, [orgsFiltered, page]);

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return (
          <OrgPage
            orgId={selectedOrgId}
            haveUpdated={() => setHaveUpdated(prevState => !prevState)}
            editing
          />
        );
      case 2:
        return (
          <SectorsPage
            handleOrgUnitName={setSelectedOrgUnitName}
            haveUpdated={() => setHaveUpdated(prevState => !prevState)}
          />
        );
      case 3:
        return <SettingsPage />;
      case 4:
        return <BillingPage />;
      default:
        return <div />;
    }
  }, [navigation, selectedOrgId]);

  const handleGetHeaderNameFormatted = useMemo(() => {
    let headerName = `: ${selectedOrg.name}`;

    if (selectedOrgUnitName && selectedOrg) {
      headerName += ` - Setor: ${selectedOrgUnitName}`;
    }

    return headerName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg, selectedOrgUnitName, haveUpdated]);

  const handleGoBack = useCallback(
    (forceRender?: boolean) => {
      if (navigation === 2 && params?.orgUnitId) {
        if (forceRender) {
          push(`/admin/orgs/${params?.id}`);
        } else {
          // Update pathname without trigger re-render
          window.history.replaceState(
            null,
            'Agenda',
            `/admin/orgs/${params?.id}`,
          );
        }
      } else {
        setSelectedOrgId(0);
        push('/admin/orgs');
      }
    },
    [navigation, params?.id, params?.orgUnitId, push],
  );

  const handleFilterOrgs = useCallback(() => {
    if (search) {
      makeReduxListOrgAclFilteredV2().list({
        searchBar: search,
      });
    }
  }, [search]);

  const handleOrderBy = (type?: string) => {
    setLoadingOrgs(true);
    let obj: IOrderBy = {} as IOrderBy;

    if (type === 'name' || type === undefined) {
      obj = {
        order: 1,
        attribute: 'NAME',
        direction:
          type === undefined
            ? 'ASC'
            : allOrderBy[0].direction === 'ASC'
            ? 'DESC'
            : 'ASC',
      };
      setAllOrderBy([obj, allOrderBy[1]]);
    } else {
      obj = {
        order: allOrderBy[1].order,
        attribute: 'SHORTNAME',
        direction: allOrderBy[1].direction === 'ASC' ? 'DESC' : 'ASC',
      };
      setAllOrderBy([allOrderBy[0], obj]);
    }

    makeRemoteGetFilteredOrgs()
      .get({
        dataControl: {
          limit: 999999,
          offset: 0,
          paging: true,
        },
        orderBy: [obj],
      })
      .then(res => {
        setOrgsFiltered(res.records);
        setLoadingOrgs(false);
      })
      .catch(err => {
        console.error(err);
        setLoadingOrgs(false);
      });
  };

  useEffect(() => {
    handleOrderBy();
  }, []);

  useLayoutEffect(() => {
    if (Number(params.id)) setSelectedOrgId(Number(params?.id));
  }, [params.id]);

  useEffect(() => {
    if (!search) {
      makeReduxListOrgAclFilteredV2().list({
        limit: 9999,
      });
    }
  }, [haveUpdated, user, search]);

  useEffect(() => {
    if (Number(params?.id)) {
      // setSelectedOrgId(Number(params?.id));

      const foundOrg = responseOrg.records?.find(
        org => org.id === Number(params?.id),
      );

      if (foundOrg) setSelectedOrg(foundOrg);
    }
  }, [params, pathname, responseOrg.records]);

  useEffect(() => {
    if (Number(params?.orgUnitId)) {
      setNavigation(2);
    }
  }, [params]);

  return (
    <Container>
      <Header title="teste" />
      {!selectedOrgId && (
        <HeaderBack
          title={Translator('Organizações cadastradas')}
          hasFilter
          search={search}
          setSearch={setSearch}
          onSearch={handleFilterOrgs}
        />
      )}
      <div style={{ padding: !selectedOrgId ? '0 24px' : '0' }}>
        {selectedOrgId ? (
          <>
            <HeaderBack
              onBack={() => {
                if (!(navigation === 2 && params?.orgUnitId))
                  setSelectedOrgId(0);

                goBack();
              }}
              title={
                navigation === 4
                  ? `Informações de consumo e faturamento${handleGetHeaderNameFormatted}`
                  : `Detalhes da organização${handleGetHeaderNameFormatted}`
              }
            />
            <Body>
              <Left>
                <OrgNavigator
                  active={navigation}
                  haveUpdated={() => setHaveUpdated(prevState => !prevState)}
                  nav={setNavigation}
                  org={selectedOrg}
                  creating={0}
                />
              </Left>
              <Right>{RenderSubPages}</Right>
            </Body>
          </>
        ) : (
          <>
            <Content>
              <div>
                <Pagination
                  currentPage={page}
                  setPage={setPage}
                  pageSize={
                    responseOrg?.records?.length
                      ? Math.ceil(responseOrg?.records?.length / itemsPerPage)
                      : 0
                  }
                />
              </div>
              <div>
                <Button
                  data-testid="btn-create-org"
                  icon={IconHealth}
                  rounded
                  variant="primary"
                  size="medium"
                  onClick={() =>
                    push('/admin/orgs/create/new', {
                      from: url.replaceAll('/', ''),
                    })
                  }
                  resourcers={['ORG']}
                  actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
                >
                  {Translator('Nova Organização')}
                </Button>
              </div>
            </Content>
            <ListOrgs>
              {loadingOrgs ? (
                <>loading...</>
              ) : (
                <Org
                  dataList={displayData}
                  setAllOrderBy={handleOrderBy}
                  haveUpdated={() => setHaveUpdated(prevState => !prevState)}
                />
              )}
            </ListOrgs>
          </>
        )}
      </div>
    </Container>
  );
};

export default Admin;
