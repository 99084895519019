/* eslint-disable no-console */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Formik, FormikErrors } from 'formik';
import Translator from 'presentation/components/i18n/Translator';
import HeaderMenu from 'presentation/components/MenuNavigation/MenuNavigation';
import { OrgInfos } from 'domain/usecases/org/remote';
import { makeRemoteGetOrg } from 'main/factories/usecases/org/GetOrgFactory';
import { Button } from 'presentation/components/UI';
import { orgSchema } from 'validation/org/CreateOrgValidator';
import { makeRemoteUpdateOrg } from 'main/factories/usecases/org/UpdateOrgFactory';
import { makeRemoteCreateOrg } from 'main/factories/usecases/org/CreateOrgFactory';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import OrgAddress from './Address';
import OrgContact from './Contact';
import OrgName from './Name';
import { Container, Body, Right, Left } from './styles/StyledOrgDetails';
import { ContentButton } from '../styles/styledAdmin';

interface ownProps {
  idOrg?: number;
  editing?: boolean;
  // TODO: Remove after everything is on Redux
  haveUpdated?: () => void;
}

export interface initialValuesOrg {
  name: string;
  shortname: string;
  zipcode: string;
  province: string;
  city: string;
  country: string;
  neighborhood: string;
  street: string;
  number: string;
  complement: string;
  contactName: string;
  contactEmail: string;
  contactPhone1: string;
  contactPhone2: string;
}

const OrgDetails: React.FC<ownProps> = ({
  idOrg,
  editing = false,
  haveUpdated,
}): JSX.Element => {
  const [orgResponse, setOrgResponse] = useState({} as OrgInfos);
  const [tabNavigation, setTabNavigation] = useState(0);
  const [previousShortnameValue, setPreviousShortnameValue] = useState('');
  const [valuesEditing, setValuesEditing] = useState({});

  const { records: orgRecords } = useSelector((store: iStore) => store.org);

  const options = useMemo(
    () => [
      `${Translator('Nome')}`,
      `${Translator('Endereço')}`,
      `${Translator('Contato')}`,
    ],
    [],
  );

  const initialValuesOrg = {
    name: '',
    shortname: '',
    zipcode: '',
    province: '',
    city: '',
    country: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: '',
    contactName: '',
    contactEmail: '',
    contactPhone1: '',
    contactPhone2: '',
  };

  const { push } = useHistory();

  const actualOrg = useMemo(
    () => orgRecords?.find(org => org.id === idOrg),
    [idOrg, orgRecords],
  );

  const RenderSubPagesTab = useMemo(() => {
    const stepIndex: Record<number, JSX.Element> = {
      0: <OrgName org={orgResponse} onChangeOrgInfo={setOrgResponse} />,
      1: <OrgAddress org={orgResponse} onChangeOrgInfo={setOrgResponse} />,
      2: <OrgContact org={orgResponse} onChangeOrgInfo={setOrgResponse} />,
    };

    return (
      <>
        <HeaderMenu
          options={options}
          setState={editing ? setTabNavigation : undefined}
          active={tabNavigation}
        />
        {stepIndex[tabNavigation] || <div />}
      </>
    );
  }, [orgResponse, options, editing, tabNavigation]);

  const handleUpdateOrgsInfo = useCallback(() => {
    if (editing) {
      const formattedOrgResponse = {
        ...orgResponse,
        id: orgResponse.id,
        skin: 1,
        contactName: orgResponse.contactName ?? undefined,
        urlmatch: orgResponse.urlmatch ?? undefined,
        urlregex: orgResponse.urlregex ?? undefined,
        contactEmail: orgResponse.contactEmail ?? undefined,
        contactPhone1: orgResponse.contactPhone1
          ? `+55${orgResponse.contactPhone1}`
          : undefined,
        contactPhone2: orgResponse.contactPhone2
          ? `+55${orgResponse.contactPhone2}`
          : undefined,
        shortname:
          previousShortnameValue !== orgResponse.shortname
            ? orgResponse.shortname
            : undefined,
        logo: undefined,
        expiration: undefined,
        orgUnitsCount: undefined,
        address: {
          ...orgResponse.address,
          type: 'HOUSE',
          complement: orgResponse.address?.complement ?? '',
        },
        users: undefined,
      };

      makeRemoteUpdateOrg()
        .update(formattedOrgResponse)
        .then(() => {
          toast.success('Organização atualizada com sucesso!');

          haveUpdated?.();
        })
        .catch(() => {
          makeReduxActiveMessage().active({
            active: 'error',
            title: `${Translator('Atualizar organização')}`,
            content: `${Translator('Não foi possível atualizar organização')}`,
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    } else {
      makeRemoteCreateOrg()
        .create({
          ...orgResponse,
          // TODO: Considerar selecionar pessoa física ou jurídica, e preencher campo reg com cpf ou cnpj
          type: 'F',
          skin: 1,
          reg: '12345678911',
          contactPhone1: orgResponse.contactPhone1
            ? `+55${orgResponse.contactPhone1}`
            : undefined,
          contactPhone2: orgResponse.contactPhone2
            ? `+55${orgResponse.contactPhone2}`
            : undefined,
          address: { ...orgResponse.address, type: 'HOUSE' },
          settings: {
            notification: {
              sms: {
                enabled: true,
                limit: 1500,
              },
              email: {
                enabled: true,
                limit: 1500,
              },
              whatsapp: {
                enabled: true,
                limit: 1500,
              },
            },
          },
        })
        .then(() => {
          toast.success(`${Translator('Organização criada com sucesso')}`);

          push('/admin/orgs');
        })
        .catch(err => {
          const errorMessage =
            err.name === 'conflict'
              ? `${Translator('Já existe uma organização com este nome')}`
              : `${Translator('Não foi possível cadastrar a organização')}`;

          makeReduxActiveMessage().active({
            active: 'error',
            title: `${Translator('Criação de organização')}`,
            content: errorMessage,
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    }
  }, [editing, haveUpdated, orgResponse, previousShortnameValue, push]);

  const handleClick = useCallback(() => {
    if (editing) {
      handleUpdateOrgsInfo();
    } else if (tabNavigation < 2) {
      setTabNavigation(tabNavigation + 1);
    } else {
      handleUpdateOrgsInfo();
    }
  }, [editing, handleUpdateOrgsInfo, tabNavigation]);

  const getNameButton = useCallback(() => {
    if (editing) return `${Translator('Salvar')}`;
    if (tabNavigation < 2) return `${Translator('Próximo')}`;

    return `${Translator('Concluir')}`;
  }, [editing, tabNavigation]);

  const handleDisabledButton = useCallback(
    (errors: FormikErrors<initialValuesOrg>): boolean => {
      if (editing) return false;

      switch (tabNavigation) {
        case 0:
          return Boolean(errors?.name) || Boolean(errors?.shortname);
        case 1:
          return (
            Boolean(errors?.zipcode) ||
            Boolean(errors?.province) ||
            Boolean(errors?.city) ||
            Boolean(errors?.neighborhood) ||
            Boolean(errors?.number) ||
            Boolean(errors?.complement) ||
            Boolean(errors?.street)
          );
        case 2:
          return (
            Boolean(errors?.contactName) ||
            Boolean(errors?.contactEmail) ||
            Boolean(errors?.contactPhone1) ||
            Boolean(errors?.contactPhone2)
          );
        default:
          return false;
      }
    },
    [editing, tabNavigation],
  );

  useEffect(() => {
    if (!idOrg) return;

    makeRemoteGetOrg()
      .get({ id: idOrg })
      .then(response => {
        setOrgResponse({
          ...response,
          contactPhone1: response.contactPhone1?.replace(/^\+55/, ''),
          contactPhone2: response.contactPhone2?.replace(/^\+55/, ''),
        });
        setValuesEditing({
          name: response.name,
          shortname: response.shortname,
          zipcode: response.address.zipcode,
          province: response.address.province,
          city: response.address.city,
          country: response.address.country,
          neighborhood: response.address.neighborhood,
          street: response.address.street,
          number: response.address.number,
          complement: response.address.complement,
          contactName: response.contactName,
          contactEmail: response.contactEmail,
          contactPhone1: response.contactPhone1?.replace(/^\+55/, ''),
          contactPhone2: response.contactPhone2?.replace(/^\+55/, ''),
        });
        setPreviousShortnameValue(response.shortname);
      })
      .catch(() => {
        // toast.error(`${Translator('Erro ao buscar dados da organização')}`);
      });
  }, [idOrg]);

  return (
    <Container>
      <Body>
        <Left />
        <Formik
          validationSchema={orgSchema}
          initialValues={editing ? valuesEditing : initialValuesOrg}
          onSubmit={values => console.log(values)}
          validateOnChange
          enableReinitialize
        >
          {({ errors }) => (
            <>
              <Right>
                {RenderSubPagesTab}
                <ContentButton>
                  {!editing && (
                    <Button
                      data-testid="btn-back"
                      size="small"
                      variant="secundary"
                      onClick={() => setTabNavigation(tabNavigation - 1)}
                      disabled={!tabNavigation}
                    >
                      Voltar
                    </Button>
                  )}
                  <Button
                    data-testid="btn-update"
                    size="small"
                    onClick={handleClick}
                    disabled={handleDisabledButton(errors)}
                    permissionsV2={actualOrg?.permission.update}
                  >
                    {getNameButton()}
                  </Button>
                </ContentButton>
              </Right>
            </>
          )}
        </Formik>
      </Body>
    </Container>
  );
};

export default OrgDetails;
