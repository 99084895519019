import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .rbc-month-row + .rbc-month-row {
    border: none;
  }

  .rbc-day-bg {
    border-left: none;
    border: 1px solid #a6a6a6;
    border-radius: 8px;
    padding: 9px;
    height: 100%;
  }

  .rbc-date-cell {
    -webkit-flex: 1 1;
    -ms-flex: 1 1;
    flex: 1 1;
    min-width: 0;
    padding: 9px;
    text-align: left;
    color: #424043;
    /* font-weight: bold; */
    pointer-events: none;
    cursor: default;
  }

  .rbc-off-range {
    color: #a6a6a6;
  }

  .rbc-toolbar {
    display: none;
  }

  .rbc-calendar {
    width: 100%;
    height: 100%;
  }

  .rbc-row {
    margin-bottom: 5px;
  }

  .rbc-header + .rbc-header {
    border: none;
  }

  .rbc-header {
    text-align: left;
    color: ${({ theme }) => theme.colors.primary.main};
    border: none;
    text-transform: uppercase;
  }

  .rbc-month-view {
    border: none;
  }

  // events

  .rbc-row-segment {
    padding: 0px 3px;
  }

  .rbc-event {
    background: rgba(226, 6, 19, 0.7);
  }

  .rbc-show-more {
    color: #a6a6a6;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #a6a6a6;
    padding: 0 9px;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   height: auto; */
`;

export const AlertFreemium = styled.div`
  width: 100%;
  padding: 20px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    color: black;
    font-weight: normal;
  }

  a {
    text-decoration: underline;
  }

  .css-1c3trtb-MuiPaper-root-MuiAlert-root {
    background-color: #e5eff9;
  }

  .MuiSvgIcon-root {
    fill: #005bc8;
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0px auto;
  margin-top: 20px;
  height: calc(100% - 160px);
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: start;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  gap: 20px;

  .links {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
  }
`;

export const FooterImgs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

export const Content = styled.form`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.colors.primary.blue};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const SignUp = styled.a`
  ${SizeVariant};
  font-weight: bold;
  font-size: 18px;
`;

export const Forgotpass = styled.a`
  ${SizeVariant};
  font-size: 16px;
`;

export const Left = styled.div`
  height: 100%;
  border-right: 1px solid #c4c4c4;
  padding-right: 29px;
  background-color: ${({ theme }) => theme.colors.area.options};

  .responsive-calendar {
    display: none;
  }

  @media (max-width: 1024px) {
    border: none;
    padding: 0;
    height: auto;

    .calendar {
      display: none;
    }

    .responsive-calendar {
      display: block;
    }
  }
`;

export const Right = styled.div`
  height: 100%;
  width: 100%;
  // max-width: 788px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-left: 30px;
  background-color: ${({ theme }) => theme.colors.area.work};

  .calendar {
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    position: relative;
    overflow: hidden;
  }

  .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: calc(100vh - 160px);
  }

  .rbc-day-bg {
    border: 1px solid rgba(166, 166, 166, 0.4) !important;
  }

  @media (max-width: 1024px) {
    margin: 0;
    overflow: auto;
  }
`;

interface iEventProps {
  isRecorded?: boolean;
}

export const Event = styled.button<iEventProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #353535;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 14px;
  padding: 0px 9px;
  z-index: 25;
  cursor: pointer;

  .pointer {
    width: 8px;
    height: 8px;
    background: ${({ isRecorded }) => (isRecorded ? '#ff5b5b' : '#C9C9C9')};
    border-radius: 50%;
  }

  .text {
    padding-left: 6px;
    text-overflow: ellipsis;
    width: calc(100% - 8px);
    height: 14px;
    overflow: hidden;
    text-align: start;
    white-space: nowrap;
  }

  :hover {
    background: rgba(226, 6, 19, 0.1);
  }
`;

export const ContainerPopover = styled.div`
  max-width: 280px;
  overflow: hidden;
`;

export const TitlePopover = styled.div`
  padding: 8px 12px;
  border-bottom: 1px solid #e5e5e5;
`;

export const ListPopover = styled.div`
  padding: 8px 10px 8px 12px;
  overflow: auto;
  max-height: 150px;

  .info {
    display: flex;
    margin-bottom: 2px;
    border: none;
    background: transparent;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #353535;
    cursor: pointer;

    :hover {
      background: rgba(226, 6, 19, 0.1);
    }
  }

  .time {
    width: 80px;
    min-width: 80px;
    white-space: nowrap;
  }

  .title {
    margin-left: 10px;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
