import React, {
  FormEvent,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

import { makeReduxLoginAuth } from 'main/factories/usecases/auth/LoginAuthFactory';
import { makeReduxCloseModal } from 'main/factories/usecases/modal/CloseModalFactory';

import {
  IconLogoV4H,
  IconLoginV4H,
  IconLogo,
  IconLogin,
} from 'presentation/base/icons';
import { Button, Input, Modal } from 'presentation/components/UI';
import { ChangeRoles } from 'presentation/components/changeRoles';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { ThemeContext } from 'App';
import Translator from 'presentation/components/i18n/Translator';
import {
  Container,
  Left,
  Right,
  Content,
  Form,
  Title,
  ButtonsContainer,
  ForgotPasswordText,
  ImageContainer,
  VersionContentText,
} from './styles/styledLogin';

const Login: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { loading } = useSelector((store: iStore) => store.auth);

  const { push } = useHistory();
  const { theme } = useContext(ThemeContext);

  const handleLogin = useCallback(
    // eslint-disable-next-line consistent-return
    (e: FormEvent) => {
      e.preventDefault();

      if (!loading) {
        if (email === '' || password === '') {
          return toast.error('Preencha todos os campos.');
        }

        makeReduxLoginAuth().login({
          body: {
            login: email,
            password,
          },
        });
      }
    },
    [email, loading, password],
  );

  return (
    <Container>
      <Left />
      <Right>
        <Content>
          {theme.images.logo.main && (
            <img src={theme.images.logo.main} alt="Logo" />
          )}
          <Title>{Translator('Login')}</Title>
          <Form onSubmit={handleLogin}>
            <Input
              data-testid="input-email"
              label={Translator('Email')}
              placeholder={Translator('Digite seu email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />
            <Input
              data-testid="input-password"
              type="password"
              label={Translator('Senha')}
              placeholder={Translator('Digite sua senha')}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <ButtonsContainer>
              <ForgotPasswordText
                data-testid="btn-forgot"
                onClick={() => push('/pwd/forgot')}
              >
                {Translator('Esqueceu a senha?')}
              </ForgotPasswordText>
              <Button
                data-testid="btn-login"
                type="submit"
                size="small"
                disabled={loading}
              >
                {loading
                  ? `${Translator('Carregando...')}`
                  : `${Translator('Entrar')}`}
              </Button>
            </ButtonsContainer>
            <Tooltip
              title={Translator('Clique para ver as notas de release')}
              placement="top"
              arrow
            >
              <VersionContentText
                href="https://docs.google.com/document/d/1XEjK967gR9vy4KEv3DPppvEPuvK4t6lv8kH6DhFXXls"
                target="_blank"
              >
                v2.20.0R
              </VersionContentText>
            </Tooltip>
          </Form>
        </Content>
        <ImageContainer>
          {theme.images.background.main && (
            <img src={theme.images.background.main} alt="Logo" />
          )}
          <Tooltip
            title={Translator('Clique para ver as notas de release')}
            placement="top"
            arrow
          >
            <ForgotPasswordText
              href="https://docs.google.com/document/d/1XEjK967gR9vy4KEv3DPppvEPuvK4t6lv8kH6DhFXXls"
              target="_blank"
            >
              v2.20.0R
            </ForgotPasswordText>
          </Tooltip>
        </ImageContainer>
      </Right>
      <Modal
        title={Translator('Seleção de papeis')}
        handleClose={() => makeReduxCloseModal().close({})}
      >
        <ChangeRoles />
      </Modal>
    </Container>
  );
};

export default Login;
