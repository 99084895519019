import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select, Button } from 'presentation/components/UI';
import { translator } from 'presentation/components/i18n';
import { iStore } from 'domain/interfaces/models';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
// import { getAppointmentsByDate } from 'utils/getAppointmentByDate';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  FormItem,
  FormGroup,
  Options,
} from './styles/StyledFilterAppointment';

interface ownProps {
  user?: iStore['user']['records'];
  role?: 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD';
  search?: string;
  setSearch?: Function;
}

const FilterAppointment: React.FC<ownProps> = ({
  user,
  role,
  search,
  setSearch,
}) => {
  // const dateOne = new Date();
  // const dateTwo = new Date();

  const [state, setState] = useState<string>('DEFAULT');
  // const [created, setCreated] = useState(dateOne);
  // const [endAt, setEndAt] = useState(dateTwo);

  const handleFilter = useCallback(() => {
    if (search) {
      makeReduxListConferenceFilteredByACLV2().list({
        query: {
          searchBar: search,
        },
      });
    }
  }, [search]);

  const resetFilter = () => {
    // setOrganizer('');
    setState('DEFAULT');
  };

  return (
    <Container>
      <ContainerForm
        onSubmit={e => {
          e.preventDefault();
          handleFilter();
        }}
      >
        <FormItem>
          <p>{`${translator('Organizador')}`}</p>
          <Input
            id="input_professional"
            placeholder={`${translator('Digite o nome do organizador')}`}
            className="hover-input"
            value={search}
            autoFocus
            onChange={e => setSearch?.(e.target.value)}
          />
        </FormItem>
        <FormItem>
          <p>{`${translator('Participante')}`}</p>
          <Input
            id="input_patient"
            placeholder={`${translator('Digite o nome do participante')}`}
            className="hover-input"
            value={search}
            autoFocus={role === 'ORG_ADMIN' || false}
            onChange={e => setSearch?.(e.target.value)}
          />
        </FormItem>
        {/* <FormItem>
          <p>{`${translator('Período')}`}</p>
          <FormGroup>
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={created}
              setState={setCreated}
              placeholder="Data"
            />
            <InputKeyBoardDate
              id="input_date"
              defaultValue="dd/MM/yyyy"
              state={endAt}
              setState={setEndAt}
              placeholder={`${translator('Fim')}`}
            />
          </FormGroup>
        </FormItem> */}
        {/* <FormItem>
          <p>Status</p>
          <Select
            id="select_status"
            value={state}
            onChange={e => setState(e.target.value)}
            placeholder={`${translator('Qualquer')}`}
          >
            <Options id="state_0" value="DEFAULT">
              {translator('Qualquer')}
            </Options> */}
        {/* <Options id="state_1" value="PENDING">
              Pendente
            </Options> */}
        {/* <Options id="state_2" value="SCHEDULED">
              {translator('Agendado')}
            </Options>
            <Options id="state_3" value="INPROGRESS">
              {translator('Em progresso')}
            </Options>
            <Options id="state_4" value="CANCELED">
              {translator('Cancelado')}
            </Options>
            <Options id="state_5" value="ENDED">
              {translator('Finalizado')}
            </Options>
          </Select>
        </FormItem> */}
      </ContainerForm>
      <ContainerButtons>
        <Button
          rounded
          variant="secundary"
          size="medium"
          onClick={() => resetFilter()}
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          // resourcers={['PARTICIPANT', 'APPOINTMENT']}
          // actions={['READ_OWN']}
          rounded
          variant="primary"
          size="medium"
          onClick={() => handleFilter()}
          autoFocus
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterAppointment;
