import React, { useState, useEffect, FunctionComponent, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';

import {
  Header as H,
  HeaderBack,
  PageHeader,
} from 'presentation/components/header';
import { AppointmentForm } from 'presentation/components/appointmentForm';
import { makeRemoteCreateConference } from 'main/factories/usecases/conference/CreateConferenceFactory';
import { History } from 'main/routes';
import { iStore } from 'domain/interfaces/models';
import { toast } from 'react-toastify';

import {
  IconCalendar,
  IconClock,
  IconPeople,
  ViniPng,
  DenioPng,
  LincPng,
  ArrowLeftIcon,
  CopyIcon,
  IconDeleteFromList,
} from 'presentation/base/icons';

import { makeRemoteCreateConferenceUser } from 'main/factories/usecases/conferenceUser/CreateConferenceUserFactory';
import { Container, Body, Navigator } from './styles/styledNewAppointment';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
}

interface OwnProps {
  data?: iUsers[];
}

const NewAppointment: React.FC<OwnProps> = ({ data }): JSX.Element => {
  return (
    <Container>
      <H title="teste" />
      <HeaderBack
        onBack={() => {
          History.getHistory().goBack();
        }}
        title="Criação de reunião"
      />
      <Body>
        <AppointmentForm users={data} />
      </Body>
    </Container>
  );
};

export default NewAppointment;
