/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';

import { EditAppointment } from 'presentation/pages';
import { getOrgUserRole, getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
}

export const MakeEditAppointmentFactory: React.FC = () => {
  const [data, setData] = useState<iUsers[]>([]);

  const { records } = useSelector((store: iStore) => store.orgUsers);
  const { user } = useSelector((store: iStore) => store.auth);

  const roleRedux = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  useEffect(() => {
    UpdateListOrgUser(roleRedux, user?.org?.id, user?.orgUnit?.id);
  }, [roleRedux, user?.org?.id, user?.orgUnit?.id]);

  useEffect(() => {
    const newData = records.map(item => {
      const role = getOrgUserRole(item.sysAdmin, item.orgAdmin, item.unitAdmin);

      return {
        id: item.user.id,
        name: `${item.user.fullName}`,
        role,
        Image: item.user.avatar,
        orgName: item.orgUnit?.org?.shortname ?? '',
        unitName: item.orgUnit?.shortname ?? '',
      };
    });

    setData(newData);
  }, [records]);

  return <EditAppointment data={data} />;
};
