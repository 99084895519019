/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
export function formattedDate(date: Date): string {
  return `${date.toLocaleDateString('pt-BR', {
    day: '2-digit',
  })} de ${date.toLocaleDateString('pt-BR', {
    month: 'long',
  })} de ${date.toLocaleDateString('pt-BR', {
    year: 'numeric',
  })} às ${date.toLocaleTimeString().substring(0, 5)}`;
}

export function convertDateToUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function padWithZeroes(number: number, length: number): string {
  let stringValue = `${String(number).replace(/\D+/g, '')}`;
  while (stringValue.length < length) stringValue = `0${stringValue}`;

  return stringValue;
}

export function addPadWithZeroes(value: number): string {
  return String(value).padStart(2, '0');
}

export function roundTime(hours: number, minutes: number): string {
  const roundMinutes = (parseInt(String((minutes + 7.5) / 15), 10) * 15) % 60;
  const roundHours = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;

  return `${addPadWithZeroes(roundHours)}:${addPadWithZeroes(roundMinutes)}`;
}

export function getDateWithoutTimezone(value: Date): Date {
  const formatted = new Date(value);
  formatted.setMinutes(formatted.getMinutes() + formatted.getTimezoneOffset());

  return formatted;
}
