/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';

import * as dateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt';

import { useForm } from 'react-hook-form';

import { Header, PageHeader } from 'presentation/components/header';
import { Calendar } from 'presentation/components/calendar';
import { ResponsiveCalendar } from 'presentation/components/responsiveCalendar';
import { ListAppointment } from 'presentation/components/list';

import { iStore } from 'domain/interfaces/models';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { Popover, Tooltip, Stack, Alert } from '@mui/material';
import { addMinutesToDate, padWithZeroes } from 'utils/formattedDate';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import {
  Container,
  AlertFreemium,
  Body,
  Left,
  Right,
  Event,
  ContainerPopover,
  TitlePopover,
  ListPopover,
} from './styles/AppointmentStyled';

const localizer = momentLocalizer(moment);

interface iRef {
  [key: string]: HTMLDivElement | null;
}

const Appointment: React.FC = (): JSX.Element => {
  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const appointments = useSelector((store: iStore) => store.conference.records);
  const { selectedDate } = useSelector(
    (store: iStore) => store.conference.controller,
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [vizDay, setVizDay] = useState<string>('M');
  const [search, setSearch] = useState<string>('');
  const [date, setDate] = useState<Date>(new Date());
  const itemEls = useRef<iRef>({});
  const itemEvs = useRef<
    { id: number; title: string; start: Date; end: Date }[]
  >([]);
  const [openDate, setOpen] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterConference = useCallback(() => {
    if (search) {
      makeReduxListConferenceFilteredByACLV2().list({
        query: {
          searchBar: search,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (!search) {
      makeReduxListConferenceFilteredByACLV2().list({
        query: {
          limit: 99999,
        },
      });
    }
  }, [search]);

  const DateCellWrapper = (props: any) => {
    const width = '14.28571429%';
    const style = {
      WebkitFlexBasis: width,
      flexBasis: width,
      maxWidth: width,
      margin: '3px',
    };
    const key = dateFns.format(props.value, 'dd-MM');

    itemEls.current[key] = null;
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const positions = document.getElementById(key)?.getBoundingClientRect();

    return (
      <div
        id={key}
        data-testid={key}
        ref={el => {
          if (el) itemEls.current[key] = el;

          if (el) divRef.current = el;
        }}
        style={style}
      >
        {props.children}
        <Popover
          open={Boolean(anchorEl) && openDate === key}
          anchorPosition={{
            top: positions ? positions.top : 0,
            left: positions ? positions.x : 0,
          }}
          anchorReference="anchorPosition"
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ContainerPopover>
            <TitlePopover>
              {dateFns.format(props.value, `dd 'de' MMMM 'de' yyyy`, {
                locale: ptBR,
              })}
            </TitlePopover>
            <ListPopover>
              {itemEvs.current.map(el => {
                return (
                  <button
                    type="button"
                    className="info"
                    id={`event-popover-${el.id}`}
                    onClick={() => {
                      makeReduxUpdateControllerConference().updateController({
                        showDetails: true,
                        selected: el.id,
                      });
                      handleClose();
                    }}
                  >
                    <div className="time">
                      {`${padWithZeroes(
                        el.start.getHours(),
                        2,
                      )}:${padWithZeroes(
                        el.start.getMinutes(),
                        2,
                      )} às ${padWithZeroes(
                        el.end.getHours(),
                        2,
                      )}:${padWithZeroes(el.end.getMinutes(), 2)}`}
                    </div>
                    <Tooltip title={el.title} placement="right" arrow>
                      <div className="title">{el.title}</div>
                    </Tooltip>
                  </button>
                );
              })}
            </ListPopover>
          </ContainerPopover>
        </Popover>
      </div>
    );
  };

  const EventWrapper = (props: any) => {
    return (
      <Event
        data-testid={`event-${props.event.id}`}
        id={`event-${props.event.id}`}
        type="button"
        onClick={() => {
          makeReduxUpdateControllerConference().updateController({
            showDetails: true,
            selected: props.event.id,
          });
        }}
        isRecorded={props.event.recorded}
      >
        <div className="pointer" />
        <Tooltip title={props.event.title} placement="right" arrow>
          <div className="text">{props.event.title}</div>
        </Tooltip>
      </Event>
    );
  };

  return (
    <Container>
      <Header title="teste" />
      <PageHeader
        setViz={setVizDay}
        typeViz={vizDay}
        search={search}
        setSearch={setSearch}
        onSearch={handleFilterConference}
      />
      <Body>
        <Left>
          <div className="calendar">
            <Calendar date={date} setDate={setDate} />
          </div>
          <div className="responsive-calendar">
            <ResponsiveCalendar />
          </div>
        </Left>
        {vizDay === 'M' ? (
          <Right style={{ boxShadow: 'none' }}>
            <div className="calendar">
              <div className="content">
                <BigCalendar
                  events={appointments?.map((el, idx) => {
                    return {
                      id: el.id,
                      title: el.title,
                      start: new Date(el.scheduled),
                      end: dateFns.addMinutes(
                        new Date(el.scheduled),
                        el.expectedDuration,
                      ),
                      recorded: el.recorded,
                    };
                  })}
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  onShowMore={props => {
                    setAnchorEl(
                      itemEls.current[dateFns.format(props[0].start, 'dd-MM')],
                    );
                    setOpen(dateFns.format(props[0].start, 'dd-MM'));
                    itemEvs.current = props;
                  }}
                  date={date}
                  selectable
                  messages={{
                    showMore: total => {
                      return `e mais ${total}+`;
                    },
                  }}
                  components={{
                    dateCellWrapper: DateCellWrapper,
                    eventWrapper: EventWrapper,
                  }}
                  view="month"
                  views={{ month: true, day: false }}
                />
              </div>
            </div>
          </Right>
        ) : (
          <Right>
            <ListAppointment
              dataList={appointments
                ?.filter(el => {
                  return dateFns.isSameDay(
                    new Date(el.scheduled),
                    selectedDate,
                  );
                })
                ?.map((el, idx) => {
                  const auxDate = new Date(el.scheduled);

                  const endDate = addMinutesToDate(
                    auxDate,
                    el.expectedDuration,
                  );

                  return {
                    id: el.id,
                    fullName: el.owner.firstName,
                    date: `${auxDate.toLocaleDateString()}`,
                    timeStart: `${padWithZeroes(
                      auxDate.getHours(),
                      2,
                    )}:${padWithZeroes(auxDate.getMinutes(), 2)}`,
                    timeEnd: `${padWithZeroes(
                      endDate.getHours(),
                      2,
                    )}:${padWithZeroes(endDate.getMinutes(), 2)}`,
                    org: `${el.orgUnit.org.shortname}/${el.orgUnit.shortname}`,
                    status: 'SCHEDULED',
                    short: el.short,
                    title: el.title,
                    permission: el.permission,
                  };
                })}
            />
          </Right>
        )}
      </Body>
    </Container>
  );
};

export default Appointment;
