import styled, { css } from 'styled-components';

interface ContainerProps {
  width: string;
}

interface InputContainerProps {
  height: string;
  focus: boolean;
  disabled?: boolean | undefined;
  error: boolean;
  backgroundColor?: string;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  font-weight: lighter;
  padding-left: 16px;
  border: 1px solid #bfc4ca;
  background: #ffffff;
`;

interface LabelProps {
  isLabelBold: boolean;
  disabled?: boolean;
}

export const Label = styled.span<LabelProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${props => (props.isLabelBold ? '#353535' : '#444a51')};
  margin-bottom: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #bfc4ca;
    `}
`;

export const InputContainer = styled.span<InputContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${props => props.height};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: ${props => props.backgroundColor};

  input {
    font-family: inherit;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #444a51;
    padding-left: 16px;

    ::placeholder {
      color: #bfc4ca;
    }

    :focus {
      #spanfocus {
        border-color: #106bef;
      }
    }
  }

  .icon {
    margin-right: 13px;
  }

  :hover {
    border-color: #106bef;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fafafb;

      :hover {
        border-color: #bfc4ca;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #ea3e4f;

      :hover {
        border-color: #ea3e4f;
      }
    `}
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;
