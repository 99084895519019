/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { Container } from './styles/StyledIcon';

interface ownProps {
  src: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  popover?: string;
  color?: string;
  width?: string;
  height?: string;
  onClick?: () => any;
  isCircular?: boolean;
  className?: string;
}

const Icon: React.FC<ownProps> = props => {
  const Image = props.src;

  return (
    <Container
      width={props.width}
      height={props.height}
      color={props.color}
      onClick={props.onClick}
      className={props.className}
    >
      {typeof props.src === 'string' ? (
        <img src={props.src} alt={props.className} />
      ) : (
        <Image />
      )}
    </Container>
  );
};

export default Icon;
