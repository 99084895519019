import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetFilteredUsers as UsecaseRemoteGetFilteredUsers } from 'domain/usecases/user/remote';
// import { GetUser as UsecaseReduxGetUser } from 'domain/usecases/user/redux';

import { RemoteGetFilteredUsers } from 'data/repository/user';
// import { ReduxGetUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetFilteredUsers = (): UsecaseRemoteGetFilteredUsers =>
  new RemoteGetFilteredUsers(makeApiUrl('/users/filtered/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetUser =
  (): UsecaseReduxGetUser =>
    new ReduxGetUser(); */
