import { iUser } from 'domain/interfaces/models';

export const getRole = (
  administrator: iUser['records'][0]['administrator'],
  orgId: number,
  orgUnitId?: number,
): 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD' => {
  if (administrator.system) {
    return 'SYSADMIN';
  }

  if (administrator.org.length > 0 && administrator.org.includes(orgId)) {
    return 'ORG_ADMIN';
  }

  if (
    orgUnitId &&
    administrator.orgUnit.length > 0 &&
    administrator.orgUnit.includes(orgUnitId)
  ) {
    return 'UNIT_ADMIN';
  }

  return 'STANDARD';
};

export const getOrgUserRole = (
  sysAdmin: boolean,
  orgAdmin: boolean,
  unitAdmin: boolean,
): 'SYSADMIN' | 'ORG_ADMIN' | 'UNIT_ADMIN' | 'STANDARD' => {
  if (sysAdmin) {
    return 'SYSADMIN';
  }

  if (orgAdmin) {
    return 'ORG_ADMIN';
  }

  if (unitAdmin) {
    return 'UNIT_ADMIN';
  }

  return 'STANDARD';
};
