/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ThemeContext } from 'App';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteCreateConference } from 'main/factories/usecases/conference/CreateConferenceFactory';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
// import { IconBack } from 'presentation/base/icons';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { History } from 'main/routes';
import {
  Agendar,
  IconAddProfessional,
  IconFilter,
  IconSearch,
  Instantanea,
} from 'presentation/base/icons';
import { Drawer } from 'presentation/components/drawer';
import { Button } from 'presentation/components/UI/buttons';
import Popover from 'presentation/components/UI/popover/Popover';
import Select from 'presentation/components/UI/select';
import { closeModal } from 'utils/closeModal';
import { getRole } from 'utils/getRole';
import ConferenceDetails from '../conferenceDetails/ConferenceDetails';
import Translator from '../i18n/Translator';
import {
  Container,
  Content,
  Drop,
  Left,
  Options,
  Right,
  Search,
} from './styles/StylePageHeader';
import FilterAppointment from '../filter/FilterAppointment';

interface ownProps {
  setViz: Function;
  typeViz: string;
  search?: string;
  setSearch?: Function;
  onSearch?: Function;
}

type Props = ownProps;

const PageHeader: React.FC<Props> = ({
  setViz,
  typeViz,
  search,
  setSearch,
  onSearch,
}): JSX.Element => {
  const [searchFilter, setSearchFilter] = useState('');

  const url = window.location.pathname;
  const showDetails = useSelector(
    (store: iStore) => store.conference.controller?.showDetails,
  );

  const [loading, setLoading] = useState(false);
  const { records } = useSelector((store: iStore) => store.orgUnits);

  const { user: userRedux } = useSelector((store: iStore) => store.auth);

  const { theme } = useContext(ThemeContext);

  const iconRef = useRef<any>();

  useEffect(() => {
    const keyPressEvent = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        iconRef?.current?.click();
      }
    };

    document.addEventListener('keydown', keyPressEvent);

    return () => {
      document.removeEventListener('keydown', keyPressEvent);
    };
  }, []);

  const searchConference = (
    <div>
      <Drop>
        <FilterAppointment search={searchFilter} setSearch={setSearchFilter} />
      </Drop>
    </div>
  );

  const instantConference = async () => {
    if (!userRedux?.administrator) return;

    const now = new Date();
    const role = getRole(
      userRedux.administrator,
      userRedux.org.id,
      userRedux.orgUnit.id,
    );

    // toast.info('Criando reunião instantânea...');

    setLoading(true);

    await makeRemoteCreateConference()
      .create({
        body: {
          // TODO: Enviar owner e org apenas quando criar conferencia para outro user
          // owner: Number(userRedux?.id),
          // org: Number(userRedux?.org?.id),
          title: `Reunião em ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`,
          descr: '',
          scheduled: now.toISOString(),
          expectedDuration: 60,
          allowInvite: true,
          notifyOptions: {
            email: false,
            sms: false,
            whatsapp: false,
          },
        },
      })
      .then(res => {
        makeReduxListConferenceFilteredByACLV2().list({
          query: {
            limit: 9999,
          },
        });

        toast.success(Translator('Reunião Agendada com sucesso!'));
        setTimeout(() => {
          window
            .open(`/join?t=${res?.short}&u=${userRedux.fullName}`, '_blank')
            ?.focus();
        }, 1500);
      })
      .catch(() => {
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Criação de Reunião Instantânea'),
          content: Translator('Não foi possível criar a reunião'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const MenuOptions = (
    <Options>
      <button
        data-testid="btn-instant-conference"
        type="button"
        onClick={instantConference}
        disabled={loading}
      >
        <Drop id="btn_login" loading={loading}>
          {loading ? (
            <CircularProgress
              style={{
                width: '30px',
                height: '30px',
                color: `${theme.colors.primary.main}`,
              }}
            />
          ) : (
            <>
              <Instantanea />
              <span>{Translator('Reunião instantânea')}</span>
            </>
          )}
        </Drop>
      </button>

      <Drop
        id="btn_logout"
        data-testid="btn-new-conference"
        onClick={() => {
          History.getHistory().push('appointment/create', {
            from: url.replaceAll('/', ''),
          });
        }}
      >
        <Agendar />
        <span>{Translator('Agendar reunião')}</span>
      </Drop>
    </Options>
  );

  return (
    <>
      <Container>
        <Content>
          <Left>
            <div data-testid="text-visualization">
              {Translator('Visualização')}
            </div>
            <Select
              data-testid="select-visualization"
              width="150px"
              height="34px"
              value={typeViz}
              onChange={e => setViz(e.target.value)}
            >
              <option data-testid="option1-visualization" value="S">
                {Translator('Dia')}
              </option>
              <option data-testid="option2-visualization" value="M">
                {Translator('Mês')}
              </option>
            </Select>
            {/* TODO: Implement search function */}
            <Search ref={iconRef} onClick={() => onSearch?.()}>
              <input
                id="input_search"
                placeholder={Translator('Pesquisar')}
                onChange={e => setSearch?.(e.target.value)}
                value={search}
              />
              <IconSearch id="btn_searchFilter" onClick={() => onSearch?.()} />
            </Search>
            <Popover content={searchConference}>
              <IconFilter />
            </Popover>
          </Left>
          <Right>
            <Popover
              content={MenuOptions}
              originX="right"
              originY="bottom"
              positionY="top"
              positionX="right"
              resourcers={['CONFERENCE']}
              actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
            >
              <Button
                data-testid="btn-create-conference"
                size="medium"
                icon={IconAddProfessional}
                background={theme.colors.primary.main}
                rounded
              >
                {Translator('Nova reunião')}
              </Button>
            </Popover>
          </Right>
        </Content>
      </Container>
      <Drawer
        anchor="right"
        isOpen={showDetails}
        handleClose={() =>
          makeReduxUpdateControllerConference().updateController({
            showDetails: false,
          })
        }
      >
        <ConferenceDetails />
      </Drawer>
    </>
  );
};

export default PageHeader;
