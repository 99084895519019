/* eslint-disable no-nested-ternary */
import { iStore } from 'domain/interfaces/models';
import { makeReduxListOrgUser } from 'main/factories/usecases/orgUser/ListOrgUserFactory';
import { IconHealth } from 'presentation/base/icons';
import Pagination from 'presentation/components/Pagination';
import ProfileNavigator from 'presentation/components/PerfilNavigator/PerfilNavigator';
import { Button } from 'presentation/components/UI';
import { Header, HeaderBack } from 'presentation/components/header';
import Translator from 'presentation/components/i18n/Translator';
import { ListUsers } from 'presentation/components/list';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getRole } from 'utils/getRole';
import { UpdateListOrgUser } from 'utils/updateListUsers';
import { makeRemoteGetFilteredUsers } from 'main/factories/usecases/user/GetFilteredUsers';
import { IOrderBy } from 'presentation/components/list/Users';
import UserPage from '../Admin/Users';
import ChangePasswordPage from './ChangePassword';
import {
  Body,
  Container,
  ContentUser,
  Left,
  List,
  Right,
} from './styles/StyledUser';

const User: React.FC = () => {
  const url = window.location.pathname;
  const [navigation, setNavigation] = useState<number>(1);
  const [usersFiltered, setUsersFiltered] = useState<any[]>([]);
  const [allOrderBy, setAllOrderBy] = useState<IOrderBy[]>([
    {
      order: 1,
      attribute: 'NAME',
      direction: 'DESC',
    },
    {
      order: 1,
      attribute: 'EMAIL',
      direction: 'DESC',
    },
  ]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [haveUpdated, setHaveUpdated] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const { records } = useSelector((store: iStore) => store.orgUsers);
  const { user } = useSelector((store: iStore) => store.auth);

  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 15;

  const navigate = useHistory();
  const { pathname } = useLocation();

  const userFiltered = useMemo(
    () => records.find(item => item.user.id === selectedUserId),
    [records, selectedUserId],
  );

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return <UserPage userId={selectedUserId} editing />;
      case 2:
        return <ChangePasswordPage userId={selectedUserId} />;
      default:
        return <div />;
    }
  }, [navigation, selectedUserId]);

  const displayData = useMemo(() => {
    // const orderedRecords = _.orderBy(
    //   usersFiltered,
    //   item => `${item.user.fullName}`.toLowerCase(),
    //   ['asc'],
    // );

    const start = (page - 1) * itemsPerPage;

    return usersFiltered?.slice(start, start + itemsPerPage);
  }, [usersFiltered, page]);

  const handleCreateUser = () => {
    navigate.push('/admin/user/create', {
      from: url.replaceAll('/', ''),
    });
  };

  const handleFilterUsers = useCallback(() => {
    if (search) {
      makeReduxListOrgUser().list({
        query: {
          limit: 99999,
          searchBar: search,
        },
      });
    }
  }, [search]);

  const handleOrderBy = (type?: string) => {
    setLoadingUser(true);
    let obj: IOrderBy = {} as IOrderBy;

    if (type === 'user' || type === undefined) {
      obj = {
        order: 1,
        attribute: 'NAME',
        direction:
          type === undefined
            ? 'ASC'
            : allOrderBy[0].direction === 'ASC'
            ? 'DESC'
            : 'ASC',
      };
      setAllOrderBy([obj, allOrderBy[1]]);
    } else {
      obj = {
        order: allOrderBy[1].order,
        attribute: 'EMAIL',
        direction: allOrderBy[1].direction === 'ASC' ? 'DESC' : 'ASC',
      };
      setAllOrderBy([allOrderBy[0], obj]);
    }

    makeRemoteGetFilteredUsers()
      .get({
        dataControl: {
          limit: 999999,
          offset: 0,
          paging: true,
        },
        orderBy: [obj],
      })
      .then(res => {
        const newArr: any[] = [];
        res.records.forEach(item => {
          const newObj = {
            user: {
              id: item.id,
              login: item.login,
              firstName: item.firstName,
              lastName: item.lastName,
              fullName: item.fullName,
              email: item.email,
              birthDate: item.birthDate,
              avatar: item.avatar,
            },
            orgUnit: {
              id: item.orgUsers[0].orgUnit.id,
              shortname: item.orgUsers[0].orgUnit.name,
              name: item.orgUsers[0].orgUnit.name,
              descr: item.orgUsers[0].orgUnit.descr,
              logo: item.orgUsers[0].orgUnit.logo,
              org: item.orgUsers[0].orgUnit.org,
            },
          };

          newArr.push(newObj);
        });
        setUsersFiltered(newArr);
        setLoadingUser(false);
      })
      .catch(err => {
        console.error(err);
        setLoadingUser(false);
      });
  };

  useEffect(() => {
    handleOrderBy();
  }, []);

  useEffect(() => {
    if (!search) {
      makeReduxListOrgUser().list({
        query: {
          limit: 99999,
        },
      });
    }
  }, [haveUpdated, user, search]);

  const { active } = useSelector((store: iStore) => store.message);

  useEffect(() => {
    if (!user?.administrator) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    UpdateListOrgUser(role, user?.org?.id, user?.orgUnit?.id);
  }, [haveUpdated, user, active]);

  useEffect(() => {
    const lastQuery = pathname.split('/').pop();

    if (Number(lastQuery)) {
      setSelectedUserId(Number(lastQuery));
    }
  }, [pathname]);

  return (
    <Container>
      <Header title="teste" haveUpdated={haveUpdated} />
      {!selectedUserId && (
        <HeaderBack
          title={`${Translator('Usuários Cadastrados')}`}
          hasFilter
          search={search}
          setSearch={setSearch}
          onSearch={handleFilterUsers}
        />
      )}
      {selectedUserId ? (
        <>
          <HeaderBack
            onBack={() => {
              setSelectedUserId(0);
              navigate.goBack();
            }}
            title={`Detalhes do usuário: ${userFiltered?.user?.fullName}`}
          />
          <Body>
            <Left>
              <ProfileNavigator
                user={userFiltered}
                active={navigation}
                nav={setNavigation}
                haveUpdated={() => setHaveUpdated(prevState => !prevState)}
              />
            </Left>
            <Right>{RenderSubPages}</Right>
          </Body>
        </>
      ) : (
        <>
          <ContentUser>
            <Pagination
              currentPage={page}
              pageSize={Math.ceil(usersFiltered.length / itemsPerPage)}
              setPage={setPage}
            />
            <div>
              <Button
                id="newUser"
                icon={IconHealth}
                rounded
                variant="primary"
                size="medium"
                onClick={handleCreateUser}
                resourcers={['USER']}
                actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
              >
                {Translator('Novo Usuário')}
              </Button>
            </div>
          </ContentUser>
          <List>
            {loadingUser ? (
              <>loading..</>
            ) : (
              <ListUsers dataList={displayData} setAllOrderBy={handleOrderBy} />
            )}
          </List>
        </>
      )}
    </Container>
  );
};

export default User;
