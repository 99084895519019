/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'presentation/components/icon';
import { Button } from 'presentation/components/UI/buttons';
import { IconFilter } from 'presentation/base/icons';

import { Drawer as DrawerMui } from '@mui/material';
import { Container, Content, BaseDrawer } from './styles/StyledDrawer';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

// import { IconBack } from 'presentation/base/icons';

interface ownProps {
  children: React.ReactNode;
  isOpen: boolean;
  anchor: Anchor;
  handleClose: () => void;
}

type Props = ownProps;

const Drawer: React.FC<Props> = ({
  children,
  anchor,
  isOpen,
  handleClose,
}): JSX.Element => {
  const closeDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    handleClose();
  };

  return (
    <Container>
      <Content>
        <DrawerMui anchor={anchor} open={isOpen} onClose={closeDrawer()}>
          <BaseDrawer>{children}</BaseDrawer>
        </DrawerMui>
      </Content>
    </Container>
  );
};

export default Drawer;
