/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableSortLabel } from '@material-ui/core';

import { History } from 'main/routes';
import { IconSort } from 'presentation/base/icons';
import { ActionsList } from 'presentation/components/actionsList';
import { ScheduleStatus } from 'presentation/components/statusList';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeRemoteDeleteConference } from 'main/factories/usecases/conference/DeleteConferenceFactory';
import { makeReduxListConference } from 'main/factories/usecases/conference/ListConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { iStore } from 'domain/interfaces/models';
import { getRole } from 'utils/getRole';
import { toast } from 'react-toastify';
import { makeReduxListConferenceFilteredByACL } from 'main/factories/usecases/conference/ListConferenceFilteredByACLFactory';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import { iListAppointment, iColumnsConfig } from './interface';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

interface ownProps {
  dataList?: iListAppointment[];
}

type iColumns =
  | 'date'
  | 'fullName'
  | 'timeStart'
  | 'timeEnd'
  | 'title'
  | 'typeIs'
  | 'org'
  | 'status'
  | 'actions';

type iOrder =
  | 'fullName'
  | 'timeStart'
  | 'timeEnd'
  | 'title'
  | 'typeIs'
  | 'specialty'
  | 'status';
const Appointment: React.FC<ownProps> = props => {
  const [order, setOrder] = useState<boolean>(true);
  const [dataList, setData] = useState<iListAppointment[] | undefined>(
    props.dataList,
  );
  const loading = false;
  const url = window.location.pathname;
  const arrToCompare = [
    'fullName',
    'timeStart',
    'timeEnd',
    'typeIs',
    'specialty',
    'status',
  ];
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'date',
      label: 'Data',
      minWidth: 20,
      maxWidth: 70,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'timeStart',
      label: 'Início',
      minWidth: 20,
      maxWidth: 70,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'timeEnd',
      label: 'Fim',
      minWidth: 20,
      maxWidth: 70,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'title',
      label: 'Título',
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'fullName',
      label: 'Organizador',
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'org',
      label: 'Órgão',
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 90,
      maxWidth: 160,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: 'Ações',
      minWidth: 50,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const user = useSelector((store: iStore) => store.auth.user);

  function StopAppointment(appointment: any) {
    if (!user?.administrator) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    if (appointment.status === 'AVAILABLE') {
      return (
        <ActionsList
          actions={{
            detailsConference: appointment?.permission?.update
              ? () =>
                  History.getHistory().push(
                    `/appointment/edit/${appointment.id}`,
                    {
                      from: url.replaceAll('/', ''),
                    },
                  )
              : undefined,
            stop: () => console.log('stop'),
          }}
        />
      );
    }
    if (appointment.status === 'INPROGRESS') {
      return (
        <ActionsList
          actions={{
            detailsConference: appointment?.permission?.update
              ? () =>
                  History.getHistory().push(
                    `/appointment/edit/${appointment.id}`,
                    {
                      from: url.replaceAll('/', ''),
                    },
                  )
              : undefined,
            start: appointment?.permission?.join
              ? () =>
                  window
                    .open(`/preconf?t=${appointment?.short}`, '_blank')
                    ?.focus()
              : undefined,
            deleteConference: appointment?.permission?.delete
              ? async () => {
                  makeReduxActiveMessage().active({
                    active: 'confirm',
                    content: 'Deseja realmente excluir a reunião?',
                    title: 'Remoção de Reunião',
                    actionOk: async () => {
                      makeRemoteDeleteConference()
                        .delete({
                          conferenceId: appointment.id,
                        })
                        .then(res => {
                          makeReduxListConferenceFilteredByACLV2().list({
                            query: {
                              limit: 9999,
                            },
                          });

                          toast.success('Reunião excluida com sucesso');
                          closeModal();
                        })
                        .catch(err => {
                          makeReduxActiveMessage().active({
                            active: 'error',
                            title: 'Remoção de Reunião',
                            content: 'Não foi possível remover a reunião.',
                            actionOk: () => closeModal(),
                            actionCancel: () => closeModal(),
                          });
                        });
                    },
                    actionCancel: () => closeModal(),
                  });
                }
              : undefined,
          }}
        />
      );
    }

    if (appointment.status === 'ENDED' || appointment.status === 'CANCELED') {
      return (
        <ActionsList
          actions={{
            deleteConference: appointment?.permission?.delete
              ? async () => {
                  makeReduxActiveMessage().active({
                    active: 'confirm',
                    content: 'Deseja realmente excluir a reunião?',
                    title: 'Remoção de Reunião',
                    actionOk: async () => {
                      makeRemoteDeleteConference()
                        .delete({
                          conferenceId: appointment.id,
                        })
                        .then(res => {
                          makeReduxListConferenceFilteredByACLV2().list({
                            query: {
                              limit: 9999,
                            },
                          });

                          toast.success('Reunião excluida com sucesso');
                          closeModal();
                        })
                        .catch(err => {
                          makeReduxActiveMessage().active({
                            active: 'error',
                            title: 'Remoção de Reunião',
                            content: 'Não foi possível remover a reunião.',
                            actionOk: () => closeModal(),
                            actionCancel: () => closeModal(),
                          });
                        });
                    },
                    actionCancel: () => closeModal(),
                  });
                }
              : undefined,
          }}
        />
      );
    }
    if (
      appointment.status === 'SCHEDULED' ||
      appointment.status === 'CONFIRMED'
    ) {
      return (
        <ActionsList
          actions={{
            detailsConference: appointment?.permission?.update
              ? () =>
                  History.getHistory().push(
                    `/appointment/edit/${appointment.id}`,
                    {
                      from: url.replaceAll('/', ''),
                    },
                  )
              : undefined,
            start: appointment?.permission?.join
              ? () =>
                  window
                    .open(`/preconf?t=${appointment?.short}`, '_blank')
                    ?.focus()
              : undefined,
            deleteConference: appointment?.permission?.delete
              ? async () => {
                  makeReduxActiveMessage().active({
                    active: 'confirm',
                    content: 'Deseja realmente excluir a reunião?',
                    title: 'Remoção de Reunião',
                    actionOk: async () => {
                      makeRemoteDeleteConference()
                        .delete({
                          conferenceId: appointment.id,
                        })
                        .then(res => {
                          makeReduxListConferenceFilteredByACLV2().list({
                            query: {
                              limit: 9999,
                            },
                          });

                          toast.success('Reunião excluida com sucesso');
                          closeModal();
                        })
                        .catch(err => {
                          makeReduxActiveMessage().active({
                            active: 'error',
                            title: 'Remoção de Reunião',
                            content: 'Não foi possível remover a reunião.',
                            actionOk: () => closeModal(),
                            actionCancel: () => closeModal(),
                          });
                        });
                    },
                    actionCancel: () => closeModal(),
                  });
                }
              : undefined,
          }}
        />
      );
    }

    return (
      <ActionsList
        actions={{
          stop: () => console.log('stop'),
          deleteConference: appointment?.permission?.delete
            ? async () => {
                makeReduxActiveMessage().active({
                  active: 'confirm',
                  content: 'Deseja realmente excluir a reunião?',
                  title: 'Remoção de Reunião',
                  actionOk: async () => {
                    makeRemoteDeleteConference()
                      .delete({
                        conferenceId: appointment.id,
                      })
                      .then(res => {
                        makeReduxListConferenceFilteredByACLV2().list({
                          query: {
                            limit: 9999,
                          },
                        });

                        toast.success('Reunião excluida com sucesso');
                        closeModal();
                      })
                      .catch(err => {
                        makeReduxActiveMessage().active({
                          active: 'error',
                          title: 'Remoção de Reunião',
                          content: 'Não foi possível remover a reunião.',
                          actionOk: () => closeModal(),
                          actionCancel: () => closeModal(),
                        });
                      });
                  },
                  actionCancel: () => closeModal(),
                });
              }
            : undefined,
        }}
      />
    );
  }

  useEffect(() => {
    setOrder(false);
    setData(props.dataList);
  }, [props.dataList]);

  const RenderComponents: React.FC<{ id: iColumns; appointment: any }> = ({
    id,
    appointment,
  }) => {
    switch (id) {
      case 'actions':
        return StopAppointment(appointment)!;
      case 'status':
        return <ScheduleStatus status={appointment.status} />;
      case 'fullName':
        return <text>{`${appointment.fullName}`}</text>;
      case 'title':
        return (
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >{`${appointment.title}`}</div>
        );
      default:
        return <div>{appointment[`${id}`]}</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => <div style={{ paddingLeft: 3 }} />}
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={() => {
                        setOrder(prevState => !prevState);
                        setData(props.dataList);
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <div>skeleton</div>
                  </TableRow>
                );
              })}
            </>
          ) : (
            props.dataList?.map((appointment: iListAppointment) => (
              <TableRow key={appointment.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        padding: '13px 16px',
                      }}
                      onClick={() => {
                        if (columnProps.id !== 'actions') {
                          makeReduxUpdateControllerConference().updateController(
                            {
                              selected: appointment.id,
                              showDetails: true,
                            },
                          );
                        }
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        appointment={appointment}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Appointment;
