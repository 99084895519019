import { number } from 'zod';
import { ListFilteredByACLV2DTO } from 'domain/dtos/conference/ListFilteredByACLV2';
import { iConference } from 'domain/interfaces/models/Conference';
import { ConferenceTypes } from 'domain/interfaces/redux/conference/types';
import { ConferenceActions } from './actions';

export const initialState: iConference = {
  loading: false,
  metadata: undefined,
  records: undefined,
  controller: {
    showDetails: false,
    selected: 0,
    selectedDate: new Date(),
  },
};

const reducer = (
  state = initialState,
  action: ConferenceActions,
): iConference => {
  switch (action.type) {
    case ConferenceTypes.LIST:
      return { ...state, loading: true };
    case ConferenceTypes.LIST_FAILED:
      return { ...state, loading: false };
    case ConferenceTypes.LIST_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case ConferenceTypes.LIST_FILTERED_BY_ACL:
      return { ...state, loading: true };
    case ConferenceTypes.LIST_FILTERED_BY_ACL_FAILED:
      return { ...state, loading: false };
    case ConferenceTypes.LIST_FILTERED_BY_ACL_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case ConferenceTypes.LIST_FILTERED_BY_ACL_V2:
      return { ...state, loading: true };
    case ConferenceTypes.LIST_FILTERED_BY_ACL_V2_FAILED:
      return { ...state, loading: false };
    case ConferenceTypes.LIST_FILTERED_BY_ACL_V2_SUCCESS: {
      const updatedPayload = ListFilteredByACLV2DTO(action.payload);

      return { ...state, ...updatedPayload, loading: false };
    }

    case ConferenceTypes.UPDATE_CONTROLLER: {
      const controller = { ...state.controller, ...action.payload };
      return { ...state, controller };
    }
    default:
      return state;
  }
};

export default reducer;
