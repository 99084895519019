import { iAuth } from 'domain/interfaces/models/Auth';
import { AuthTypes } from 'domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  access: {},
  refresh: {},
  user: undefined,
  permissions: {},
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        access: action.payload.access,
        refresh: action.payload.refresh,
        permissions: action.payload.permissions,
        loading: false,
        user: { ...action.payload.user },
      };
    }
    case AuthTypes.REFRESH:
      return { ...state, loading: true };
    case AuthTypes.REFRESH_FAILED:
      return { ...state, loading: false };
    case AuthTypes.REFRESH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        access: action.payload.access,
        refresh: action.payload.refresh,
        permissions: action.payload.permissions,
        loading: false,
        user: { ...action.payload.user },
      };
    }
    default:
      return state;
  }
};

export default reducer;
