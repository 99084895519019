/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import * as dateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloseIcon from '@mui/icons-material/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleIcon from '@mui/icons-material/People';
import VideocamIcon from '@mui/icons-material/Videocam';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { IconAddProfessional } from 'presentation/base/icons';

import { Button } from 'presentation/components/UI/buttons';
import { History } from 'main/routes';
import { iRecords, iStore } from 'domain/interfaces/models';

import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeRemoteListRecordingsConference } from 'main/factories/usecases/conference/ListRecordingsConferenceFactory';
import { makeRemoteDownloadConference } from 'main/factories/usecases/conference/DownloadConferenceFactory';
import { ListRecordingsConference } from 'domain/usecases/conference/remote';
import { closeModal } from 'utils/closeModal';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteDeleteConference } from 'main/factories/usecases/conference/DeleteConferenceFactory';

import { formatBytes } from 'utils/formatBytes';
import { addPadWithZeroes, padWithZeroes } from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import { makeReduxListConferenceFilteredByACLV2 } from 'main/factories/usecases/conference/ListConferenceFilteredByACLV2Factory';
import { ThemeContext } from 'App';
import {
  Container,
  HeaderText,
  HeaderTitle,
  HeaderSubtitle,
  RowContainer,
  SpacedRowContainer,
  Status,
  CenteredDivider,
  ContentDivider,
  DownloadText,
  RecordingsContainer,
  RecordingsText,
  RecordingsItemContainer,
  ListContainer,
  ListItem,
  User,
  Avatar,
  Info,
  Records,
  RecordingsDownloadIcon,
} from './styles/StyledConferenceDetails';
import { AvatarImage } from '../avatar';
import Translator from '../i18n/Translator';

const ConferenceDetails: React.FC = (): JSX.Element => {
  const url = window.location.pathname;
  const [recordings, setRecordings] = useState<
    ListRecordingsConference.Model['records']
  >([]);
  const [loading, setLoading] = useState<{ [id: string]: boolean }>({});

  const { controller, records } = useSelector(
    (store: iStore) => store.conference,
  );
  const user = useSelector((store: iStore) => store.auth.user);

  const { theme } = useContext(ThemeContext);

  const conference: iRecords | undefined = records
    ? records.filter(el => {
        return el.id === controller.selected;
      })[0]
    : undefined;

  const roleRedux = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const formartDate = (conf: iRecords) => {
    const scheduled = new Date(conf?.scheduled);
    const time = scheduled.getTime();

    return (
      <>
        {dateFns.format(scheduled, "EEEE, dd 'de' MMMM - ", {
          locale: ptBR,
        })}
        {padWithZeroes(scheduled.getHours(), 2)}:
        {padWithZeroes(scheduled.getMinutes(), 2)}
      </>
    );
  };

  const handleClose = useCallback(() => {
    makeReduxUpdateControllerConference().updateController({
      showDetails: false,
    });
  }, []);

  const handleDownload = useCallback(
    (id: number) => {
      if (conference?.id && id) {
        setLoading({ ...loading, [id]: true });

        makeRemoteDownloadConference()
          .download({
            conferenceId: Number(conference.id),
            recordingId: id,
          })
          .then(res => {
            if (!res.url) return;

            const link = document.createElement('a');
            const scheduledDate = new Date(conference.scheduled);

            const filename = `PBMeet_Reuniao_${addPadWithZeroes(
              scheduledDate.getDate(),
            )}-${addPadWithZeroes(
              scheduledDate.getMonth() + 1,
            )}-${scheduledDate.getFullYear()}_${addPadWithZeroes(
              scheduledDate.getHours(),
            )}-${addPadWithZeroes(scheduledDate.getMinutes())}.mp4`;

            link.download = filename;
            link.href = res.url;
            link.target = '_blank';

            console.log('Recording <a>: ', link);

            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            // toast.error('Não foi possível realizar o download da gravação');
          })
          .finally(() => {
            setLoading({ ...loading, [id]: false });
          });
      }
    },
    [conference?.id, conference?.scheduled, loading],
  );

  const handleDelete = useCallback(() => {
    if (!conference?.id || !user?.administrator) return;

    const role = getRole(user.administrator, user.org.id, user.orgUnit.id);

    makeReduxActiveMessage().active({
      active: 'confirm',
      content: Translator('Deseja realmente excluir a reunião?'),
      title: Translator('Remoção de Reunião'),
      actionOk: async () => {
        makeRemoteDeleteConference()
          .delete({
            conferenceId: Number(conference?.id),
          })
          .then(res => {
            makeReduxUpdateControllerConference().updateController({
              showDetails: false,
            });

            makeReduxListConferenceFilteredByACLV2().list({
              query: {
                limit: 9999,
              },
            });

            toast.success(Translator('Reunião excluida com sucesso'));
            closeModal();
          })
          .catch(err => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Remoção de Reunião'),
              content: Translator('Não foi possível remover a reunião.'),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      },
      actionCancel: () => closeModal(),
    });
  }, [conference?.id, user]);

  useEffect(() => {
    if (conference?.id)
      makeRemoteListRecordingsConference()
        .listRecordings({
          conferenceId: Number(conference?.id),
          query: {
            disablePagination: true,
          },
        })
        .then(res => {
          setRecordings(res.records);
        })
        .catch(err => {
          // toast.error('Não foi possível buscar gravações');
        });
  }, [conference?.id]);

  useEffect(() => {
    makeReduxListConferenceFilteredByACLV2().list({
      query: {
        limit: 9999,
      },
    });
  }, [roleRedux, user?.id, user?.org?.id, user?.orgUnit?.id]);

  return (
    <Container>
      <CloseIcon
        onClick={handleClose}
        style={{ marginBottom: '8px', cursor: 'pointer' }}
      />
      <Tooltip
        title={conference ? conference?.title : ''}
        placement="top"
        arrow
      >
        <HeaderTitle>{conference?.title}</HeaderTitle>
      </Tooltip>
      <HeaderSubtitle style={{ marginBottom: '16px' }}>
        {conference && formartDate(conference)}
      </HeaderSubtitle>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '100px',
          textAlign: 'justify',
        }}
      >
        <HeaderText>
          <b>Descrição: </b> {conference?.descr}
        </HeaderText>
      </div>
      <RowContainer style={{ marginTop: '16px' }}>
        <FormatListBulletedIcon />
        <HeaderText bold>{Translator('Ações')}</HeaderText>
        <CenteredDivider />
      </RowContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '22px 0px',
          width: '100%',
          gap: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          data-testid="btn-edit"
          size="small"
          icon={EditIcon}
          background="#45B4F2"
          rounded
          permissionsV2={conference?.permission.update}
          onClick={() => {
            History.getHistory().push(`/appointment/edit/${conference?.id}`, {
              from: url.replaceAll('/', ''),
            });
          }}
        >
          {Translator('Editar')}
        </Button>
        <Button
          data-testid="btn-delete"
          size="small"
          icon={DeleteIcon}
          background="#45B4F2"
          rounded
          permissionsV2={conference?.permission.delete}
          onClick={handleDelete}
        >
          {Translator('Remover')}
        </Button>
        <a
          href={`/preconf?t=${conference?.short}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'white' }}
        >
          <Button
            data-testid="btn-join-conference"
            size="medium"
            icon={IconAddProfessional}
            background={theme.colors.primary.main}
            rounded
            permissionsV2={conference?.permission.join}
          >
            {Translator('Entrar na Reunião')}
          </Button>
        </a>
        <Button
          data-testid="btn-copy-link"
          size="medium"
          icon={CopyIcon}
          background={theme.colors.primary.main}
          onClick={() => {
            const link = `${window.location.origin}/preconf?t=${conference?.short}`;

            navigator.clipboard.writeText(link);
            toast.info('URL copiada para a área de transferência');
          }}
          rounded
          permissionsV2={conference?.permission.join}
        >
          {Translator('Copiar link')}
        </Button>
      </div>
      <RowContainer>
        <ManageAccountsIcon />
        <HeaderText bold>{Translator('Organização')}</HeaderText>
        <CenteredDivider />
      </RowContainer>
      <ContentDivider>
        <HeaderText>
          <b>{Translator('Organizador')}: </b>
          {`${conference?.owner.fullName}`}
        </HeaderText>
        <HeaderText>
          <b>{Translator('Órgão')}: </b>
          {`${conference?.orgUnit.org.shortname.toLocaleUpperCase()}/${conference?.orgUnit.shortname.toLocaleUpperCase()}`}
        </HeaderText>
      </ContentDivider>
      <RowContainer>
        <PeopleIcon />
        <HeaderText bold style={{ width: '40%' }}>
          {`Participantes (${conference?.members.total})`}
        </HeaderText>
        <CenteredDivider />
      </RowContainer>
      <ContentDivider>
        <SpacedRowContainer>
          <HeaderText color="#45F275" bold>
            {`Confirmaram: ${conference?.members.confirmedPresence}`}
          </HeaderText>
          {/* Use redish as color */}
          <HeaderText color="#fb6161" bold>
            {`Declinaram: ${conference?.members.confirmedAbsence}`}
          </HeaderText>
          <HeaderText color="#45B4F2" bold>
            {`Talvez: ${conference?.members.maybePresence}`}
          </HeaderText>
          <HeaderText color="#3f3f3f" bold>
            {`Pendentes: ${conference?.members.pendingConfirmation}`}
          </HeaderText>
        </SpacedRowContainer>
        <SpacedRowContainer style={{ marginTop: '8px' }}>
          <ListContainer>
            {conference?.participants.length &&
              conference?.participants.map(value => {
                return (
                  <ListItem>
                    <User>
                      <Avatar>
                        <AvatarImage
                          src={value?.user?.avatar}
                          size="mini"
                          name={
                            value?.fullName ??
                            `${value?.user?.firstName} ${value?.user?.lastName}`
                          }
                        />
                      </Avatar>
                      <Info>
                        <div>
                          {value?.fullName ??
                            `${value?.user?.firstName} ${value?.user?.lastName}`}
                        </div>
                        <div>
                          {value.role === 'MODERATOR'
                            ? 'Moderador'
                            : 'Convidado'}
                        </div>
                      </Info>
                    </User>
                  </ListItem>
                );
              })}
          </ListContainer>
        </SpacedRowContainer>
      </ContentDivider>
      <Records>
        <VideocamIcon />
        <HeaderText bold>{Translator('Gravações')}:</HeaderText>
        <Status status={recordings.length ? 'in progress' : 'not recorded'}>
          {recordings.length
            ? `${Translator('Gravado')}`
            : `${Translator('Não gravado')}`}
        </Status>
        <CenteredDivider />
      </Records>
      <ContentDivider>
        <RowContainer>
          {/* <HeaderText bold>Status: </HeaderText> */}
        </RowContainer>
        <RecordingsContainer>
          {recordings.length ? (
            recordings.map(rec => (
              <RecordingsItemContainer>
                <div
                  style={{ width: '80%', borderBottom: '1px solid #c4c4c4' }}
                >
                  <RecordingsText>{`Gravação ${rec.id}`}</RecordingsText>
                  <RecordingsText color="#c4c4c4">
                    {`${formatBytes(rec.size)}`}
                  </RecordingsText>
                </div>
                {loading[rec.id] ? (
                  <CircularProgress
                    style={{
                      width: '30px',
                      height: '30px',
                      color: `${theme.colors.primary.main}`,
                    }}
                  />
                ) : (
                  <RecordingsDownloadIcon
                    onClick={() => handleDownload(rec.id)}
                    permissionsV2
                  />
                )}
              </RecordingsItemContainer>
            ))
          ) : (
            <DownloadText
              lighter
              style={{ textAlign: 'center', marginTop: '16px' }}
            >
              {Translator(
                'Você poderá fazer o download da gravação assim que disponível.',
              )}
            </DownloadText>
          )}
        </RecordingsContainer>
      </ContentDivider>
    </Container>
  );
};

export default ConferenceDetails;
