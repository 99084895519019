/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteCreateByOrgAdmin } from 'main/factories/usecases/admin/CreateByOrgAdminFactory';
import { makeRemoteCreateByOrgUnitAdmin } from 'main/factories/usecases/admin/CreateByOrgUnitAdminFactory';
import { makeRemoteListAdmin } from 'main/factories/usecases/admin/ListAdminFactory';
import { IconCloseWhite, IconDeleteFromList } from 'presentation/base/icons';
import { AvatarImage } from 'presentation/components/avatar';
import { Button, Select } from 'presentation/components/UI';
import { getOrgUserRole, getRole } from 'utils/getRole';
import { mapperRoles } from 'utils/mapperRoles';
import { UpdateListOrgUnit, UpdateListOrgUser } from 'utils/updateListUsers';
import { Container as GlobalContainer } from '../Background';
import { ownProps } from '../interface';
import {
  Avatar,
  Body,
  Container,
  Content,
  ContentBody,
  Info,
  ListActions,
  ListContainer,
  ListItem,
  Title,
  User,
} from './styles/StyledModals';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
}

interface iParams {
  id?: string;
  orgId?: number;
  orgUnitId?: string;
}

const SelectBondAdminModal: React.FC<ownProps> = ({ message }) => {
  const [dataUsers, setDataUsers] = useState<iUsers[]>([]);
  const [selectUsers, setSelectUsers] = useState<number[]>([]);
  const [valueUser, setValueUser] = useState<number>(-1);
  const [params, setParams] = useState<iParams>();

  const userRedux = useSelector((store: iStore) => store.auth.user);
  const { records } = useSelector((store: iStore) => store.orgUsers);

  const msgName = MessageOptions.selectBondAdmin;

  const { active, actionCancel, actionOk } = message;

  const role = useMemo(
    () =>
      userRedux?.administrator
        ? getRole(
            userRedux.administrator,
            userRedux.org.id,
            userRedux.orgUnit.id,
          )
        : 'STANDARD',
    [userRedux?.administrator, userRedux?.org.id, userRedux?.orgUnit.id],
  );

  const updateListOrgUser = useCallback(() => {
    UpdateListOrgUser(role, userRedux?.org?.id, userRedux?.orgUnit?.id);
  }, [role, userRedux?.org?.id, userRedux?.orgUnit?.id]);

  const handleSubmit = useCallback(async () => {
    if (!params?.id || !selectUsers.length)
      return toast.error('Preencha todos os campos');

    const filteringBy: 'ORG_ADMIN' | 'UNIT_ADMIN' = params?.orgUnitId
      ? 'UNIT_ADMIN'
      : 'ORG_ADMIN';

    if (filteringBy === 'UNIT_ADMIN') {
      makeRemoteCreateByOrgUnitAdmin()
        .createByOrgUnit({
          orgId: Number(params.id),
          unitId: Number(params.orgUnitId),
          body: {
            admins: selectUsers.map(item => ({ user: item })),
          },
        })
        .then(res => {
          updateListOrgUser();

          // Refetch orgUnits after delete user
          UpdateListOrgUnit();

          if (res.succeeded.length) {
            toast.success(`${res.succeeded.length} usuário(s) associado(s)`);
            actionOk?.();
          }

          if (res.failed.length)
            toast.error(
              `${res.failed.length} usuário(s) não foram associado(s)`,
            );
        })
        .catch(err => {
          // toast.error('Não foi possível criar administrador(es).');
        });
    } else {
      makeRemoteCreateByOrgAdmin()
        .createByOrg({
          orgId: Number(params.id),
          body: {
            admins: selectUsers.map(item => ({ user: item })),
          },
        })
        .then(res => {
          updateListOrgUser();

          // Refetch orgUnits after delete user
          UpdateListOrgUnit();

          if (res.succeeded.length) {
            toast.success(`${res.succeeded.length} usuário(s) associado(s)`);
            actionOk?.();
          }

          if (res.failed.length)
            toast.error(
              `${res.failed.length} usuário(s) não foram associado(s)`,
            );
        })
        .catch(err => {
          // toast.error('Não foi possível criar administrador(es).');
        });
    }
  }, [actionOk, params, role, selectUsers, updateListOrgUser, userRedux]);

  useEffect(() => {
    if (!(msgName === active)) return;

    const splitParams = window.location.pathname.split('/');
    let updatedParams: iParams = {};

    if (splitParams.length > 3) {
      updatedParams = {
        id: splitParams[3],
        orgUnitId: splitParams.length > 5 ? splitParams[5] : undefined,
      };

      setParams(updatedParams);
    }

    const filteringBy: 'ORG_ADMIN' | 'UNIT_ADMIN' = updatedParams.orgUnitId
      ? 'UNIT_ADMIN'
      : 'ORG_ADMIN';
    const usersNotDisplayed: number[] = [];

    const orgUsers: Array<iUsers | null> = records
      .map(item => {
        const orgUserRole = getOrgUserRole(
          item.sysAdmin,
          item.orgAdmin,
          item.unitAdmin,
        );

        console.log('item: ', item);

        // Usuário já é administrador da organização, logo, não retorna
        if (
          filteringBy === 'ORG_ADMIN' &&
          item?.orgUnit?.org?.id === Number(updatedParams?.id) &&
          orgUserRole === 'ORG_ADMIN'
        ) {
          usersNotDisplayed.push(item.user.id);

          return null;
        }

        // Usuário já é administrador da unidade, logo, não retorna
        if (
          filteringBy === 'UNIT_ADMIN' &&
          item?.orgUnit?.id === Number(updatedParams?.orgUnitId) &&
          orgUserRole === 'UNIT_ADMIN'
        ) {
          usersNotDisplayed.push(item.user.id);

          return null;
        }

        return {
          id: item.user.id,
          name: `${item.user.fullName}`,
          role: orgUserRole,
          Image: item.user.avatar,
          orgName: item.orgUnit?.org?.shortname ?? '',
          unitName: item.orgUnit?.shortname ?? '',
        };
      })
      .filter(Boolean);

    const filteredOrgUsers = orgUsers.filter(
      item => item && item.id && !usersNotDisplayed.includes(item.id),
    );

    if (role === 'SYSADMIN') {
      makeRemoteListAdmin()
        .list({
          query: {
            disablePagination: true,
          },
        })
        .then(res => {
          const sysAdminFiltered = filteredOrgUsers
            .map(item => {
              const isAdmin = res.records.find(item2 => {
                if (filteringBy === 'ORG_ADMIN') {
                  return (
                    item2?.org?.id === Number(updatedParams?.id) &&
                    item2?.user?.id === item?.id
                  );
                }

                return (
                  item2?.orgUnit?.id === Number(updatedParams?.orgUnitId) &&
                  item2?.user?.id === item?.id
                );
              });

              if (isAdmin) return null;

              return item;
            })
            .filter(Boolean);

          setDataUsers(sysAdminFiltered as iUsers[]);
        })
        .catch(err => {
          setDataUsers(filteredOrgUsers as iUsers[]);
        });
    } else {
      setDataUsers(filteredOrgUsers as iUsers[]);
    }

    return () => {
      setSelectUsers([]);
    };
  }, [active, msgName, role, userRedux?.id, records]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    setValueUser(-1);
  }, [active]);

  return (
    <>
      {msgName === active ? (
        <GlobalContainer>
          <Container>
            <Content>
              <Title>
                <div />
                Selecione um usuário para vincular
                <IconCloseWhite
                  onClick={actionCancel}
                  style={{ cursor: 'pointer' }}
                />
              </Title>
              <Body>
                <ContentBody>
                  <Select
                    className="select"
                    value={valueUser}
                    disabled={selectUsers.length === dataUsers?.length}
                    onChange={e => {
                      const auxLixt = selectUsers;
                      auxLixt.push(Number(e.target.value));
                      setSelectUsers(auxLixt);
                      setValueUser(Number(e.target.value));
                    }}
                  >
                    <>
                      <option value={0} style={{ display: 'none' }}>
                        Selecione um administrador
                      </option>
                      {dataUsers?.map(
                        (
                          value: {
                            id: number;
                            name:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                          },
                          index: number,
                        ) => {
                          if (!selectUsers.find(el => el === index + 1))
                            return (
                              <option value={value.id}>{value.name}</option>
                            );

                          return null;
                        },
                      )}
                    </>
                  </Select>
                  <ListContainer>
                    {dataUsers &&
                      selectUsers.map(value => {
                        const user =
                          dataUsers.find(el => el.id === Number(value)) ??
                          ({} as iUsers);

                        const { name, role: userRole, Image } = user;

                        return (
                          <ListItem>
                            <User>
                              <Avatar>
                                {Image ? (
                                  <img
                                    alt=""
                                    src={Image}
                                    style={{
                                      borderRadius: '50%',
                                      width: '44px',
                                    }}
                                  />
                                ) : (
                                  <AvatarImage name={name} size="mini" />
                                )}
                              </Avatar>
                              <Info>
                                <div>{name}</div>
                                <div>{mapperRoles(userRole)}</div>
                              </Info>
                            </User>
                            <ListActions>
                              {/* TODO: Não permitir remover a si mesmo */}
                              <IconDeleteFromList
                                onClick={() => {
                                  setSelectUsers(
                                    selectUsers.filter(el => el !== value),
                                  );
                                  setValueUser(0);
                                }}
                              />
                            </ListActions>
                          </ListItem>
                        );
                      })}
                  </ListContainer>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      paddingBottom: '29px',
                    }}
                  >
                    <Button variant="primary" onClick={handleSubmit}>
                      Vincular
                    </Button>
                  </div>
                </ContentBody>
              </Body>
            </Content>
          </Container>
        </GlobalContainer>
      ) : null}
    </>
  );
};

export default SelectBondAdminModal;
