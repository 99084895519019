import styled from 'styled-components';

interface iError {
  error?: boolean;
}

export const Container = styled.span<iError>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 48px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  #date-picker-inline-helper-text {
    display: none;
  }
`;

export const ContainerRoot = styled.div<iError>`
  width: 100%;

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${props => (props.error ? 'red' : '#444a51')};
    margin-bottom: 4px;
  }

  #error {
    color: red;
  }
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444a51;
  margin-bottom: 4px;
`;
