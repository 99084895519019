export enum MessageTypes {
  SET_DATA = '@message/SET_DATA',
  MESSAGE_ACTIVE = '@message/MESSAGE_ACTIVE',
}

export enum MessageOptions {
  none = 'none',
  selectBondAdmin = 'selectBondAdmin',
  success = 'success',
  confirm = 'confirm',
  error = 'error',
  infoMarkdown = 'infoMarkdown',
  firstAccess = 'firstAccess',
  advConfig = 'advConfig',
}

export type iMessageOptions = keyof typeof MessageOptions;
