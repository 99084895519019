import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import WiseAPI from 'wise-api';
import IStartConferenceDTO from 'wise-api/lib/resources/session/dtos/IStartConferenceDTO';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { iStore } from 'domain/interfaces/models';
import { makeRemoteJoinConference } from 'main/factories/usecases/conference/JoinConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { History } from 'main/routes';
import { closeModal } from 'utils/closeModal';
import { getRole } from 'utils/getRole';
import { toast } from 'react-toastify';
import { Container } from './styles/styledConf';

/* import { AppointmentTypes } from 'domain/interfaces/redux/appointment/types'; */

export interface ParamsState {
  appointmentId: string;
  m?: boolean;
  c?: boolean;
  u?: string;
  t?: string;
}

export interface QueryState {
  appointmentId: string;
}

export const Conf: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const { push } = useHistory();

  const params = useMemo(
    () => ({
      m: new URLSearchParams(search).get('m') ?? false,
      c: new URLSearchParams(search).get('c') ?? false,
      appointmentId: new URLSearchParams(search).get('appointmentId'),
      t: new URLSearchParams(search).get('t') ?? null,
      u: new URLSearchParams(search).get('u') ?? null,
    }),
    [search],
  );

  const { m, c, appointmentId, t, u } = params;

  const { auth, conference, org } = useSelector((store: iStore) => store);
  const timeoutMeet: { current: NodeJS.Timeout | null } = useRef(null);

  const isModerator = useCallback((): boolean => {
    if (!auth.user?.administrator) return false;

    const role = getRole(
      auth.user.administrator,
      auth.user.org?.id,
      auth.user.orgUnit?.id,
    );

    // Se usuário for SYSADMIN, é moderador
    if (role === 'SYSADMIN') return true;

    // Se usuário não estiver autenticado, não é moderador
    if (!auth.access.token || auth.access.token === '') return false;

    const userOrg = org?.records?.find(
      item => item?.id === auth?.user?.org?.id,
    );

    // Se não encontrar organização, não é moderador
    if (!userOrg) return false;

    const foundConference = conference?.records?.find(
      item => item.short === params.t,
    );

    // Se não encontrar conferência, não é moderador
    if (!foundConference) return false;

    const isParticipantModeratorOnConference =
      foundConference.participants.find(
        item => item?.user?.id === auth.user?.id && item.role === 'MODERATOR',
      );

    // Se não encontrar moderador na conferência, não é moderador
    if (!isParticipantModeratorOnConference) return false;

    // Se tiver gravações desabilitadas, não é moderador
    if (!userOrg?.settings?.conference?.recording.enabled) {
      makeReduxActiveMessage().active({
        active: 'error',
        title: 'Permissão de gravação',
        content: 'Organização não permite gravações de conferência.',
        actionOk: () => closeModal(),
        actionCancel: () => closeModal(),
      });

      return false;
    }

    // Usuário é moderador na conferência, e gravações estão habilitadas
    return true;
  }, [auth, conference?.records, org?.records, params.t]);

  useEffect(() => {
    const moderator = isModerator();

    const buttons = moderator
      ? [
          'microphone',
          'camera',
          'closedcaptions',
          'desktop',
          'fullscreen',
          'fodeviceselection',
          'hangup',
          'profile',
          'info',
          'chat',
          'recording',
          'livestreaming',
          'sharedvideo',
          'settings',
          'raisehand',
          'videoquality',
          'filmstrip',
          'invite',
          'feedback',
          'stats',
          'shortcuts',
          'tileview',
          'videobackgroundblur',
          'download',
          'help',
          'mute-everyone',
          'avatar',
          'libras',
          'transcription',
        ]
      : [
          'microphone',
          'camera',
          'closedcaptions',
          'desktop',
          'fullscreen',
          'fodeviceselection',
          'hangup',
          'profile',
          'info',
          'chat',
          'sharedvideo',
          'settings',
          'raisehand',
          'videoquality',
          'filmstrip',
          'invite',
          'feedback',
          'stats',
          'shortcuts',
          'tileview',
          'videobackgroundblur',
          'download',
          'help',
          'avatar',
          'libras',
          'transcription',
        ];

    const start = async (): Promise<void> => {
      try {
        const { sessionName } = await makeRemoteJoinConference().join({
          conferenceShort: String(t),
        });

        const v4h = await WiseAPI({
          baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
          domain: `${window.config.connection.confUrl}`,
        });

        await v4h.session.startConference(sessionName, {
          parentNode: document.getElementById('meet'),
          userInfo: {
            displayName: u ?? 'Convidado',
          },
          shareLink: `${window.location.origin}/preconf?t=${String(t)}`,
          startWithAudioMuted: !(m === 'true'),
          startWithVideoMuted: !(c === 'true'),
          buttons: buttons as unknown as IStartConferenceDTO['buttons'],
          onClose: () => {
            push(`/leave?t=${t}&u=${u}&c=${c}&m=${m}`);
          },
          moderator,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // toast.error(
        //   'Não localizamos o agendamento desta reunião. Verifique com o organizador da reunião.',
        // );

        History.getHistory().push('/home');
      }
    };

    start();

    // timeoutMeet.current = setTimeout(() => {
    //   const meetChildNodesCount =
    //     document.getElementById('meet')?.childNodes.length;

    //   if (!meetChildNodesCount) start();
    // }, 2000);

    return () => {
      if (timeoutMeet?.current) clearTimeout(timeoutMeet.current);
      if (appointmentId) console.log('### left');
    };
  }, [isModerator, appointmentId, timeoutMeet, auth.user, c, m, t, u]);

  return <Container id="meet">{loading && <div>loading...</div>}</Container>;
};

export default Conf;
