/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import * as dateFns from 'date-fns';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { makeRemoteCreateConference } from 'main/factories/usecases/conference/CreateConferenceFactory';
import { makeRemoteUpdateConference } from 'main/factories/usecases/conference/UpdateConferenceFactory';
import { makeRemoteCreateConferenceUser } from 'main/factories/usecases/conferenceUser/CreateConferenceUserFactory';
import { History } from 'main/routes';
import {
  IconCalendar,
  IconClock,
  IconDeleteFromList,
  IconPeople,
} from 'presentation/base/icons';
import { AvatarImage } from 'presentation/components/avatar';
import {
  Button,
  Input,
  KeyboardDate,
  Select,
} from 'presentation/components/UI';
import { mapperConferenceRoles } from 'utils/mapperRoles';
import { makeRemoteListConferenceUser } from 'main/factories/usecases/conferenceUser/ListConferenceUserFactory';
import { makeRemoteDeleteConferenceUser } from 'main/factories/usecases/conferenceUser/DeleteConferenceUserFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { appointmentSchema } from 'validation/appointment/CreateAppointmentValidation';
import { iStore } from 'domain/interfaces/models';
import {
  CreateConference,
  UpdateConference,
} from 'domain/usecases/conference/remote';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeContext } from 'App';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ConnectComponent } from './mapper/MapperappointmentForm';
import {
  Avatar,
  CheckBox,
  CheckBoxGroup,
  Container,
  Content,
  Info,
  Inputs,
  Left,
  ListActions,
  ListContainer,
  ListItem,
  Navigator,
  Right,
  User,
} from './styles/StyledAppointmentForm';
import { times } from './externValues';
import Textarea from '../UI/textarea';
import Translator from '../i18n/Translator';

interface iUsers {
  id: number;
  name: string;
  role: string;
  Image: string;
  orgName?: string;
  unitName?: string;
}

interface iInitialUsers {
  id: number;
  confUser: number;
  email?: string;
  find?: boolean;
}

export interface ownProps {
  data: {
    selectDate: Date;
    title: string;
    timeStart: number;
    timeEnd: number;
    description: string;
    conferenceId?: number;
    notifyOptions: { sms: boolean; email: boolean; whatsapp: boolean };
    participants?: {
      id: number;
      role: 'MODERATOR' | 'ATTENDEE';
      scheduled: string;
      fullName: string;
      email: string;
      phone: string;
      passwd: string;
      confirmation: string;
      user: {
        id: number;
        login: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        email: string;
        avatar: string;
        lastAccess: string;
        fullName: string;
      };
      conference: any;
    }[];
  };
}

interface Invites {
  whatsapp: boolean;
  email: boolean;
  sms: boolean;
}
export interface externalProps {
  users?: iUsers[];
  edit?: boolean;
}

export interface initialValuesAppointment {
  title: string;
}

export interface iSelectUser {
  id?: number;
  user?: number;
  name?: string;
  role: string;
  image?: string;
  email?: string;
  orgName?: string;
  unitName?: string;
  confirmation?: string;
  phone?: string;
}

const AppointmentForm: React.FC<ownProps & externalProps> = ({
  users,
  edit,
  data,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [selectDate, setSelectDate] = useState<Date>(data.selectDate);
  const [title, setTitle] = useState<string>(data.title);
  const [timeStart, setTimeStart] = useState<number>(data.timeStart);
  const [timeEnd, setTimeEnd] = useState<number>(data.timeEnd);
  const [descr, setDescr] = useState<string>(data.description);
  const [initialUsers, setInitialUsers] = useState<iInitialUsers[]>([]);
  const [selectUsers, setSelectUsers] = useState<iSelectUser[]>([]);
  const [owner, setOwner] = useState<iSelectUser>();
  const [newUsers, setNewUsers] = useState<iSelectUser[]>([]);
  const [valueUser, setValueUser] = useState<number>(-1);
  const [switchInvites, setSwitchInvites] = useState<Invites>({
    whatsapp: data.notifyOptions.whatsapp,
    email: data.notifyOptions.email,
    sms: data.notifyOptions.sms,
  });

  const { theme } = useContext(ThemeContext);

  const initialValuesConf = {
    title: '',
    descr: '',
    timeStart: 0,
    timeEnd: 0,
  };

  const initialValuesConfEdit = {
    title,
    descr,
    timeStart,
    timeEnd,
  };

  const { user: userRedux } = useSelector((store: iStore) => store.auth);
  const { records } = useSelector((store: iStore) => store.orgUnits);

  const selectStyles = {
    control: (styles: any) => ({
      display: 'flex',
      alignItems: 'center',
      height: '48px',
      border: '1px solid #dfdfdf',
      boxShadow: 'none',
      borderRadius: '8px',
      background: '#fff',
      padding: '6px',
      marginBottom: '26px',

      '&:hover': {
        borderColor: `${theme.colors.primary.main}`,
      },

      '&:focus': {
        borderColor: `${theme.colors.primary.main}`,
      },

      '&:disabled': {
        backgroundColor: '#fafafb',

        '&:hover': {
          borderColor: '#bfc4ca',
        },
      },
    }),
  };

  const SwitchStyles = withStyles({
    root: {
      '& .MuiSwitch-colorPrimary': {
        backgroundColor: `${theme.colors.primary.main}`,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: `${theme.colors.primary.main}`,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: `${theme.colors.primary.main}`,
      },
    },
  })(Switch);

  useEffect(() => {
    if (!edit && userRedux) {
      setOwner({
        id: userRedux.id,
        name: userRedux.fullName,
        image: userRedux.avatar,
        role: 'MODERATOR',
        email: userRedux.email,
        orgName: userRedux.org.shortname,
        unitName: userRedux.orgUnit.shortname,
      });
    }
  }, [edit, userRedux]);

  useEffect(() => {
    const getInitialUsers = async () => {
      const aux: iInitialUsers[] = [];
      const auxSel: iSelectUser[] = [];
      const auxNew: iSelectUser[] = [];

      if (edit && users?.length) {
        data?.participants?.forEach(el => {
          if (el.user?.id) {
            auxSel.push({
              id: el?.id || undefined,
              user: el.user?.id || undefined,
              name: el.user?.fullName || el.fullName || 'Convidado',
              image: el.user?.avatar || undefined,
              role: el.role || 'ATTENDEE',
              email: el.user?.email || el.email || undefined,
              confirmation: el.confirmation || undefined,
              phone: el.phone || undefined,
            });

            aux.push({ id: el.user.id, confUser: el.id });
          } else {
            auxNew.push({
              id: el?.id || undefined,
              name: el.fullName,
              email: el.email,
              confirmation: el.confirmation || undefined,
              role: el?.role || 'ATTENDEE',
            });

            aux.push({
              id: -1,
              confUser: el.id,
              email: el.email,
            });
          }
        });

        if (auxSel.length > 0) setSelectUsers(auxSel);
        if (auxNew.length > 0) setNewUsers(auxNew);

        setInitialUsers(aux);
      }
    };

    getInitialUsers();
  }, [data, edit, users]);

  const cancel = () => {
    History.getHistory().push(`/home`);
  };

  const changeInviteValues = (type: keyof Invites) => {
    const newSwitchInvites = { ...switchInvites };

    newSwitchInvites[type] = !switchInvites[type];
    setSwitchInvites(newSwitchInvites);
  };

  const getDuration = () => {
    const auxTime = times[timeEnd].split(':');
    const endSchedule = new Date(selectDate);
    endSchedule.setHours(Number(auxTime[0]));
    endSchedule.setMinutes(Number(auxTime[1]));

    return dateFns.differenceInMinutes(endSchedule, selectDate);
  };

  const schedule = async () => {
    const participants: CreateConference.Params['body']['participants'] = [];

    selectUsers.forEach(el => {
      if (users) {
        participants.push({
          id: el.id,
          user: el.user,
          role: el.role || 'ATTENDEE',
          confirmation: el.confirmation || 'NEEDS-ACTION',
        });
      }
    });

    newUsers.forEach(el => {
      participants.push({
        id: el.id,
        email: el.email,
        fullName: el.name,
        phone: el.phone || '+5583988888888',
        role: el.role || 'ATTENDEE',
        confirmation: el.confirmation || 'NEEDS-ACTION',
      });
    });

    setLoading(true);

    await makeRemoteCreateConference()
      .create({
        body: {
          // TODO: Enviar owner e org apenas quando criar conferencia para outro user
          // owner: Number(userRedux?.id),
          // org: Number(userRedux?.org?.id),
          descr,
          title,
          scheduled: selectDate?.toISOString(),
          expectedDuration: getDuration(),
          allowInvite: true,
          notifyOptions: switchInvites,
          participants,
        },
      })
      .then(res => {
        toast.success(Translator('Reunião Agendada com sucesso!'));
        History.getHistory().push(`/home`);
      })
      .catch(() => {
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Cadastro de conferência'),
          content: Translator('Não foi possível criar a reunião'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const change = async () => {
    setLoading(true);

    const participants: UpdateConference.Params['body']['participants'] = [];

    if (users) {
      selectUsers.forEach(el => {
        participants.push({
          id: el.id,
          user: el.user,
          role: el.role || 'ATTENDEE',
          confirmation: el.confirmation || 'NEEDS-ACTION',
        });
      });

      newUsers.forEach(el => {
        participants.push({
          id: el.id,
          email: el.email,
          fullName: el.name,
          phone: el.phone || '+5583988888888',
          role: el.role || 'ATTENDEE',
          confirmation: el.confirmation || 'NEEDS-ACTION',
        });
      });
    }

    await makeRemoteUpdateConference()
      .update({
        conferenceId: Number(data.conferenceId),
        body: {
          descr,
          title,
          scheduled: selectDate?.toISOString(),
          expectedDuration: getDuration(),
          allowInvite: true,
          notifyOptions: switchInvites,
          participants,
        },
      })
      .then(() => {
        toast.success(Translator('Conferência atualizada com sucesso'));
        History.getHistory().push(`/home`);
      })
      .catch(() => {
        makeReduxActiveMessage().active({
          active: 'error',
          title: Translator('Edição de conferência'),
          content: Translator('Não foi possível atualizar a reunião'),
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      })
      .finally(() => {
        setLoading(false);
      });

    History.getHistory().push(`/home`);
  };

  const getOrgUnitLabelName = useCallback(
    (name: string, orgName?: string, unitName?: string) => {
      if ((!orgName && !unitName) || (orgName === '' && unitName === ''))
        return name;

      if (orgName && !unitName && orgName !== '')
        return `${name} (${orgName?.toLocaleUpperCase()})`;

      return `${name} (${orgName?.toLocaleUpperCase()}/${unitName?.toLocaleUpperCase()})`;
    },
    [],
  );

  const generateSelectOptions = useCallback(() => {
    return _.sortBy(
      users
        ?.map(value => {
          if (
            value.id !== userRedux?.id &&
            !selectUsers.find(el => el.user === value.id)
          )
            return {
              value: value.id,
              label: getOrgUnitLabelName(
                value.name,
                value.orgName,
                value.unitName,
              ),
            };

          return null;
        })
        .filter(Boolean),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [getOrgUnitLabelName, selectUsers, users, userRedux]);

  const isTimeOptionGonnaBeRendered = useCallback(
    (hour: string): boolean => {
      const now = new Date();

      if (selectDate?.getFullYear() - now?.getFullYear() > 0) return false;
      if (selectDate?.getMonth() - now?.getMonth() > 0) return false;
      if (selectDate?.getDate() - now?.getDate() > 0) return false;

      const auxTime = hour.split(':');
      const hourNow = now.getHours();
      const minuteNow = now.getMinutes();

      if (Number(auxTime[0]) < hourNow) return true;
      if (Number(auxTime[0]) === hourNow && Number(auxTime[1]) < minuteNow)
        return true;

      return false;
    },
    [selectDate],
  );

  const Option = ({ children, ...props }: any) => (
    <components.Option {...props} data-testid="option-participant">
      {children}
    </components.Option>
  );

  const Control = ({ children, ...props }: any) => (
    <components.Control {...props} data-testid="input-participants">
      {children}
    </components.Control>
  );

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconPeople data-testid="icon-participants" />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <Formik
        initialValues={edit ? initialValuesConfEdit : initialValuesConf}
        validationSchema={appointmentSchema}
        onSubmit={value => console.log(value)}
        validateOnBlur
      >
        {({ errors, touched, setFieldValue, handleBlur }) => (
          <>
            <Container>
              <Content>
                <Left>
                  <div className="title">Informações da reunião</div>
                  <>
                    <Input
                      data-testid="input-title"
                      className="input"
                      label={Translator('Título')}
                      name="title"
                      required
                      placeholder={Translator('Adicione um título')}
                      autoFocus
                      onBlur={handleBlur}
                      message={touched.title ? errors.title : ''}
                      error={Boolean(touched.title && errors?.title)}
                      value={title}
                      onChange={e => {
                        setTitle(e.target.value);
                        setFieldValue('title', e.target.value);
                      }}
                    />
                    <Inputs>
                      <KeyboardDate
                        data-testid="input-date"
                        label={Translator('Data')}
                        name="scheduled"
                        required
                        state={selectDate}
                        setState={(date: Date) => {
                          setSelectDate(date);
                        }}
                        Icon={IconCalendar}
                        minDate={new Date()}
                      />
                      <Select
                        data-testid="input-hour-start"
                        icon={IconClock}
                        className="time"
                        name="hourStart"
                        required
                        value={timeStart}
                        label={Translator('Início')}
                        width="25%"
                        border={false}
                        onChange={e => {
                          const auxTime =
                            times[Number(e.target.value)].split(':');

                          const date = selectDate;
                          date.setHours(Number(auxTime[0]));
                          date.setMinutes(Number(auxTime[1]));

                          setSelectDate(date);
                          setTimeStart(Number(e.target.value));

                          if (Number(e.target.value) > Number(timeEnd))
                            setTimeEnd(Number(e.target.value) + 1);
                        }}
                      >
                        {times.map((item, index) => {
                          if (isTimeOptionGonnaBeRendered(item)) return null;

                          return (
                            <option id={`option_${index}`} value={index}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                      <Select
                        data-testid="input-hour-end"
                        icon={IconClock}
                        className="time"
                        name="hourFinish"
                        required
                        onBlur={handleBlur}
                        value={timeEnd}
                        label={Translator('Fim')}
                        width="25%"
                        border={false}
                        onChange={e => {
                          setFieldValue('hourFinish', e.target.value);
                          setTimeEnd(Number(e.target.value));
                        }}
                      >
                        {times.map((item, index) => {
                          if (
                            isTimeOptionGonnaBeRendered(item) ||
                            index <= timeStart
                          )
                            return null;

                          if (
                            index !== 0 &&
                            isTimeOptionGonnaBeRendered(times[index - 1])
                          ) {
                            return null;
                          }

                          return (
                            <option id={`option_${index}`} value={index}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                    </Inputs>
                    <Textarea
                      data-testid="input-description"
                      className="textarea"
                      label={Translator('Descrição da reunião')}
                      name="descr"
                      height="174px"
                      placeholder={Translator('Adicione uma descrição')}
                      onBlur={handleBlur}
                      message={touched.descr ? errors.descr : ''}
                      error={Boolean(touched.descr && errors?.descr)}
                      value={descr}
                      onChange={e => {
                        setDescr(e.target.value);
                        setFieldValue('descr', e.target.value);
                      }}
                    />
                  </>
                </Left>
                <Right>
                  <div className="title">{Translator('Participantes')}</div>
                  <CreatableSelect
                    className="select"
                    components={{
                      Control,
                      DropdownIndicator,
                      IndicatorSeparator: () => null,
                      Option,
                    }}
                    placeholder={Translator(
                      'Adicione participantes ou digite um novo e-mail',
                    )}
                    formatCreateLabel={(label: string) => `Adicionar ${label}`}
                    value={valueUser}
                    options={generateSelectOptions()}
                    onChange={(e: any) => {
                      // eslint-disable-next-line no-underscore-dangle
                      if (e?.__isNew__ && e.value) {
                        setNewUsers([
                          ...newUsers,
                          {
                            email: e.value,
                            name: e.value?.split('@')[0],
                            role: 'ATTENDEE',
                          },
                        ]);
                      } else {
                        const auxLixt = selectUsers;
                        auxLixt.push({
                          user: Number(e.value),
                          role: 'ATTENDEE',
                        });

                        setSelectUsers(auxLixt);
                        setValueUser(Number(e.value));
                      }
                    }}
                    onInputChange={(e: any) => {
                      // console.log('Updated input: ', e);
                    }}
                    styles={selectStyles}
                  />
                  <ListContainer>
                    {owner && (
                      <ListItem>
                        <User>
                          <Avatar>
                            {owner.image ? (
                              <img
                                alt=""
                                src={owner.image}
                                style={{
                                  borderRadius: '50%',
                                  width: '44px',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            ) : (
                              <AvatarImage name={owner.name} size="mini" />
                            )}
                          </Avatar>
                          <Info>
                            <div>
                              {getOrgUnitLabelName(
                                String(owner.name),
                                owner.orgName,
                                owner.unitName,
                              )}
                            </div>
                            <div>
                              {mapperConferenceRoles(String(owner.role))}
                            </div>
                          </Info>
                        </User>
                      </ListItem>
                    )}
                    {users &&
                      selectUsers.map((value, index) => {
                        const foundUser = users?.find(
                          item => item.id === value.user,
                        );

                        return (
                          <ListItem>
                            <User>
                              <Avatar>
                                {value.image || foundUser?.Image ? (
                                  <img
                                    alt=""
                                    src={value.image || foundUser?.Image}
                                    style={{
                                      borderRadius: '50%',
                                      width: '44px',
                                    }}
                                  />
                                ) : (
                                  <AvatarImage
                                    name={value.name || foundUser?.name}
                                    size="mini"
                                  />
                                )}
                              </Avatar>
                              <Info>
                                <div>
                                  {getOrgUnitLabelName(
                                    String(value.name || foundUser?.name),
                                    foundUser?.orgName || undefined,
                                    foundUser?.unitName || undefined,
                                  )}
                                </div>
                                <div>
                                  <Select
                                    data-testid={`select-user-role-${value.id}`}
                                    width="150px"
                                    height="17px"
                                    value={value.role}
                                    onChange={e => {
                                      setSelectUsers(
                                        selectUsers.map(el => {
                                          if (el.user === value.user) {
                                            return {
                                              ...el,
                                              role: e.target.value,
                                            };
                                          }
                                          return el;
                                        }),
                                      );
                                    }}
                                    minimal
                                  >
                                    <option
                                      data-testid="attendee-option"
                                      value="ATTENDEE"
                                    >
                                      Convidado
                                    </option>
                                    <option
                                      data-testid="moderator-option"
                                      value="MODERATOR"
                                    >
                                      Moderador
                                    </option>
                                  </Select>
                                </div>
                              </Info>
                            </User>
                            <ListActions>
                              <IconDeleteFromList
                                data-testid={`icon-delete-user-${index}`}
                                onClick={() => {
                                  setSelectUsers(
                                    selectUsers.filter(el => el !== value),
                                  );
                                  setValueUser(0);
                                }}
                              />
                            </ListActions>
                          </ListItem>
                        );
                      })}
                    {newUsers.map(value => (
                      <ListItem>
                        <User>
                          <Avatar>
                            <AvatarImage name={value.name} size="mini" />
                          </Avatar>
                          <Info>
                            <div>{value.email}</div>
                            <Select
                              data-testid={`new-user-role-${value.id}`}
                              width="150px"
                              height="17px"
                              value={value.role}
                              onChange={e => {
                                setNewUsers(
                                  newUsers.map(el => {
                                    if (el.user === value.user) {
                                      return {
                                        ...el,
                                        role: e.target.value,
                                      };
                                    }
                                    return el;
                                  }),
                                );
                              }}
                              minimal
                            >
                              <option
                                data-testid="attendee-option"
                                value="ATTENDEE"
                              >
                                Convidado
                              </option>
                              <option
                                data-testid="moderator-option"
                                value="MODERATOR"
                              >
                                Moderador
                              </option>
                            </Select>
                          </Info>
                        </User>
                        <ListActions>
                          <IconDeleteFromList
                            onClick={() => {
                              setNewUsers(newUsers.filter(el => el !== value));
                              setValueUser(0);
                            }}
                          />
                        </ListActions>
                      </ListItem>
                    ))}
                  </ListContainer>
                  <div className="titleInvite">
                    {Translator('Formas de convite')}
                  </div>
                  <CheckBoxGroup>
                    <CheckBox>
                      <SwitchStyles
                        data-testid="switch-invite-email"
                        checked={switchInvites.whatsapp}
                        onClick={() => changeInviteValues('whatsapp')}
                      />
                      <div className="text">{Translator('Whatsapp')}</div>
                    </CheckBox>
                    <CheckBox>
                      <SwitchStyles
                        data-testid="switch-invite-email"
                        checked={switchInvites.email}
                        onClick={() => changeInviteValues('email')}
                      />
                      <div className="text">{Translator('Email')}</div>
                    </CheckBox>
                    <CheckBox>
                      <SwitchStyles
                        data-testid="switch-invite-email"
                        checked={switchInvites.sms}
                        onClick={() => changeInviteValues('sms')}
                      />
                      <div className="text">{Translator('SMS')}</div>
                    </CheckBox>
                  </CheckBoxGroup>
                </Right>
              </Content>
              <Navigator>
                <Button
                  data-testid="btn-cancel"
                  onClick={cancel}
                  variant="secundary"
                  color={theme.colors.primary.main}
                  id="cancel"
                  size="small"
                >
                  Cancelar
                </Button>
                <Button
                  data-testid="btn-save"
                  onClick={edit ? change : schedule}
                  disabled={
                    loading ||
                    Boolean(
                      errors?.title || errors?.descr || timeStart >= timeEnd,
                    )
                  }
                  background={theme.colors.primary.main}
                  id="schedule"
                  size="small"
                >
                  {loading ? (
                    <CircularProgress
                      style={{
                        width: '30px',
                        height: '30px',
                        color: `${theme.colors.primary.main}`,
                      }}
                    />
                  ) : edit ? (
                    `${Translator('Salvar')}`
                  ) : (
                    `${Translator('Agendar')}`
                  )}
                </Button>
              </Navigator>
            </Container>
          </>
        )}
      </Formik>
    </>
  );
};

export default ConnectComponent(AppointmentForm);
