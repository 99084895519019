import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetFilteredOrgs as UsecaseRemoteGetFilteredOrgs } from 'domain/usecases/org/remote';

import { RemoteGetFilteredOrgs } from 'data/repository/org';

/**
 * send request via API.
 */
export const makeRemoteGetFilteredOrgs = (): UsecaseRemoteGetFilteredOrgs =>
  new RemoteGetFilteredOrgs(makeApiUrl('/orgs/filtered/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetUser =
  (): UsecaseReduxGetUser =>
    new ReduxGetUser(); */
