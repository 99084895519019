import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.area.header};
  box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 0px 72px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1920px; */
  height: 56px;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-size: 18px;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.colors.area.header};
`;

export const Left = styled.div`
  border: none;
  background: transparent;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  max-width: fit-content;
  align-items: center;
  font-size: 14px;

  .t {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-weight: 800;
    font-size: 16px;
    color: #233242;
    margin-right: 24px;
  }

  .title {
    font-size: 16px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  max-width: 385px;
  min-width: 300px;
  height: 34px;
  border-radius: 4px;
  padding-left: 5px;
  border: 1px solid #efefef;
  margin-right: 10px;
  justify-content: space-between;
  padding: 0px 10px 0px 5px;
  input {
    max-width: 385px;
    min-width: 300px;
    padding: 3px;
    text-decoration: none;
    border: none;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  /* margin-left: 8px; */
  padding-right: 8px;
`;

interface iDropProps {
  loading?: boolean;
}

export const Drop = styled.div<iDropProps>`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: ${({ loading }) => (loading ? 'center' : 'flex-start')};
  padding: 13px 15px;
  color: black;
  background: white;
  cursor: pointer;
  border: none;

  span {
    margin-left: 15px;
  }
`;

export const Options = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 8px 8px;
  width: 270px;
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }
`;

export const FullScreen = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 400;
  font-size: 14px;
  color: #404040;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 0px 8px;

  cursor: pointer;
`;
