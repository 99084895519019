import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;
  height: 189px;
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 0px 30px 0px 45px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 493px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;

    textarea {
      display: none;
    }
  }
`;

export const BackButton = styled.div`
  height: 85px;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 85px);

  span {
    font-size: 45px;
    font-weight: bold;
    display: flex;
    align-items: center;

    svg {
      margin-left: 21px;
    }
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;

  span {
    font-size: 18px;
    margin-top: 5px;
  }
`;

export const Left = styled.div`
  width: 60%;
  padding-right: 34px;
  border-right: 1px solid #a6a6a6;

  textarea {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 8px;
    color: #444a51;
    width: 100%;
    resize: none;
    height: 174px;
    padding: 14px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;

    ::placeholder {
      color: #bfc4ca;
    }
  }

  .input {
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    border: none;
    width: 100%;
    padding: 0px;
  }
`;

export const Right = styled.div`
  width: 40%;
  padding-left: 34px;

  .select {
    width: 100%;
    // max-width: 389px;
    margin-bottom: 26px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0px;

    .select {
      max-width: 100%;
    }
  }
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 50px;

  .time {
    margin-left: 20px;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  // max-width: 389px;
  max-height: 281px;
  padding: 31px 17px;
  border: 1px solid rgba(166, 166, 166, 0.5);
  border-radius: 3px;
  margin-bottom: 48px;
  overflow-y: scroll;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  margin-bottom: 11px;
`;

export const User = styled.div`
  display: flex;
`;

export const Avatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin-right: 6px;

  .icon {
    border: 1px solid red;
    border-radius: 50%;
  }

  .img {
    height: 100%;
    width: 100%;
  }
`;

export const Info = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 0px;
`;

export const ListActions = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CheckBoxGroup = styled.div`
  display: flex;
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: 8px;
    border-radius: 8px;
    height: 22px;
    width: 22px;
  }

  .text {
    font-size: 14px;
    margin-right: 15px;
  }
`;

export const Footer = styled.div`
  max-width: 389px;
  padding: 63px 450px;
  display: flex;
  justify-content: end;
`;

export const Navigator = styled.div`
  display: flex;
  padding-top: 63px;
  justify-content: flex-end;

  button {
    margin-left: 20px;
  }

  @media (max-width: 1024px) {
    padding-top: 80px;
  }
`;
