import React from 'react';

import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';

import SelectBondAdmin from './selectBondAdmin';
import SuccessModal from './success';
import ConfirmModal from './confirm';
import ErrorModal from './error';
import InfoMarkdownModal from './infoMarkdown';
import FirstAccessModal from './firstAccess';
import AdvConfigModal from './advConfig';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      <SelectBondAdmin message={message} />
      <SuccessModal message={message} />
      <ErrorModal message={message} />
      <ConfirmModal message={message} />
      <InfoMarkdownModal message={message} />
      <FirstAccessModal message={message} />
      <AdvConfigModal message={message} />
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
