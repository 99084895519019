import { takeLatest } from 'redux-saga/effects';
import { UserTypes } from 'domain/interfaces/redux/user/types';
import { onList, onListSuccess, onListFailed } from './List';
import { onUpdate, onUpdateSuccess, onUpdateFailed } from './Update';

const tasks = [
  takeLatest(UserTypes.LIST, onList),
  takeLatest(UserTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(UserTypes.LIST_FAILED, onListFailed),
  takeLatest(UserTypes.UPDATE, onUpdate),
  takeLatest(UserTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(UserTypes.UPDATE_FAILED, onUpdateFailed),
];

export default tasks;
