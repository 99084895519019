/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Translator from 'presentation/components/i18n/Translator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TableSortLabel } from '@material-ui/core';
import { iStore, OrgUserInfo } from 'domain/interfaces/models';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteDeleteByOrgUnitOrgUser } from 'main/factories/usecases/orgUser/DeleteByOrgUnitOrgUserFactory';
import { ActionsList } from 'presentation/components/actionsList';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { closeModal } from 'utils/closeModal';
import { getOrgUserRole, getRole } from 'utils/getRole';
import { mapperRoles } from 'utils/mapperRoles';
import { makeRemoteGetFilteredUsers } from 'main/factories/usecases/user/GetFilteredUsers';
import { IconSort } from 'presentation/base/icons';
import { iColumnsConfig } from './interface';

interface ownProps {
  dataList: OrgUserInfo[];
  setAllOrderBy: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: 'box-shadow: 0px 0px 2.93605px rgba(0, 0, 0, 0.25)',
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '10px',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

type iColumns = 'user' | 'org' | 'sector' | 'e-mail' | 'role' | 'actions';

export interface IOrderBy {
  order: number;
  attribute: string;
  direction: string;
  active?: boolean;
}

const Users: React.FC<ownProps> = ({ dataList, setAllOrderBy }) => {
  const [tableListItems, setTableListItems] = useState<OrgUserInfo[]>(dataList);
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    order: 0,
    attribute: 'ID',
    direction: 'DESC',
  });
  const url = window.location.pathname;
  const { user: userRedux } = useSelector((store: iStore) => store.auth);
  const classes = useStyles();
  const columns: iColumnsConfig[] = [
    {
      id: 'user',
      label: Translator('Usuários'),
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'org',
      label: Translator('Organização'),
      minWidth: 50,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'sector',
      label: Translator('Setor'),
      minWidth: 200,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'e-mail',
      label: Translator('E-mail'),
      minWidth: 100,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'role',
      label: Translator('Papel'),
      minWidth: 100,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 20,
      align: 'right',
      alignTitle: 'right',
      hiddenForRoles: ['STANDARD'],
    },
  ];

  const role = useMemo(
    () =>
      userRedux?.administrator
        ? getRole(
            userRedux.administrator,
            userRedux.org.id,
            userRedux.orgUnit.id,
          )
        : 'STANDARD',
    [userRedux?.administrator, userRedux?.org.id, userRedux?.orgUnit.id],
  );

  const { push } = useHistory();

  const orderByFunction = async (type: string) => {
    setAllOrderBy(type);
  };

  const handleDelete = useCallback(
    (user: OrgUserInfo) => {
      if (user?.orgUnit?.id && user?.orgUnit?.org?.id) {
        makeRemoteDeleteByOrgUnitOrgUser()
          .deleteByOrgUnit({
            orgId: user.orgUnit.org.id,
            unitId: user.orgUnit.id,
            userId: user.user.id,
          })
          .then(res => {
            closeModal();

            push('/admin/users');
            toast.success(`${Translator('Usuário removido com sucesso')}`);
          })
          .catch(err =>
            makeReduxActiveMessage().active({
              active: 'error',
              title: `${Translator('Remoção de Usuário')}`,
              content: `${Translator('Não foi possível remover usuário')}`,
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            }),
          );
      } else {
        makeReduxActiveMessage().active({
          active: 'error',
          title: `${Translator('Remoção de Usuário')}`,
          content: `${Translator('Não foi possível remover usuário')}`,
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
        });
      }
    },
    [push],
  );

  const RenderComponents: React.FC<{
    id: iColumns;
    user: OrgUserInfo;
  }> = ({ id, user }) => {
    const orgName =
      role === 'ORG_ADMIN' ? userRedux?.org?.name : user?.orgUnit?.org?.name;

    const orgId =
      role === 'ORG_ADMIN' ? userRedux?.org?.id : user?.orgUnit?.org?.id;

    const orgUserRole = getOrgUserRole(
      user.sysAdmin,
      user.orgAdmin,
      user.unitAdmin,
    );

    switch (id) {
      case 'user':
        return <div>{user?.user?.fullName}</div>;
      case 'org':
        return <b>{orgName}</b>;
      case 'sector':
        return <div>{user?.orgUnit?.name}</div>;
      case 'e-mail':
        return <div>{user?.user?.email}</div>;
      case 'role':
        return <div>{mapperRoles(orgUserRole)}</div>;
      case 'actions':
        return (
          <ActionsList
            actions={{
              details: () => {
                push(
                  `/admin/users/${user.user.id}?org=${orgId}${
                    user?.orgUnit?.id ? `&unit=${user?.orgUnit?.id}` : ''
                  }`,
                  { from: url.replaceAll('/', '') },
                );
              },
              delete:
                role !== 'STANDARD' && !user.sysAdmin
                  ? () => {
                      makeReduxActiveMessage().active({
                        active: 'confirm',
                        content: `${Translator(
                          'Deseja realmente excluir o usuário',
                        )}`,
                        title: `${Translator('Remoção de Usuário')}`,
                        actionOk: () => {
                          handleDelete(user);
                        },
                        actionCancel: () => closeModal(),
                      });
                    }
                  : undefined,
            }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow data-testid="list-users-head">
            {columns.map(item => {
              if (item.hiddenForRoles?.includes(role)) {
                return <></>;
              }
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  {item.id === 'user' || item.id === 'e-mail' ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div
                          style={{ paddingLeft: 3 }}
                          onClick={() => orderByFunction(item.id)}
                        >
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      // onClick={() => {
                      //   setOrder(prevState => !prevState);
                      //   setData([
                      //     ...orderData(props.data, order, item.id as iOrder),
                      //   ]);
                      // }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div>{item.label}</div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody data-testid="list-users-body">
          {dataList?.map((item: OrgUserInfo) => (
            <TableRow>
              {columns.map((columnProps: any) => {
                if (columnProps.hiddenForRoles?.includes(role)) {
                  return <></>;
                }
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} user={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Users;
