import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import Translator from 'presentation/components/i18n/Translator';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from 'domain/interfaces/models';
import { ListOrgUnit } from 'domain/usecases/orgUnit/remote';
import { makeReduxListOrgAclFilteredV2 } from 'main/factories/usecases/org/ListOrgAclFilteredV2Factory';
import { makeReduxListOrgUnitAclFilteredV2 } from 'main/factories/usecases/orgUnit/ListOrgUnitAclFilteredFactoryV2';
import { Select } from 'presentation/components/UI';
import { getRole } from 'utils/getRole';
import { UserByOrg, UserFieldsValidation } from '..';
import { Container, Fields } from './styles/StyledBond';

interface ownProps {
  user: UserByOrg;
  onChangeUserInfo: Function;
  editing?: boolean;
}

const Bond: React.FC<ownProps> = ({
  user,
  onChangeUserInfo,
  editing = false,
}) => {
  const [responseOrgUnit, setResponseOrgUnit] = useState<ListOrgUnit.Model>(
    {} as ListOrgUnit.Model,
  );

  const { org: responseOrg } = useSelector((store: iStore) => store);

  const { search } = useLocation();
  const { errors, touched, setFieldValue, handleBlur, values } =
    useFormikContext<UserFieldsValidation>();

  const userRedux = useSelector((store: iStore) => store.auth.user);
  const { records } = useSelector((store: iStore) => store.orgUnits);

  const params = useMemo(
    () => ({
      org: new URLSearchParams(search).get('org'),
      unit: new URLSearchParams(search).get('unit'),
    }),
    [search],
  );

  const role = useMemo(
    () =>
      userRedux?.administrator
        ? getRole(
            userRedux.administrator,
            userRedux.org.id,
            userRedux.orgUnit.id,
          )
        : 'STANDARD',
    [userRedux?.administrator, userRedux?.org.id, userRedux?.orgUnit.id],
  );

  const { pathname } = useLocation();

  // const handleChangeOrgUser = useCallback(() => {
  //   onChangeUserInfo({
  //     ...user,
  //     role: user?.role === 'ORG_ADMIN' ? 'STANDARD' : 'ORG_ADMIN',
  //   });
  // }, [user, onChangeUserInfo]);

  useEffect(() => {
    makeReduxListOrgAclFilteredV2().list({
      limit: 9999,
    });

    makeReduxListOrgUnitAclFilteredV2().list({
      org: user?.orgId ?? undefined,
      limit: 9999,
    });
  }, [user?.orgId, userRedux, role]);

  useEffect(() => {
    if (user?.orgId && records) {
      setResponseOrgUnit(prevState => ({
        ...prevState,
        records,
      }));

      if (
        pathname.includes('/create') &&
        params.org &&
        !params.unit &&
        !user.orgUnitId
      ) {
        const orgRecords = records.filter(
          item => item.org?.id === Number(user?.orgId),
        );

        setFieldValue('sector', Number(orgRecords?.[0]?.id ?? -1));

        onChangeUserInfo({
          ...user,
          orgUnitId: Number(orgRecords?.[0]?.id ?? -1),
        });
      }
    }
  }, [
    onChangeUserInfo,
    params,
    records,
    search,
    setFieldValue,
    user,
    pathname,
  ]);

  useEffect(() => {
    if (!values.organization && user?.orgId) {
      setFieldValue('organization', Number(user?.orgId));
    }

    if (!values.sector && user?.orgUnitId) {
      setFieldValue('sector', Number(user?.orgUnitId));
    }
  }, [setFieldValue, user, values]);

  return (
    <Container>
      <Fields>
        <Select
          label={`${Translator('Organização')}`}
          name="organization"
          required={!editing}
          onBlur={handleBlur}
          message={touched.organization ? errors.organization : ''}
          error={Boolean(touched.organization && errors.organization)}
          defaultValue={editing ? user?.orgId : ''}
          value={user?.orgId}
          disabled={Boolean(params.org) && !editing}
          autoFocus
          onChange={e => {
            setFieldValue('organization', Number(e.target.value));
            setFieldValue('sector', -1);

            onChangeUserInfo({
              ...user,
              orgId: Number(e.target.value),
              orgUnitId: undefined,
            });
          }}
        >
          <option key={-1}>{Translator('Selecione uma Organização')}</option>
          {responseOrg?.records?.map(item => (
            <option key={item.id} value={item?.id}>
              {item?.name}
            </option>
          ))}
        </Select>
        <Select
          label={`${Translator('Setor')}`}
          name="sector"
          required={!editing}
          defaultValue={editing ? user?.orgUnitId : ''}
          onBlur={handleBlur}
          message={touched.sector ? errors.sector : ''}
          error={Boolean(touched.sector && errors.sector)}
          value={user?.orgUnitId}
          disabled={Boolean(params.org || params.unit) && !editing}
          onChange={e => {
            setFieldValue('sector', Number(e.target.value));

            onChangeUserInfo({
              ...user,
              orgUnitId: Number(e.target.value),
            });
          }}
        >
          <option key={-1}>{Translator('Selecione um Setor')}</option>
          {responseOrgUnit?.records?.map(item => (
            <option key={item.id} value={item?.id}>
              {item?.name}
            </option>
          ))}
        </Select>
      </Fields>
      {/* <ContainerCheckbox
        resourcers={['USER']}
        actions={['PROMOTE_ANY', 'PROMOTE_ORG', 'PROMOTE_UNIT']}
      >
        <input
          type="checkbox"
          defaultChecked={user?.role === 'ORG_ADMIN'}
          onChange={handleChangeOrgUser}
          disabled={Boolean(params.org || params.unit) && !editing}
        />
        Administrador da organização?
      </ContainerCheckbox> */}
    </Container>
  );
};

export default Bond;
