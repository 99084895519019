import { Dispatch } from 'redux';
import store from 'data/store';
import { listFilteredByACLV2Request } from 'data/store/reducer/conference/actions/listFilteredByACLV2';

import { iActionListFilteredByACLV2 } from 'domain/interfaces/redux/conference/listFilteredByACLV2';

import { ListConferenceFilteredByACLV2 } from 'domain/usecases/conference/redux';

export class ReduxListConferenceFilteredByACLV2
  implements ListConferenceFilteredByACLV2
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (
    params: ListConferenceFilteredByACLV2.Params,
  ): iActionListFilteredByACLV2 =>
    this.send(listFilteredByACLV2Request(params));
}
