import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 15%;
  gap: 30px;
  justify-content: space-between;
`;

export const HeaderFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  gap: 8px;
`;

export const HeaderFilterItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderData = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;

export const HeaderDataItem = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const HeaderButtons = styled.div`
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const HeaderButtonItem = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  cursor: not-allowed;
  opacity: 0.5;
`;

interface iHeaderText {
  color?: string;
  fontWeight?: 'bold' | 'normal';
}

export const HeaderText = styled.div<iHeaderText>`
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  font-size: 14px;
  color: ${({ color }) => color ?? 'black'};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
`;

export const ContentBilling = styled.div`
  display: flex;
  width: 100%;
  height: 7%;
  align-items: center;
  justify-content: space-between;
  color: #656a6e;
`;

export const ContentList = styled.div`
  display: flex;
  width: 100%;
  height: 93%;
  margin-top: 14px;
`;
