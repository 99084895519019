import { takeLatest } from 'redux-saga/effects';
import { ConferenceTypes } from 'domain/interfaces/redux/conference/types';
import { onList, onListSuccess, onListFailed } from './List';
import {
  onListFilteredByACL,
  onListFilteredByACLSuccess,
  onListFilteredByACLFailed,
} from './ListFilteredByACL';
import {
  onListFilteredByACLV2,
  onListFilteredByACLV2Success,
  onListFilteredByACLV2Failed,
} from './ListFilteredByACLV2';

const tasks = [
  takeLatest(ConferenceTypes.LIST, onList),
  takeLatest(ConferenceTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(ConferenceTypes.LIST_FAILED, onListFailed),
  takeLatest(ConferenceTypes.LIST_FILTERED_BY_ACL, onListFilteredByACL),
  takeLatest(
    ConferenceTypes.LIST_FILTERED_BY_ACL_SUCCESS,
    onListFilteredByACLSuccess,
  ),
  takeLatest(
    ConferenceTypes.LIST_FILTERED_BY_ACL_FAILED,
    onListFilteredByACLFailed,
  ),
  takeLatest(ConferenceTypes.LIST_FILTERED_BY_ACL_V2, onListFilteredByACLV2),
  takeLatest(
    ConferenceTypes.LIST_FILTERED_BY_ACL_V2_SUCCESS,
    onListFilteredByACLV2Success,
  ),
  takeLatest(
    ConferenceTypes.LIST_FILTERED_BY_ACL_V2_FAILED,
    onListFilteredByACLV2Failed,
  ),
];

export default tasks;
