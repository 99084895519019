import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import storeDev from 'data/store';
import { iStore } from 'domain/interfaces/models';
import { makeReduxRefreshAuth } from 'main/factories/usecases/auth/RefreshAuthFactory';
import Translator from 'presentation/components/i18n/Translator';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';

class AxiosHttpClient implements HttpClient {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;

    const store: iStore = storeDev.getState();

    const { token } = store.auth.access;

    let authorizationHeader = data.headers;

    if (
      token &&
      (!data.headers ||
        (typeof data.headers === 'object' && Object.keys(data.headers).length))
    ) {
      authorizationHeader = {
        Authorization: `Bearer ${token}`,
        ...data.headers,
      };
    }

    const request = {
      url: data.url,
      method: data.method,
      data: data.body,
      headers: authorizationHeader,
      responseType: data.responseType,
      params: data.params,
    };

    try {
      axiosResponse = await axios.request(request);
    } catch (error: any) {
      if (error.response?.status === 403) {
        console.log(
          'Usuário não possui permissão suficiente para desempenhar ação.',
        );
      }

      if (error.response?.status === 502 || error.response?.status === 503) {
        let found = false;

        document.querySelectorAll('.Toastify__toast--error').forEach(node => {
          if (node.innerHTML.includes('Falha interna no servidor')) {
            found = true;
          }
        });

        if (!found) {
          toast.error('Falha interna no servidor, tente novamente em minutos.');
        }
      }

      if (
        error.response?.status === 401 &&
        error.response?.data?.errors?.[0]?.code?.includes('AUTH') &&
        store.auth.user?.id &&
        store.auth.user?.org?.id &&
        store.auth.refresh?.token
      ) {
        makeReduxRefreshAuth().refresh({
          requestConfig: request,
          body: {
            user: store.auth.user?.id,
            org: store.auth.user?.org?.id,
            refreshToken: store.auth.refresh?.token,
          },
        });
      }

      if (error.response?.data?.errors?.[0]?.code) {
        toast.error(Translator(error.response?.data?.errors?.[0]?.code));
      }

      axiosResponse = error.response;
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
      internalCode: axiosResponse?.data?.internalCode,
    };
  };
}

export default AxiosHttpClient;
