import { Translations } from '.';

const esES: Record<Translations, string> = {
  Organizador: 'Organizador',
  Paciente: 'Paciente',
  Profissional: 'Profesional',
  Convidado: 'Visitante',
  Administrador: 'Administrador',
  'Novo Administrador': 'Nuevo administrador',
  'Administrador Geral': 'Administradora Geral',
  'Documentos clínicos': 'Documentos clínicos',
  'Agenda de atendimentos': 'Cronograma de servicio',
  'Perfil do usuário': 'Perfil del usuario',
  'Detalhes do atendimento': 'Detalles del servicio',
  'Documento clínico': 'Documento clínico',
  'Pacientes cadastrados': 'Pacientes registrados',
  'Profissionais cadastrados': 'Profesionales registrados',
  'Organizadores cadastrados': 'Organizadores registrados',
  'Agendar novo atendimento': 'Programar nuevo servicio',
  'Cadastrar novo documento clínico': 'Registrar nuevo documento clínico',
  'Cadastrar novo participante': 'Registrar nuevo participante',
  'Digite o nome do participante': 'Ingrese el nombre del participante',
  'Cadastrar novo profissional': 'Registrar nuevo profesional',
  'Cadastrar novo organizador': 'Registrar nuevo organizador',
  'Digite o nome do organizador': 'Ingrese el nombre del organizador',
  Cancelar: 'Cancelar',
  Voltar: 'Volver',
  Próximo: 'Siguiente',
  Enviar: 'Enviar',
  Entrar: 'Acceder',
  'Carregando...': 'Cargando...',
  'Clique para ver as notas de release':
    'Haga clic para ver las notas de lanzamiento',
  'Enviar e concluir': 'Enviar y completar',
  'Sim, cancelar': 'Si, cancelar',
  'Não cancelar': 'No cancele',
  'Excluir usuário': 'Borrar usuario',
  'Novo paciente': 'Nuevo paciente',
  'Novo profissional': 'Nuevo profesional',
  'Novo organizador': 'Nuevo organizador',
  'Novo documento': 'Nuevo documento',
  'Novo agendamento': 'Nuevo servicio',
  Disponível: 'Disponible',
  Cancelado: 'Cancelado',
  Finalizado: 'Terminado',
  Agendado: 'Programado',
  'Em andamento': 'En proceso',
  Confirmado: 'Confirmado',
  'Documento não enviado': 'Documento no enviado al paciente',
  'Dados perfil': 'Datos de perfil',
  'Mudar senha': 'Cambiar contraseña',
  Permissão: 'Permiso',
  'Excluir conta': 'Borrar cuenta',
  'Login habilitado': 'Inicio de sesión habilitado',
  'Login habilitado com sucesso!': '¡Iniciar sesión habilitado con éxito!',
  'Login desabilitado com sucesso!': '¡Iniciar sesión deshabilitado con éxito!',
  Sair: 'Salir',
  'Aviso importante': 'Advertencia importante',
  'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ':
    'Después de borrar un usuario, las informaciónes pertinentes de la ellos como datos de servicio, documentos clínicos y de apoyo como también las conversaciones en chat, se mantendrá de acuerdo con las políticas de privacidad y terminos de uso de la plataforma V4H servicio. ',
  'Dados profissionais': 'Datos profesionales',
  Profissão: 'Profesión',
  'Salvar alterações': 'Guardar ediciones',
  'Mudança de senha': 'Cambio de contraseña',
  'Nova senha': 'Nueva contraseña',
  'Digite nova senha': 'Escribelo nueva contraseña',
  'Não foi possível alterar senha': 'No se puede cambiar la contraseña',
  'Senha atual': 'Contraseña atual',
  'Digite a senha atual': 'Introducir la contraseña actual',
  'Confirmar nova senha': 'Confirmar nueva contraseña',
  'Confime sua senha': 'Confirmar la contraseña',
  'Digite a confirmação da nova senha':
    'Ingresar nueva confirmación de contraseña',
  'Repita nova senha': 'Repita nueva contraseña',
  'Digite novamente a nova senha': 'Escribelo de nuevo de la nueva contraseña',
  'Não foi possível alterar a senha': 'No se puede cambiar la contraseña',
  'Senha alterada com sucesso!': '¡Contraseña alterada con éxito!',
  'Dados do perfil': 'Datos del perfil',
  'Dados gerais': 'Datos generales',
  'Enviar documento por e-mail': 'Enviar documento por correo electrónico',
  'Dados Pessoais': 'Datos Personales',
  'Dados Profissionais': 'Datos Profesionales',
  'Selecione sua profissão': 'Seleccione tu profesion',
  Médico: 'Médico',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Escribelo solamente letras mayúsculas y minúsculas y espacio.',
  Dentista: 'Dentista',
  Contatos: 'Contactos',
  Status: 'Estado',
  Ações: 'Acciones',
  Visualização: 'Visualización',
  Período: 'Período',
  Tipo: 'Tipo',
  'Documentos de apoio': 'Documentos de apoyo',
  Atendimento: 'Servicio',
  Retorno: 'Regreso',
  Emergency: 'Emergencia',
  Gravado: 'Grabado',
  'Cancelar atendimento?': '¿Cancelar servicio?',
  'Deseja realmente cancelar o atendimento: ':
    'Desea realmente cancelar el servicio: ',
  'Nome completo': 'Nombre completo',
  'Seu primeiro nome': 'Tu primero nombre',
  'Seu segundo nome': 'Tu segundo nombre',
  'Digite seu nome': 'Escribelo tu nombre',
  'Digite seu sobrenome': 'Escribelo tu apellido',
  Gênero: 'Género',
  Masculino: 'Masculino',
  Feminino: 'Femenino',
  Cpf: 'Cpf',
  Rg: 'Rg',
  'Nome da mãe': 'Nombre de la madre',
  Especialidade: 'Especialidad',
  'Data do Atendimento': 'Data del Servicio',
  'Selecione uma data disponível': 'Seleccione una fecha disponible',
  'Hora início': 'Hora início',
  'Hora fim': 'Hora final',
  Selecione: 'Seleccione',
  'Tipo de Atendimento': 'Tipo de Servicio',
  Convidados: 'Visitantes',
  'Digite o(s) email(s) para convidar':
    'Escribelo o(s) correo eletronico(s) para invitar',
  'Um Link estará disponível após a confirmação do agendamento.':
    'Un Link estará disponible despues de la confirmación del servicio.',
  'Data de nascimento': 'Fecha de nacimiento',
  Endereço: 'Dirección',
  Cep: 'Cep',
  Número: 'Número',
  Complemento: 'Complemento',
  Bairro: 'Distrito',
  UF: 'Estado',
  State: 'Estado',
  Cidade: 'Ciudad',
  Contato: 'Contacto',
  'E-mail': 'Correo electrónico',
  Senha: 'Contraseña',
  'Telefone celular': 'Teléfono celular',
  'Telefone alternativo': 'Teléfono alternativo',
  'Telefone celular 1': 'Teléfono celular 1',
  'Telefone celular 2': 'Teléfono celular 2',
  Salvar: 'Guardar',
  'Digite o seu email': 'Escribelo tu correo electrónico',
  'Digite sua Senha': 'Escribelo tu contraseña',
  Código: 'Código',
  'Digite o código do atendimento': 'Escribelo el código del servicio',
  'Sua relação familiar com o paciente': 'Tu relación familiar con el paciente',
  'Selecione uma opção': 'Seleccione una opción',
  Anterior: 'Anterior',
  'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.':
    'Después de borrar el usuario, algunas de las informaciónes asociadas a ello (datos de consultas médicas, documentos clínicos y de suporte, conversaciones por chat) se mantendrá de acuerdo con las políticas de privaciudad y terminos de uso del servicio.',
  'Termos de uso': 'Terminos de uso',
  'Política de privacidade': 'Política de privacidad',
  Assinado: 'Firmado',
  Pendente: 'Pendiente',
  'Lista de Documentos clínicos': 'Lista de Documentos clínicos',
  'Lista de Documentos de apoio': 'Lista de Documentos de apoyo',
  Tamanho: 'Tamaño',
  Data: 'Fecha',
  Cod: 'Cod',
  Assinatura: 'Assinatura',
  Hora: 'Hora',
  Origem: 'Origen',
  CPF: 'CPF',
  'Profissional da saúde': 'Profesional de la salud',
  'Registro de conselho': 'Registro del consejo',
  'Data de inclusão': 'Fecha de inclusión',
  Idade: 'Edad',
  Início: 'Início',
  Fim: 'Fim',
  'Iniciar atendimento': 'Comienza el servicio',
  Detalhes: 'Detalles',
  'Acessas farmácia': 'Acceso a la farmacia',
  'Ver perfil': 'Ver perfil',
  'Editar setor': 'Editar sector',
  'Baixar documento': 'Descargar documento',
  'Ir para sala de espera': 'Ir a la sala de espera',
  'Compartilhar por email': 'Compartir por correo electrónico',
  'Novo documento clínico': 'Nuevo documento clínico',
  'Limite de reuniões mensais (horas)': 'Límite de reuniones mensuales (horas)',
  Painel: 'Tablero',
  'Painel de monitoramento': 'Panel de monitoreo',
  'Painel de reuniões': 'Panel de reuniones',
  'Excluir usuario': 'Borrar usuario',
  'Cancelar atendimento': 'Cancelar servicio',
  'Finalizar atendimento': 'Cerrar servicio',
  'Editar especialidade': 'Editar especialidad',
  'Excluir especialidade': 'Borrar especialidad',
  'Copiar link': 'Copiar enlace',
  'Copiar link de atendimento': 'Copiar enlace de servicio',
  'URL copiada para a área de transferência': 'URL copiada al portapapeles',
  'Reenviar email': 'Reenviar email',
  'Enviar convite por Whatsapp': 'Enviar invitación por Whatsapp',
  'Excluir documento': 'Eliminar documento',
  Órgão: 'Órgano',
  Avulso: 'Avulso',
  Simples: 'Sencillo',
  'Atestado Médico': 'Certificado Médico',
  'Requisição de exame': 'Solicitud de examen',
  'Status do atendimento': 'Estado del servicio',
  'Participantes da Consulta': 'Participantes de la Consulta',
  'Dados do paciente': 'Datos del paciente',
  Participante: 'Partícipe',
  Nome: 'Nombre',
  anos: 'anos',
  'Pronto para participar?': '¿Pronto para participar?',
  'Ações do atendimento': 'Acciones del servicio',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'Para reprogramar o cambiar datos del servicio, usted necesitas editar el servicio en los campos al lado.',
  'Atendimento cancelado': 'Servicio cancelado',
  'Dados do profissional de saúde': 'Datos del profesional de la salud',
  'Registro no conselho profissional': 'Registro en consejo profesional',
  'Data e Hora': 'Fecha y Hora',
  Profissionais: 'Profesionales',
  Concluir: 'Concluir',
  'Paciente em questão': 'Paciente en cuestión',
  'Selecione um paciente': 'Seleccione un paciente',
  'Convidar paciente para participar': 'Invitar al paciente para participar',
  'Selecione uma especialidade': 'Seleccione una especialidade',
  'Data do atendimento': 'Data del servicio',
  'Hora de início': 'Hora de início',
  'Hora do fim': 'Hora del final',
  'Selecione um profissional': 'Seleccione un profesional',
  'Convidar profissional externo': 'Invitar el profesional externo',
  'Digite o nome do profissinal': 'Escribelo el nombre del profesional',
  'Nome do Profissional': 'Nombre del Profesional',
  'Digite o e-mail do profissional':
    'Escribelo el correo electrónico del profesional',
  'E-mail do profissional': 'Correo electrónico del profesional',
  'Não foi possível enviar e-mail de recuperação':
    'No se puede enviar el correo electrónico de recuperación',
  'E-mail enviado com sucesso!': '¡Email enviado con éxito!',
  'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.':
    'Se enviará un correo electrónico al usuario con instrucciones para cambiar la contraseña. El usuario aún podrá iniciar sesión con la contraseña actual hasta que la cambie.',
  Add: 'Add',
  'Lista de profissionais participantes':
    'Lista de profesionales participantes',
  'Digite seu CEP': 'Escribelo tu CEP',
  'Digite seu estado': 'Escribelo tu estado',
  Estado: 'Estado',
  'Digite sua cidade': 'Escribelo tu ciudad',
  'Digite seu país': 'Escribelo tu país',
  País: 'País',
  'Digite seu bairro': 'Escribelo tu bairro',
  'Rua Av. Ladeira...': 'Calle Av. Ladeira...',
  'Número da residência': 'Número de la residencia',
  'Digite um complemento': 'Escribelo un complemento',
  'Seu nome completo': 'Tu nombre completo',
  'Nome Completo': 'Nombre Completo',
  'Cadastro de Pessoa Física': 'Registro de Persona Fisica',
  'Número da identidade': 'Número de la identidad',
  'Selecione seu Gênero': 'Seleccione tu Género',
  Outros: 'Otros',
  'DD/MM/AAAA': 'DD/MM/AAAA',
  'Data de Nascimento': 'Fecha de Nacimiento',
  'Nome da sua mãe': 'Nombre de tu madre',
  'Digite uma senha pessoal': 'Escribelo una contraseña pessoal',
  'Senha de Acesso': 'Contraseña de Acceso',
  'Confirme a senha': 'Confirme de la contraseña',
  'Repita a senha de acesso': 'Repita de la contraseña de acceso',
  Fraca: 'Debil',
  Médio: 'Mediana',
  Forte: 'Fuerte',
  'Senha de': 'Contraseña de',
  segurança: 'segurança',
  Segurança: 'Segurança',
  Avançar: 'Avance',
  Avançado: 'Avanzado',
  Cadastro: 'Registro',
  'Cadastro de conferência': 'Registro de conferencia',
  'Selecione um papel': 'Seleccione un papel',
  'Permissões concedidas': 'Permisos concedido',
  Descrição: 'Descripción',
  Papel: 'Papel',
  'Seleção de papeis': 'Selección de roles',
  '+Adicionar': '+Agregar',
  'Profissional de saúde': 'Profesional de salud',
  'Última edição': 'Última edición',
  'Profissionais participantes': 'Profesionales participantes',
  'Não foi possível carregar o arquivo': 'No puede cargar el arquivo',
  'Dados Gerais': 'Datos Generales',
  'Acesso à Receita': 'Acceso à Prescripción',
  Acessar: 'Acessar',
  'Digite o código da receita': 'Escribelo el código de la prescripción',
  'Digite a senha da receita': 'Escribelo la contraseña de la prescripción',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insira el CÓDIGEl y CONTRASEÑA disponible en la prescripción del paciente',
  'para visualizar o arquivo assinado pelo médico':
    'para ver el arquivo firmado por el médico',
  'Criado em': 'Creado en',
  'Acesso para farmácia': 'Acceso para farmacia',
  'Receita Especial': 'Prescripción Especial',
  'Validador ICP Brasil': 'Validador ICP Brasil',
  'Baixar PDF': 'Descargar PDF',
  'Um link estará disponível após confirmação do agendamento':
    'Un link estará disponible despues de confirmación del servicio',
  'Digite o(s) e-mail(s) para convidar':
    'Escribelo o(s) correo electrónico(s) para invitar',
  'Selecione um tipo': 'Seleccione un tipo',
  'Tipo de serviço': 'Tipo de servicio',
  Hoje: 'Hoy',
  Domingo: 'Domingo',
  'Segunda-Feira': 'Lunes',
  'Terça-Feira': 'Martes',
  'Quarta-Feira': 'Miércoles',
  'Quinta-Feira': 'Jueves',
  'Sexta-Feira': 'Viernes',
  Sábado: 'Sábado',
  ' de Janeiro': ' de Enero',
  ' de Fevereiro': ' de Febrero',
  ' de Março': ' de Marzo',
  ' de Maio': ' de Mayo',
  ' de Abril': ' de Abril',
  ' de Junho': ' de Junio',
  ' de Julho': ' de Julio',
  ' de Agosto': ' de Agosto',
  ' de Setembro': ' de Septiembre',
  ' de Outubro': ' de Octubre',
  ' de Novembro': ' de Noviembre',
  ' de Dezembro': ' de Diciembre',
  'Receita simples': 'Prescripción simples',
  'Solicite a farmácia que acesse o endereço':
    'Solicite de la farmacia que acesse el endereço',
  'para baixar sua receita digital.': 'para baixar tu prescripción digital.',
  'Utilize o código e senha para acessar':
    'Utilize el código y contraseña para acessar',
  'Código:': 'Código:',
  'Senha:': 'Contraseña:',
  'Esqueceu sua senha?': '¿Olvidaste tu contraseña?',
  'Ver QR Code': 'Ver QR Code',
  'Código e Senha': 'Código y Contraseña',
  'Acesse o documento': 'Acesse el documento',
  'via o QR code do documento': 'via el QR code del documento',
  Perfil: 'Perfil',
  'Minha conta': 'Mi cuenta',
  Geral: 'General',
  Pacientes: 'Pacientes',
  'Profissionais de saúde': 'Profesionales de salud',
  Organizações: 'Organizaciones',
  'Selecione uma Organização': 'Seleccione una organización',
  'Ranking de organizações': 'Ranking de la organización',
  'Lista de organizações': 'Lista de organizaciones',
  'Detalhes de organização': 'Detalhes de la organización',
  'Criação de organização': 'Creación de organización',
  'Todas as organizações': 'Todas las organizaciones',
  'Organização expira?': '¿La organización expira?',
  'Organização habilitada até:': 'Organización habilitada hasta:',
  Painéis: 'Painéis',
  'Log de atividade': 'Log de actividad',
  'Serviços e faturamentos': 'Servicios y facturación',
  Faturamento: 'Facturación',
  Valor: 'Valor',
  Setores: 'Setores',
  'Selecione um Setor': 'Seleccione un Sector',
  'Detalhes do setor': 'Detalles do sector',
  'Todos os setores': 'Todos los setores',
  Agenda: 'Agenda',
  Usuário: 'Usuarios',
  Usuários: 'Usuarios',
  usuários: 'usuarios',
  'Ranking de usuários': 'Ranking de usuarios',
  'Lista de usuários': 'Lista de usuarios',
  'Detalhes do usuário': 'Detalles de usuario',
  'Criação de usuário': 'Creación de usuario',
  Administração: 'Administración',
  'Limpar todas': 'Limpiar todos',
  'Ocultar notificações': 'Esconder notificaciones',
  'criou um atendimento': 'creado un servicio',
  'cancelou um atendimento': 'canceló un servicio',
  'confirmou o atendimento': 'confirmó el servicio',
  'atualizou um atendimento': 'actualizado un servicio',
  'agendou o atendimento': 'programado el servicio',
  'deletou um atendimento': 'eliminado un servicio',
  'criou um atendimento com profissional acompanhante':
    'creado un servicio con profesional acompañante',
  'entrou na sala de espera': 'entró en la sala de espera',
  'saiu da sala de espera': 'salió de la sala de espera',
  'Nova mensagem': 'Nueva mensaje',
  Notificações: 'Notificaciones',
  'Últimas conversas': 'Ultimas conversaciones',
  'Selecione um tipo de documento clínico':
    'Seleccione un tipo de documento clínico',
  'Selecione um certificado': 'Seleccione un certificado',
  'Receita Simples': 'Prescripción Sencillo',
  'Receita Antimicrobiano': 'Prescripción Antimicrobiano',
  'Requisição de Exames': 'Solicitud de Exámenes',
  Instituição: 'Institución',
  'Assinar Documento': 'Firma Documento',
  'Data de emissão': 'Fecha de emisión',
  Droga: 'Droga',
  Dosagem: 'Dosis',
  Posologia: 'Dosis',
  'Certificado Digital': 'Certificado Digital',
  'Excluir Receita': 'Borrar Prescripción',
  'Enviar ao Paciente': 'Enviar al Paciente',
  'Tipo de documento': 'Tipo de documento',
  'Informações gerais': 'Informaciones generales',
  Prescrição: 'Prescripción',
  'Assinatura do documento': 'Assinatura del documento',
  Pesquisar: 'Buscar',
  'Buscar por...': 'Busquéda por...',
  'Pesquisar usuário': 'Buscar usuario',
  Redefinir: 'Reiniciar',
  'Redefinição de senha': 'Restablecer contraseña',
  'Redefinir senha': 'Restablecer contraseña',
  Qualquer: 'Cualquier',
  Estatísticas: 'Estadísticas',
  Gravados: 'Grabados',
  'Não gravado': 'No grabado',
  'Permite gravação de reuniões?': '¿Permite grabar las reuniones?',
  'Limite de gravações mensais (horas)':
    'Límite de grabaciones mensuales (horas)',
  'Documentos Clínicos': 'Documentos Clínicos',
  'Digite o nome do administrador': 'Escribelo el nombre del administrador',
  'Digite o CPF do administrador': 'Escribelo el CPF del administrador',
  'Digite o código do paciente': 'Escribelo el código del paciente',
  'Digite o nome do profissional': 'Escribelo el nombre del profesional',
  'Digite o nome do paciente': 'Escribelo el nombre del paciente',
  'Digite o nome de um organizador': 'Escribelo el nombre de un organizador',
  'Digite o CPF do organizador': 'Escribelo el CPF del organizador',
  'Digite o CPF do paciente': 'Escribelo el CPF del paciente',
  'Selecione uma data': 'Seleccione una fecha',
  Dia: 'Día',
  Mês: 'Mes',
  Ontem: 'Ayer',
  'Últimos 7 dias': 'Últimos 7 dias',
  'Últimos 30 dias': 'Últimos 30 dias',
  'Últimos 90 dias': 'Últimos 90 dias',
  Ínicio: 'Ínicio',
  'Em progresso': 'En progreso',
  'Digite o nome de um médico': 'Escribelo el nombre de un médico',
  'atendimentos neste dia': 'servicios este día',
  'O paciente foi informado que': 'El paciente fue informado que',
  'você está na sala de espera': 'usted está en la sala de espera',
  'Aguarde o início do atendimento': 'Aguarde el início del servicio',
  'nesta sala de espera': 'en ésta sala de espera',
  'Deixar sala': 'Dejar sala',
  'O paciente está pronto': 'El paciente está listo',
  'Você já pode iniciar o atendimento': 'Usted ya puede iniciar el servicio',
  'clicando em avançar': 'clicando en avançar',
  Cadastrados: 'Cadastrados',
  'Eventos do atendimento': 'Eventos del servicio',
  'Criação de reunião': 'Creación de reuniones',
  'Nova reunião': 'Nueva reunión',
  'Reunião instantânea': 'Reunión instantánea',
  Agendar: 'Programar',
  'Agendar reunião': 'Programar reunión',
  'Editar reunião': 'Editar reunión',
  'Reunião Agendada com sucesso!': '¡Reunión programada exitosamente!',
  'Não foi possível criar a reunião': 'No fue posible crear la reunión',
  'Deseja realmente excluir a reunião?':
    '¿Deseas realmente eliminar la reunión?',
  'Remoção de Reunião': 'Eliminación de reunión',
  'Reunião excluida com sucesso': 'Reunión eliminada exitosamente',
  'Não foi possível remover a reunião': 'No se pudo eliminar la reunión',
  'Conferência atualizada com sucesso': 'Conferencia actualizada exitosamente',
  'Edição de conferência': 'Edición de conferencia',
  'Não foi possível atualizar a reunião':
    'No fue posible actualizar la reunión',
  'Adicione um título': 'Agregar un título',
  'Informações da reunião': 'Información de la reunión',
  'Descrição da reunião': 'Descripción de la reunión',
  'Adicione uma descrição': 'Agregar una descripción',
  'Adicione participantes ou digite um novo e-mail':
    'Agrega participantes o ingresa un nuevo correo electrónico',
  'Formas de convite': 'Formas de invitación',
  Título: 'Título',
  Anotações: 'Notas',
  Gravação: 'Grabación',
  'Você poderá fazer o download da gravação assim que disponível.':
    'Podrá descargar la grabación tan pronto como esté disponible.',
  Chat: 'Chat',
  'Gravações do atendimento': 'Grabaciones del servicio',
  'Novo documento de apoio': 'Nuevo documento de apoyo',
  'Antes de entrar na sala de espera': 'Antes de entrar en la sala de espera',
  'Você precisa concordar com os termos de uso da':
    'Usted precisa está de acuerdo con los terminos de uso da',
  'plataforma e também concordar que o atendimento poderá':
    'plataforma y también está de acuerdo que el servicio podrá',
  'ser gravado mediante escolha do médico.':
    'ser grabado mediante escolha del médico.',
  'Aceito os': 'Acepto los',
  'termos de uso.': 'terminos de uso.',
  'Aceito que o atendimento poderá ser gravado.':
    'Acepto que el servicio podrá ser grabado.',
  'O médico foi informado que': 'El médico fue informado que',
  'Aguarde nesta tela.': 'Aguarde en ésta tela.',
  'Você será avisado quando o': 'Usted será avisado quando o',
  'médico estiver pronto': 'médico estiver listo',
  'Entrar na consulta': 'Entrar en la consulta',
  'Deixar a sala': 'Dejar la sala',
  'O médico está pronto': 'El médico está listo',
  'O médico já está na sala de atendimento':
    'El médico ya está en la sala de servicio',
  'Você já pode iniciar seu atendimento': 'Usted ya puede iniciar tu servicio',
  'Insira seu nome completo.': 'Insira tu nombre completo.',
  'CPF inválido.': 'CPF no es válido.',
  'RG inválido.': 'RG no es válido.',
  'RG inválido, existe(m) digitos faltando':
    'RG no es válido, hay dígitos faltantes',
  'RG inválido, número de digitos ultrapassou o limite':
    'RG no es válido, número de dígitos excedió el límite',
  'Email inválido.': 'Email no es válido.',
  'E-mail já cadastrado': 'Correo electrónico ya registrado',
  'Telefone inválido.': 'Teléfono no es válido.',
  'CEP inválido.': 'CEP no es válido.',
  'CEP inválido, existe(m) digitos faltando':
    'CEP no es válido, hay dígitos faltantes',
  'CEP inválido, número de digitos ultrapassou o limite':
    'CEP no es válido, número de dígitos excedió el límite',
  'Data de nascimento é obrigatório.': 'Fecha de nacimiento es obligatorio.',
  'Data inválida.': 'Fecha no es válido.',
  'Nome da mãe é obrigatório.': 'Nombre de la madre es obligatorio.',
  'UF inválido.': 'Estado no es válido.',
  'UF inválido, existe(m) caracteres faltando':
    'Estado no es válido, hay caracteres faltando',
  'UF inválido, número de caracteres ultrapassados':
    'Estado no es válido, número de caracteres excedió el límite',
  'Insira o nome da sua cidade.': 'Insira el nombre de tu ciudad.',
  'Insira o nome do seu bairro.': 'Insira el nombre de tu bairro.',
  'Insira o seu endereço.': 'Insira el tu endereço.',
  'Insira o número de sua residência': 'Insira el número de tu residencia',
  'Número inválido, ultrapassou o limite':
    'Número no es válido, excedió el limite',
  'Consultante inválido ou não encontrado.':
    'Consultante no es válido o no encontrado.',
  'Profissional inválido ou não encontrado.':
    'Profesional no es válido o no encontrado.',
  'Especialidade inválida ou não encontrada':
    'Especialidad inválida o no encontrada',
  'Opção inválida': 'Opção no es válido',
  'Data inválida': 'Data no es válido',
  'Hora de ínicio incorreta': 'Hora de ínicio incorreta',
  'Hora final incorreta': 'Hora final incorreta',
  'Senha inválida': 'Contraseña no es válido',
  'Insira o nome da droga': 'Insira el nombre de la droga',
  'Insira a dosagem': 'Insira la dosis',
  'Insira a posologia da medicação': 'Insira la dosis de la medicação',
  'Insira o tempo de afastamento': 'Insira el tiempo libre',
  'Insira uma indicação clínica.': 'Insira una indicación clínica.',
  'Insira o número do seu documento profissional':
    'Insira el número del tu documento profesional',
  'Insira a UF do seu documento profissional':
    'Insira de la Estado del tu documento profesional',
  'Insira a data de emissão do seu documento profissional':
    'Insira la fecha de emisión de tu documento profesional',
  'Selecione a área de atendimento': 'Seleccione de la área de servicio',
  'Falha no servidor, tente novamente mais tarde.':
    'Falla en servidor, intenta de nuevo más tarde.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Tus servicios aparecerán aqui. No hay itens para ser mostrado en el momento.',
  'Assinar agora': 'Firma ahora',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Seleccione tu certificado digital y finalize de la creación del documento ahora.',
  'Assinar depois': 'Firma despues',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'Este documento clinico será listado como Documento pendente y no será enviado al paciente até que la assinatura seja finalizada.',
  'Você não tem nenhum documento associado ao atendimento.':
    'Usted no tienes ninguno documento associado al servicio.',
  'Você não tem nenhuma gravação.': 'Usted no tienes ninguno grabación.',
  'Antes de iniciar': 'Antes de iniciar',
  'Não foi possível desativar a câmera.':
    'No fue possível desativar de la cámara.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'Recomendamos que entre con el micrófono y cámara activada.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Aceite los terminos de uso y de grabación para iniciar.',
  'Não foi possível buscar usuário.': 'No se puede obtener el usuario.',
  'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.':
    'El administrador general tiene permisos ilimitados en el sistema. Este cambio tiene implicaciones en la gestión del sistema, ya que elimina los permisos de administración para este usuario.',
  'Usuário promovido com sucesso!': '¡Usuario promovido con éxito!',
  'Usuário não é mais administrador do sistema!':
    '¡El usuario ya no es administrador del sistema!',
  'Você não pode editar o usuário sem uma organização':
    'No puede editar el usuario sin una organización',
  'Não foi possível buscar detalhes de administração do usuário':
    'No se pueden obtener los detalles de administración del usuario',
  'Informações do usuário atualizadas com sucesso!':
    '¡Información de usuario actualizada con éxito!',
  'Não foi possível atualizar as informações do usuário':
    'No se puede actualizar la información del usuario',
  'Usuário criado com sucesso!': '¡Usuario creado con éxito!',
  'Não foi possível criar usuário': 'No se puede crear usuario',
  'Não foi possível carregar dados do usuário':
    'No se pueden cargar los datos del usuario',
  DESATIVADA: 'DESATIVADA',
  'Entrar com:': 'Entrar com:',
  Ligado: 'Activado',
  Desligado: 'Apagado',
  Ligada: 'Activado',
  Desligada: 'Desactivada',
  'termos de uso': 'terminos de uso',
  'Aceito que o atendimento poderá ser gravado':
    'Acepto que el servicio podrá ser grabado',
  'Participar do atendimento': 'Participar del servicio',
  'Sair do atendimento': 'Salir del servicio',
  Sim: 'Si',
  Não: 'No',
  Ativado: 'Habilitado',
  Desativado: 'Deshabilitado',
  Sigla: 'Acrónimo',
  'Usuários Cadastrados': 'Usuarios Registrados',
  'Novo Usuário': 'Nuevo Usuario',
  Vínculo: 'Vínculo',
  Email: 'Correo electrónico',
  'Nova Organização': 'Nueva Organización',
  'Organizações cadastradas': 'Organizaciones registradas',
  Telefone: 'Teléfono',
  'Este número é whatsapp': '¿Este número es whatsapp?',
  CEP: 'Código postal',
  Logradouro: 'Lugar público',
  'Nome da Organização': 'Nombre de la Organización',
  'Nome da pessoa de contato': 'Nombre del Contacto',
  'Telefone 1': 'Teléfono 1',
  'Telefone 2': 'Teléfono 2',
  'Campo obrigatório': 'Campo obligatorio',
  'O nome é obrigatório': 'Se requiere el nombre',
  'O e-mail é obrigatório': 'correo electronico es requerido',
  'O telefone é obrigatório': 'Se requiere teléfono',
  'O e-mail não está em formato válido':
    'El correo electrónico no tiene un formato válido',
  Fechar: 'Cerrar',
  'Digite seu e-mail': 'Escriba su correo electrónico',
  'Digite o número do seu celular': 'Ingresa tu número de celular',
  'Nome da organização': 'Nombre de la organización',
  Setor: 'Sector',
  Organização: 'Organización',
  'Remoção de Organização': 'Eliminación de la organización',
  'Deseja realmente excluir a organização':
    '¿Desea realmente eliminar la organización?',
  Configurações: 'Configuraciones',
  'Configurações da organização atualizadas com sucesso!':
    '¡Configuraciones de la organización actualizadas con éxito!',
  'Não foi possível atualizar configurações da organização':
    'No fue posible actualizar las configuraciones de la organización',
  Confirmar: 'Confirmar',
  'Não é possível deletar organização com usuários ativos':
    'No se puede eliminar la organización con usuarios activos.',
  'Editar organização': 'Editar organización',
  'Ver organização': 'Ver organización',
  'Remover organização': 'Eliminar organización',
  'Remover usuário': 'Eliminar usuario',
  'Editar usuário': 'Editar usuario',
  'Deseja realmente excluir o usuário': '¿Desea realmente eliminar el usuario?',
  'Remoção de Usuário': 'Eliminación de usuario',
  'Organização removida com sucesso': 'Organización eliminada con éxito!',
  'Usuário removido com sucesso': 'Usuario eliminado con éxito!',
  'Não foi possível remover usuário': 'No se puede eliminar el usuario',
  'Organização atualizada com sucesso': 'Organización actualizada con éxito!',
  'Atualizar organização': 'Actualizar organización',
  'Não foi possível atualizar organização':
    'No se puede actualizar la organización',
  'Organização criada com sucesso': 'Organización creada con éxito!',
  'Já existe uma organização com este nome':
    'Ya existe una organización con este nombre',
  'Erro ao buscar dados da organização':
    'Error al buscar datos de la organización',
  'Não foi possível cadastrar a organização':
    'No se puede registrar la organización.',
  'Informações do usuário atualizadas com sucesso':
    'Información del usuario actualizada con éxito!',
  'Usuário criado com sucesso': 'Usuario creado con éxito!',
  'Organizadores do setor': 'Organizadores del sector',
  'Últimos 12 meses': 'Últimos 12 meses',
  'Nome do setor': 'Nombre del sector',
  'Não foi possível buscar dados da organização do setor':
    'No fue posible buscar datos de la organización del sector',
  'Altere algum campo para atualizar o setor.':
    'Altere algún campo para actualizar el sector.',
  'Setor atualizado com sucesso!': 'Sector actualizado con éxito!',
  'Atualizar setor': 'Actualizar sector',
  'Não foi possível atualizar o setor.': 'No fue posible actualizar el sector.',
  'Setor criado com sucesso!': 'Sector creado con éxito!',
  'Já existe um setor com este nome.': 'Ya existe un sector con este nombre.',
  'Não foi possível criar o setor.': 'No fue posible crear el sector.',
  Login: 'Login',
  'Último acesso': 'Último acceso',
  'Desvinculo de usuário do setor': 'Desvinculo de usuario del sector',
  'Deseja realmente desvincular esse usuário do setor?':
    'Deseja realmente desvincular esse usuario del sector?',
  'Usuário desassociado do setor com sucesso!':
    'Usuario desasociado del sector',
  'Não foi possível desassociar o usuário do setor.':
    'No fue posible desasociar el usuario del sector.',
  'Adicionar novo usuário': 'Adicionar nuevo usuario',
  'Vincular usuário existente': 'Vincular usuario existente',
  'Desvincular Organizador do Setor': 'Desvincular Organizador del Sector',
  'Novo setor': 'Nuevo sector',
  'Usuários lotados': 'Usuarios lotados',
  'Remover setor': 'Remover sector',
  'Ver setor': 'Ver sector',
  'Remoção de setor': 'Remoção de sector',
  'Não foi possível remover setor.': 'No fue posible remover sector.',
  'Deseja realmente excluir o setor?': 'Deseja realmente excluir el sector?',
  'Setor excluído com sucesso!': 'Sector excluído con éxito!',
  'O nome do setor deve conter no máximo 64 caracteres.':
    'El nombre del sector debe contener no máximo 64 caracteres.',
  'A sigla deve conter no máximo 32 caracteres.':
    'La sigla debe contener no máximo 32 caracteres.',
  'Criação de setor': 'Creación de sector',
  'Recursos Humanos': 'Recursos Humanos',
  'Centro de Atendimento ao cliente': 'Centro de Servicio al Cliente',
  Manutenção: 'Mantenimiento',
  'Tecnologia da Informação': 'Tecnología de la informacion',
  'Serviços Gerais': 'Servicios generales',
  Assessoria: 'Consejo',
  'Assessoria de Imprensa': 'Oficina de prensa',
  'Consumo por setor': 'Consumo por sectores',
  'Consumo por serviço': 'Consumo por servicio',
  'Consumo por usuário': 'Consumo por usuario',
  'Consumo por dia do mês': 'Consumo por día del mes',
  'Extrato de consumo detalhado': 'Declaración detallada de consumo',
  'Valor Total': 'Valor total',
  'Atualizar logo da organização': 'Actualizar logotipo de la organización',
  'Logo da organização alterado com sucesso!':
    '¡El logo de la organización cambió con éxito!',
  'Não foi possível atualizar logo da organização':
    'No se puede actualizar el logotipo de la organización',
  'Atualizar imagem do usuário': 'Actualizar imagen de usuario',
  'Imagem do usuário alterada com sucesso!':
    '¡La imagen de usuario cambió con éxito!',
  'Não foi possível atualizar imagem do usuário':
    'No se puede actualizar la imagen del usuario',
  'Desvinculo de Usuário da organização':
    'Desvincular usuario de la organización',
  'Deseja realmente desvincular esse usuário da organização?':
    '¿Realmente desea desvincular a este usuario de la organización?',
  'Usuário desassociado com sucesso': 'Usuario desasociado con éxito',
  'Não foi possível desvincular o usuário da organização':
    'No se puede desvincular al usuario de la organización',
  'Confirmação de conta': 'Confirmacion de cuenta',
  'Reenviar confirmação': 'Reenviar confirmación',
  'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.':
    'Se enviará un mensaje a la dirección de correo electrónico anterior para verificar la autenticidad y asociar la dirección de correo electrónico con el usuario.',
  'E-mail reenviado com sucesso!': '¡Correo electrónico reenviado con éxito!',
  'Não foi possível reenviar e-mail de confirmação':
    'No se puede reenviar el correo electrónico de confirmación',
  'Confirmação do cadastro de usuário': 'Confirmación de registro de usuario',
  'Cadastrado em': 'Registrado en',
  'Prazo para confirmação': 'Fecha límite para la confirmación',
  'sem prazo para confirmação': 'sin fecha límite para la confirmación',
  'E-mail confirmado em': 'Correo electrónico confirmado en',
  'Confirmação de e-mail': 'Confirmación de correo electrónico',
  'Não foi possível enviar e-mail': 'No se puede enviar el correo electronico',
  'não confirmado': 'no confirmado',
  'Último login em': 'Último inicio de sesión el',
  'não fez login': 'sin iniciar sesión',
  'Não foi possível confirmar conta': 'No se puede confirmar la cuenta',
  'Conta confirmada com sucesso!': '¡Cuenta confirmada con éxito!',
  Vigência: 'Validez',
  Vencimento: 'Madurez',
  AUTH_MISSING_AUTHORIZATION_HEADER: 'Cabeçalho de autorización ausente',
  AUTH_FORBIDDEN: 'Você não tem permissão para executar essa ação',
  AUTH_FAILED: 'Falha na autenticação',
  AUTH_REFRESH_FAILED: 'No se puede renovar o iniciar sesión',
  AUTH_NOT_CREATED_USER:
    'Não foi possível criar usuário no serviço de autenticação',
  AUTH_NOT_UPDATED_USER:
    'Não foi possível atualizar usuário no serviço de autenticação',
  AUTH_NOT_DELETED_USER:
    'Não foi possível eliminar usuário no serviço de autenticação',
  AUTH_COULD_NOT_RESET_PASSWORD:
    'Não foi possível redefinir a senha do usuário no serviço de autenticação',
  AUTH_COULD_NOT_LIST_USER:
    'Não foi possível listar a senha do usuário no serviço de autenticação',
  AUTH_MULTIPLES_ORG_AVAILABLE: 'Múltiples organizaciones disponibles',
  AUTH_REFRESH_INVALID_TOKEN: 'Token de renovación inválido',
  AUTH_USER_DOES_NOT_MATCH_WITH_TOKEN:
    'O ID do usuário não corresponde ao token',
  AUTH_ACL_NOT_PROVIDED: 'A ACL do usuário deve ser fornecida para this ação',
  AUTH_PWD_RECOVERY_TOKEN_INCORRECT:
    'Token de recuperación de senha incorrecto',
  AUTH_CONFIRMATION_TOKEN_INCORRECT: 'Token de confirmación incorrecto',
  VALIDATOR_REQUIRED_FIELD: 'Falta campo requerido',
  VALIDATOR_UNRECOGNIZED_FIELD: 'Campo no reconocido',
  VALIDATOR_DEFAULT_ISSUE: 'Problema por defecto de validación',
  FILE_REQUIRED: 'Se requiere archivo para esta acción',
  AUTHORIZATION_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_RESOURCE:
    'No se pudo crear el recurso en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_ROLE:
    'No se pudo crear el rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION:
    'No se pudo crear la acción en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_RESOURCES:
    'No se pudieron listar los recursos de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_ACTIONS:
    'No se pudieron listar las acciones de autorización',
  AUTHORIZATION_API_COULD_NOT_LIST_ROLES:
    'No se pudieron listar los roles de autorización',
  AUTHORIZATION_API_COULD_NOT_CREATE_USER:
    'No se pudo crear el usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_UPDATE_USER:
    'No se pudo actualizar el usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_USER:
    'No se pudo eliminar el usuario del sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_ROLE_TO_USER:
    'No se pudo dar el rol al usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_USER:
    'No se pudo dar permiso al usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_ROLE:
    'No se pudo dar permiso al rol en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_TO_USER:
    'No se pudo eliminar el rol del usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_REMOVE_PERMISSION_TO_USER:
    'No se pudo eliminar el permiso del usuario en el sistema de autorización',
  AUTHORIZATION_API_COULD_NOT_GET_USER_ACL:
    'No se pudo obtener el ACL del usuario en el sistema de autorización',
  CONFERENCE_NOT_FOUND: 'No se pudo encontrar la conferencia',
  CONFERENCE_NOT_CREATED: 'No se pudo crear la conferencia en la base de datos',
  CONFERENCE_NOT_DELETED:
    'No se pudo eliminar la conferencia en la base de datos',
  CONFERENCE_COULD_NOT_LIST:
    'No se pudo listar las conferencias en la base de datos',
  CONFERENCE_IS_CANCELED: 'La conferencia se ha cancelado o ha terminado',
  CONFERENCE_NOT_STARTED:
    'La conferencia no ha sido iniciada por el propietario',
  CONFERENCE_ALREADY_STARTED: 'La conferencia ya ha sido iniciada',
  CONFERENCE_MEMBER_NOT_CREATED:
    'No se pudo crear el miembro de la conferencia en la base de datos',
  CONFERENCE_MEMBER_NOT_DELETED:
    'No se pudo eliminar al participante de la conferencia en la base de datos',
  CONFERENCE_MEMBER_NOT_FOUND:
    'No se pudo encontrar el miembro de la conferencia',
  CONFERENCE_MEMBERS_USER_NOT_FOUND:
    'No se pudo encontrar el usuario del participante',
  CONFERENCE_MEMBER_COULD_NOT_LIST:
    'No se pudo listar los participantes en la base de datos',
  CONFERENCE_MEMBER_ALREADY_WITH_ROLE:
    'El participante ya ha sido asignado a este rol',
  CONFERENCE_MEMBER_ALREADY: 'El usuario ya es un participante',
  CONFERENCE_OWNER_CANNOT_QUIT:
    'El propietario no puede abandonar su propia conferencia',
  CONFERENCE_OWNER_NOT_IN_ORG:
    'El propietario no es miembro de esta organización',
  CONFERENCE_RECORDINGS_NOT_FOUND:
    'No se encontraron grabaciones para esta conferencia',
  CONFERENCE_SUMMARY_NOT_FOUND:
    'No se pudo encontrar información resumida de la conferencia',
  CONFERENCE_LIMIT_REACHED: 'Límite alcanzado. Se requiere el pago',
  NOTIFICATION_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el servicio de centro de notificaciones',
  NOTIFICATION_API_CHECK_MESSAGE_EXIST:
    'No se pudo verificar si el mensaje existe en el servicio de centro de notificaciones',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE:
    'No se pudo enviar el mensaje en el servicio de centro de notificaciones',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE_WITH_ATTACHEMENTS:
    'No se pudo enviar el mensaje con adjuntos en el servicio de centro de notificaciones',
  SETTING_DYNAMIC_GET:
    'El sistema parece estar faltando configuraciones obligatorias.',
  STORAGE_COULD_NOT_GET_URL:
    'No se pudo obtener la URL del archivo desde el servicio de almacenamiento',
  STORAGE_COULD_NOT_SEND_FILE:
    'No se pudo enviar el archivo desde el servicio de almacenamiento',
  ORG_NOT_FOUND: 'No se pudo encontrar la organización',
  ORG_SHORT_ALREADY_IN_USE: 'El nombre corto de la organización ya está en uso',
  ORG_NOT_CREATED: 'No se pudo crear la organización',
  ORG_NOT_UPDATED: 'No se pudo actualizar la organización',
  ORG_NOT_DELETED: 'No se pudo eliminar la organización',
  ORG_EXPIRED: 'La organización ha expirado',
  ORG_COULD_NOT_LINK_USER: 'No se pudo enlazar al usuario con la organización',
  ORG_LIMIT_REACHED: 'Se ha alcanzado el límite de unidades de organización',
  ORG_USER_LIMIT_REACHED:
    'La organización ya ha alcanzado el límite de usuarios',
  ORG_APIKEY_DECRYPT_ERROR:
    'No se pudo descifrar la clave de API: Clave de API no válida o faltante',
  ORG_COULD_NOT_LIST: 'No se pudo listar las organizaciones',
  ORG_ADMIN_NOT_CREATED: 'No se pudo crear el administrador de la organización',
  ORG_ADMIN_NOT_DELETED:
    'No se pudo eliminar el administrador de la organización',
  ORG_USER_NOT_FOUND: 'No se pudo encontrar al miembro de la organización',
  ORG_ADMIN_NOT_FOUND:
    'No se pudo encontrar al administrador de la organización',
  ORG_USER_NOT_DELETED: 'No se pudo eliminar al usuario de la organización',
  ORG_ADMIN_COULD_NOT_LIST:
    'No se pudo listar los administradores de la organización',
  ORG_USER_COULD_NOT_LIST: 'No se pudo listar los miembros de la organización',
  ORG_CANNOT_DELETE_ACTIVE_USERS:
    'No se puede eliminar la organización con usuarios activos',
  ORGUNIT_EXPIRED: 'Org expirada',
  ORGUNIT_NOT_FOUND: 'No se pudo encontrar la unidad de organización',
  ORGUNIT_NOT_CREATED: 'No se pudo crear la unidad de organización',
  ORGUNIT_NOT_UPDATED: 'No se pudo actualizar la unidad de organización',
  ORGUNIT_NOT_DELETED: 'No se pudo eliminar la unidad de organización',
  ORGUNIT_LIMIT_REACHED:
    'La unidad de organización ha alcanzado el límite de cantidad de unidades',
  ORGUNIT_USER_LIMIT_REACHED:
    'La unidad de organización ya ha alcanzado el límite de cantidad de usuarios',
  ORGUNIT_CANNOT_DELETE_ACTIVE_USERS:
    'No se puede eliminar la unidad de organización con usuarios activos',
  ORGUNIT_PARENT_NOT_FOUND:
    'No se pudo encontrar la unidad de organización principal',
  ORGUNIT_SHORT_ALREADY_IN_USE:
    'Ya existe una unidad de organización con este nombre corto',
  ORGUNIT_NAME_ALREADY_IN_USE:
    'Ya existe una unidad de organización con este nombre',
  ORGUNIT_COULD_NOT_LIST: 'No se pudo listar las unidades de organización',
  USER_NOT_FOUND: 'No se pudo encontrar al usuario',
  USER_NOT_CREATED: 'No se pudo crear al usuario',
  USER_NOT_UPDATED: 'No se pudo actualizar al usuario',
  USER_NOT_DELETED: 'No se pudo eliminar al usuario',
  USER_ALREADY_EXISTS:
    'El nombre de usuario o correo electrónico ya están en uso',
  USER_SUSPENDED: 'El usuario está suspendido',
  USER_NOT_IN_ORG: 'El usuario no existe en esta organización',
  USER_COULD_NOT_FIND_ORG: 'No se pudo encontrar la organización del usuario',
  USER_ALREADY_IN_ORG:
    'El usuario ya está asignado a esta organización y unidad',
  USER_COULD_NOT_LIST:
    'No se pudieron listar las conferencias en la base de datos',
  USER_ALREADY_CONFIRMED: 'El usuario ya ha confirmado',
  USER_ALREADY_AGREED: 'El usuario ya ha aceptado los Términos',
  DASHBOARD_COULD_NOT_GET: 'No se pudo obtener el panel',
  ADDRESS_NOT_CREATED: 'No se pudo crear la dirección',
  ADDRESS_NOT_UPDATED: 'No se pudo actualizar la dirección',
  ADDRESS_INCOMPLETE: 'La dirección está incompleta',
  SKIN_NOT_FOUND: 'No se pudo encontrar el estilo',
  SKIN_NOT_CREATED: 'No se pudo crear el estilo',
  SKIN_NAME_ALREADY_IN_USE: 'El nombre del estilo ya está en uso',
  SKIN_NOT_UPDATED: 'No se pudo actualizar el estilo',
  SKIN_NOT_DELETED: 'No se pudo eliminar el estilo',
  SYSTEM_SETTING_NOT_FOUND: 'No se pudo encontrar la configuración del sistema',
  SYSTEM_SETTING_NOT_CREATED: 'No se pudo crear la configuración del sistema',
  SYSTEM_SETTING_NAME_ALREADY_IN_USE:
    'El nombre de la configuración del sistema ya está en uso',
  SYSTEM_SETTING_NOT_UPDATED:
    'No se pudo actualizar la configuración del sistema',
  SYSTEM_SETTING_NOT_RESETED:
    'No se pudo restablecer la configuración del sistema',
  SYSTEM_SETTING_NOT_DELETED:
    'No se pudo eliminar la configuración del sistema',
  SESSION_MANAGER_COULD_NOT_CREATE_ROOM:
    'No se pudo crear la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_OPEN_ROOM:
    'No se pudo abrir la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_JOIN_ROOM:
    'No se pudo unir a la sala en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_LIST_RECORDING:
    'No se pudo recuperar las grabaciones del administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_GET_RECORDING:
    'No se pudo encontrar la grabación en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_GET_DASHBOARD:
    'No se pudo obtener información del panel de control del administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_GET_USER:
    'No se pudo obtener información del usuario del administrador de sesiones',
  SESSION_MANAGER_BILLING_NOT_FOUND: 'No se pudo encontrar la facturación',
  SESSION_MANAGER_ORG_ALREADY_EXISTS:
    'La organización con este nombre corto ya existe',
  SESSION_MANAGER_ORGUNIT_ALREADY_EXISTS:
    'La unidad organizativa con este nombre corto ya existe',
  SESSION_MANAGER_USER_ALREADY_EXISTS:
    'El usuario con este inicio de sesión ya existe',
  SESSION_MANAGER_COULD_NOT_GET_INFO: 'No se pudo obtener información',
  SESSION_MANAGER_COULD_NOT_CREATE_ORG:
    'No se pudo crear la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_CREATE_ORGUNIT:
    'No se pudo crear la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_CREATE_USER:
    'No se pudo crear el usuario en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORG:
    'No se pudo actualizar la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORGUNIT:
    'No se pudo actualizar la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_UPDATE_USER:
    'No se pudo actualizar el usuario en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_ORG:
    'No se pudo eliminar la organización en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_ORGUNIT:
    'No se pudo eliminar la unidad organizativa en el administrador de sesiones',
  SESSION_MANAGER_COULD_NOT_DELETE_USER:
    'No se pudo eliminar el usuario en el administrador de sesiones',
  FACE_RECOGNITION_API_COULD_NOT_UPDT_PICTURE:
    'No se pudo actualizar la imagen de usuario',
  FACE_RECOGNITION_API_NO_FACE_RECORD:
    'El usuario no tiene un registro facial adjunto al perfil',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_FACE:
    'No se pudo verificar la cara del usuario',
  FACE_RECOGNITION_API_COULD_NOT_CREATE_SUBJECT: 'No se pudo crear el sujeto',
  FACE_RECOGNITION_API_COULD_NOT_GET_SUBJECT: 'No se pudo obtener el sujeto',
  FACE_RECOGNITION_API_COULD_NOT_SEND_PICTURE: 'No se pudo enviar la imagen',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_SUBJECT:
    'No se pudo verificar el sujeto',
  FACE_RECOGNITION_API_COULD_NOT_ADD_FILE_TO_FORM: 'Error al leer el archivo',
  USERACTION_ALREADY_EXISTS: 'Ya existe una UserAction con este nombre',
  USERACTION_GROUP_ALREADY_EXISTS:
    'Ya existe un UserActionGroup con este nombre',
  USERACTION_NOT_CREATED: 'No se pudo crear UserAction en la base de datos',
  USERACTION_GROUP_NOT_CREATED:
    'No se pudo crear UserActionGroup en la base de datos',
  USERACTION_NOT_FOUND: 'No se pudo encontrar UserAction',
  USERACTION_GROUP_NOT_FOUND: 'No se pudo encontrar UserActionGroup',
  USERACTION_COULD_NOT_LIST:
    'No se pudieron listar las UserActions en la base de datos',
  USERACTION_GROUP_COULD_NOT_LIST:
    'No se pudieron listar los UserActionGroup en la base de datos',
  USERACTION_LOG_COULD_NOT_LIST:
    'No se pudieron listar los registros de usuario en la base de datos',
  UNRECOGNIZED_ORDERBY: 'Columna de orden no reconocida',
  LINK_GEN_API_LOGIN_ERROR:
    'No se pudo iniciar sesión en el sistema LinkGenerator',
  LINK_GEN_API_COULD_NOT_CREATE_LINK: 'No se pudo crear enlace',
  'Classificação de organizações': 'Clasificación de organizaciones',
  'Classificação de usuários': 'Clasificación de usuarios',
  'Duração média': 'Duración media',
  'Duração total': 'Duración total',
  'Horas de reuniões': 'Horas de reuniones',
  'Informações históricas': 'Informaciones históricas',
  'Média de consumo diário': 'Media de consumo diario',
  'Média de participantes': 'Media de participantes',
  'Não gravadas': 'No grabadas',
  'Número de reuniões': 'Número de reuniones',
  'Reuniões gravadas': 'Reuniones grabadas',
  'Reuniões não gravadas': 'Reuniones no grabadas',
  Agendadas: 'Agendadas',
  Armazenamento: 'Almacenamiento',
  Consumo: 'Consumo',
  Disponíveis: 'Disponibles',
  Gravadas: 'Grabadas',
  Gravações: 'Grabaciones',
  Horas: 'Horas',
  Realizadas: 'Realizadas',
  Total: 'Total',
  Utilizados: 'Utilizados',
  usado: 'usado',
  Reuniões: 'Reuniones',
  'Administradores de sistema': 'Administradores de sistema',
  'Nome do usuário': 'Nombre del usuario',
  'Novos usuários': 'Nuevos usuarios',
  'Sem atividade': 'Sin actividad',
  'Usuários ativos': 'Usuarios activos',
  'Usuários padrão': 'Usuarios padrão',
  Administradores: 'Administradores',
  Agendamentos: 'Agendamientos',
  Logados: 'Logados',
  Agora: 'Ahora',
  Organizadores: 'Organizadores',
  'Armazenamento total': 'Almacenamiento total',
  'Horas gravadas': 'Horas grabadas',
  'Quantidade de reuniões': 'Cantidad de reuniones',
};

export default esES;
