/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useState,
  useEffect,
  useCallback,
  FormEvent,
  useContext,
} from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Input, KeyboardDate } from 'presentation/components/UI';
import {
  iconPlusRed,
  iconMinusRed,
  IconCalendar,
} from 'presentation/base/icons';
import { makeRemoteUpdateOrg } from 'main/factories/usecases/org/UpdateOrgFactory';
import { makeRemoteGetOrg } from 'main/factories/usecases/org/GetOrgFactory';
import { OrgInfos } from 'domain/usecases/org/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { ThemeContext } from 'App';
import Translator from 'presentation/components/i18n/Translator';
import {
  Container,
  FormContainer,
  RowContainer,
  Title,
  Subtitle,
} from './styles/StyledParameters';

interface iParams {
  id: string;
}

const Parameters: React.FC = (): JSX.Element => {
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles({
    switch: {
      '& .MuiSwitch-switchBase': {
        color: '#FFF',
        '&:hover': {
          backgroundColor: `${theme.colors.primary.main}40`,
        },
      },
      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: `${theme.colors.primary.main}`,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#FFF',
        '&:hover': {
          backgroundColor: '#4BB54340',
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#4BB543',
      },
    },
  });

  const [org, setOrg] = useState<OrgInfos>({} as OrgInfos);
  const [selectDate, setSelectDate] = useState<Date>(new Date());
  const [conferenceLimit, setConferenceLimit] = useState<number>(0);
  const [orgExpire, setOrgExpire] = useState(false);

  const classes = useStyles();
  const params = useParams<iParams>();

  const handleCheck = useCallback(() => {
    setOrg({
      ...org,
      settings: {
        ...org?.settings,
        conference: {
          ...org?.settings?.conference,
          recording: {
            enabled: !org?.settings?.conference?.recording?.enabled,
          },
        },
      },
    });
  }, [org]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      makeRemoteUpdateOrg()
        .update({
          id: Number(params?.id),
          expiration: orgExpire ? selectDate : null,
          settings: {
            ...org?.settings,
            conference: {
              ...org?.settings?.conference,
              recording: {
                enabled: org?.settings?.conference?.recording?.enabled,
                limit: org?.settings?.conference?.recording?.limit,
              },
            },
          },
        })
        .then(res => {
          toast.success(
            Translator('Configurações da organização atualizadas com sucesso!'),
          );
        })
        .catch(err => {
          makeReduxActiveMessage().active({
            active: 'error',
            title: Translator('Atualizar organização'),
            content: Translator(
              'Não foi possível atualizar configurações da organização',
            ),
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    },
    [org?.settings, orgExpire, params?.id, selectDate],
  );

  useEffect(() => {
    if (!params?.id) return;

    makeRemoteGetOrg()
      .get({ id: Number(params?.id) })
      .then(response => {
        if (response.expiration) {
          setSelectDate(new Date(response.expiration));
          setOrgExpire(true);
        }

        setOrg(response);
      })
      .catch(() => {
        // toast.error('Erro ao buscar dados da organização!');
      });
  }, [params?.id]);

  return (
    <Container onSubmit={handleSubmit}>
      <FormContainer>
        <RowContainer alignEnd style={{ marginBottom: '26px' }}>
          <Input
            data-testid="input-monthly-limit"
            label={Translator('Limite de reuniões mensais (horas)')}
            height="32px"
            type="number"
            min={0}
            value={conferenceLimit}
            onChange={e => setConferenceLimit(Number(e.target.value))}
            isLabelBold
          />
          <Button
            data-testid="btn_appointment_monthly_minus"
            id="btn_minus"
            icon={iconMinusRed}
            variant="secundary"
            size="icon"
            height="24px"
            noFill
            style={{ marginLeft: '4px' }}
            onClick={() =>
              setConferenceLimit(
                conferenceLimit - 1 > 0 ? conferenceLimit - 1 : 0,
              )
            }
          />
          <Button
            data-testid="btn_appointment_monthly_plus"
            id="btn_plus"
            icon={iconPlusRed}
            variant="secundary"
            size="icon"
            height="24px"
            noFill
            onClick={() => setConferenceLimit(conferenceLimit + 1)}
          />
        </RowContainer>

        <RowContainer>
          <Title>{Translator('Permite gravação de reuniões?')}</Title>
        </RowContainer>

        <FormControlLabel
          control={
            <Switch
              data-testid="switch-recording-enabled"
              className={classes.switch}
              checked={org?.settings?.conference?.recording?.enabled}
              value={org?.settings?.conference?.recording?.enabled}
              onChange={handleCheck}
            />
          }
          label={
            org?.settings?.conference?.recording?.enabled
              ? `${Translator('Sim')}`
              : `${Translator('Não')}`
          }
          style={{
            fontWeight: 400,
            color: '#353535',
          }}
        />

        <RowContainer alignEnd style={{ margin: '20px 0' }}>
          <Input
            data-testid="input-recording-limit"
            label={Translator('Limite de gravações mensais (horas)')}
            height="32px"
            type="number"
            value={org?.settings?.conference?.recording?.limit}
            min={0}
            onChange={e =>
              setOrg({
                ...org,
                settings: {
                  ...org?.settings,
                  conference: {
                    ...org?.settings?.conference,
                    recording: {
                      ...org?.settings?.conference?.recording,
                      limit: Number(e.target.value),
                    },
                  },
                },
              })
            }
            disabled={!org?.settings?.conference?.recording?.enabled}
            isLabelBold
          />
          <Button
            data-testid="btn_appointment_recording_minus"
            id="btn_minus"
            icon={iconMinusRed}
            variant="secundary"
            size="icon"
            height="24px"
            noFill
            onClick={() =>
              setOrg({
                ...org,
                settings: {
                  ...org?.settings,
                  conference: {
                    ...org?.settings?.conference,
                    recording: {
                      ...org?.settings?.conference?.recording,
                      limit:
                        org?.settings?.conference?.recording?.limit &&
                        org?.settings?.conference?.recording?.limit - 1 > 0
                          ? org?.settings?.conference?.recording?.limit - 1
                          : 0,
                    },
                  },
                },
              })
            }
            disabled={!org?.settings?.conference?.recording?.enabled}
            style={{ marginLeft: '4px' }}
          />
          <Button
            data-testid="btn_appointment_recording_plus"
            id="btn_plus"
            icon={iconPlusRed}
            variant="secundary"
            size="icon"
            height="24px"
            onClick={() =>
              setOrg({
                ...org,
                settings: {
                  ...org?.settings,
                  conference: {
                    ...org?.settings?.conference,
                    recording: {
                      ...org?.settings?.conference?.recording,
                      limit:
                        (org?.settings?.conference?.recording?.limit &&
                          org?.settings?.conference?.recording?.limit + 1) ||
                        1,
                    },
                  },
                },
              })
            }
            disabled={!org?.settings?.conference?.recording?.enabled}
            noFill
          />
        </RowContainer>

        <RowContainer>
          <Title>{Translator('Organização expira?')}</Title>
        </RowContainer>

        <FormControlLabel
          control={
            <Switch
              data-testid="switch-expiration-enabled"
              className={classes.switch}
              checked={orgExpire}
              value={orgExpire}
              onChange={() => setOrgExpire(prev => !prev)}
            />
          }
          label={orgExpire ? `${Translator('Sim')}` : `${Translator('Não')}`}
          style={{
            marginBottom: '20px',
          }}
        />

        <KeyboardDate
          data-testid="input-expiration-date"
          state={selectDate}
          setState={(date: Date) => {
            setSelectDate(date);
          }}
          Icon={IconCalendar}
          label={Translator('Organização habilitada até:')}
          minDate={new Date()}
          width="330px"
          disabled={!orgExpire}
        />
      </FormContainer>
      <Button
        data-testid="btn_save"
        id="btn_save"
        variant="primary"
        size="medium"
        style={{ alignSelf: 'flex-end', marginTop: '42px' }}
        type="submit"
        onClick={handleSubmit}
      >
        {Translator('Salvar')}
      </Button>
    </Container>
  );
};

export default Parameters;
