export enum ConferenceTypes {
  LIST = '@conference/LIST',
  LIST_SUCCESS = '@conference/LIST_SUCCESS',
  LIST_FAILED = '@conference/LIST_FAILED',
  LIST_FILTERED_BY_ACL = '@conference/LIST_FILTERED_BY_ACL',
  LIST_FILTERED_BY_ACL_SUCCESS = '@conference/LIST_FILTERED_BY_ACL_SUCCESS',
  LIST_FILTERED_BY_ACL_FAILED = '@conference/LIST_FILTERED_BY_ACL_FAILED',
  LIST_FILTERED_BY_ACL_V2 = '@conference/LIST_FILTERED_BY_ACL_V2',
  LIST_FILTERED_BY_ACL_V2_SUCCESS = '@conference/LIST_FILTERED_BY_ACL_V2_SUCCESS',
  LIST_FILTERED_BY_ACL_V2_FAILED = '@conference/LIST_FILTERED_BY_ACL_V2_FAILED',
  UPDATE_CONTROLLER = '@conference/UPDATE_CONTROLLER',
}
