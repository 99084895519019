/* eslint-disable import/no-anonymous-default-export */
export default {
  colors: {
    primary: {
      dark: '#E20613', // unused
      main: '#E20613',
      light: '#FA3845',
      blue: '#1756E8',
    },
    secondary: {
      dark: '#004BA5',
      main: '#E20613',
      light: '#FACF71', // unused
    },
    background: {
      default: '#FFFFFF',
      hover: '#F4F7FE',
      press: '#CEDAF2',
    },
    text: {
      primary: '#444444',
      secondary: '#848181',
      darkGray: '#7A7A7A',
      lightGray: '#C9C9C9',
      dark: '#1C1413',
    },
    feedback: {
      highlight: '#52C8FA', // unused
      error: '#EC5C52', // unused
      none: '#EFEFEF', // unused
      success: '#5BB974',
      successBack: '#D3F4CE',
      cancel: '#E20613',
      cancelBack: '#FD867D',
      finished: '#7A7A7A', // unused
      finishedBack: '#EFEFEF', // unused
      scheduled: '#11AEF8', // unused
      scheduledBack: '#CEDAF2', // unused
      progress: '#FFFFFF', // unused
      progressBack: '#42ABC2', // unused
    },
    calendar: {
      day: '#FFFFFF',
    },
    menu: {
      selected: '#E20613',
      unselected: '#FFFFFF',
      hover: '#E206131a',
    },
    area: {
      menu: '#FFFFFF',
      header: '#FFFFFF',
      options: '#FFFFFF',
      work: '#FFFFFF',
    },
  },
  images: {
    logo: {
      main: 'https://minio.homolog.v4h.cloud/agenda/logoMain.svg',
      small: 'https://minio.homolog.v4h.cloud/agenda/logoSmall.svg',
      opacity: 'https://minio.homolog.v4h.cloud/agenda/logoOpacity.svg',
    },
    background: {
      main: 'https://minio.homolog.v4h.cloud/agenda/login.svg',
    },
  },
};
