import React, { useContext } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { withAccess } from 'presentation/hooks/access';
import { ThemeContext } from 'App';
import { Container, ContainerRoot, Label } from './styles';

const CssKeyboardDatePicker = withStyles({
  root: {
    height: '100%',
    paddingLeft: '14px',
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      border: 'none',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#A7A7A7',
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: '0px',
    },
    '& .MuiFormControl-marginDense': {
      margin: 0,
    },
    '& .MuiInputBase-input': {
      height: '100%',
      padding: 0,
    },
    '& .MuiInputBase-fullWidth': {
      height: '100%',
    },
    '& .MuiFormControl-fullWidth': {
      height: '100%',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiFormControl-root': { height: '100%' },
  },
})(KeyboardDatePicker);

interface ownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  state?: Date | null;
  defaultValue?: string;
  setState: Function;
  error?: boolean;
  message?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  autofocus?: boolean;
  required?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  width?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const InputKeyboardDate: React.FC<ownProps> = ({
  id,
  label,
  placeholder,
  name,
  state,
  setState,
  error,
  defaultValue,
  message,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  disabled,
  autofocus,
  required,
  width,
  Icon,
}): JSX.Element => {
  const { theme } = useContext(ThemeContext);

  const GlobalCss = withStyles({
    '@global': {
      '.MuiPickersCalendarHeader-switchHeader': {
        justifyContent: 'flex-end',
      },
      '.MuiPickersCalendarHeader-transitionContainer': {
        position: 'absolute',
        /* textAlign: 'start !important',
        right: '76px', */
      },
      '.MuiPickersCalendarHeader-transitionContainer > p': {
        textAlign: 'start',
        paddingLeft: '23px',
      },
      '.MuiPickersToolbar-toolbar': {
        backgroundColor: theme.colors.primary.main,
      },
      '.MuiPickersDay-daySelected': {
        backgroundColor: theme.colors.primary.main,
      },
      '.MuiPickersDay-current': {
        // color: theme.colors.primary.main,
      },
      '.MuiPickersDay-daySelected:hover': {
        backgroundColor: theme.colors.primary.main,
        filter: 'brightness(0.9)',
      },
    },
  })(() => null);

  const handlerDate = (date: Date | null) => {
    setState(date);
  };

  const mTop = label ? '4px' : '0px';
  const mBottom = message ? '2px' : '0px';

  let inputLabel = label;
  if (required) inputLabel = `${label}*`;

  return (
    <>
      <ContainerRoot error={error}>
        {label && <Label>{inputLabel}</Label>}
        <GlobalCss />
        <Container
          id={id}
          error={error}
          style={{ marginTop: mTop, marginBottom: mBottom, width }}
        >
          <CssKeyboardDatePicker
            fullWidth
            autoOk
            defaultValue={defaultValue}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            placeholder={placeholder}
            value={state}
            onChange={handlerDate}
            name={name}
            error={error}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
            autoFocus={autofocus}
          />
        </Container>
        <p id="error">{message}</p>
      </ContainerRoot>
    </>
  );
};

export default withAccess(InputKeyboardDate);
