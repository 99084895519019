import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import Translator from 'presentation/components/i18n/Translator';
import { OrgInfos } from 'domain/usecases/org/remote';
import { makeRemoteGetUfs } from 'main/factories/usecases/externalServices/GetUfs';
import { Select, Input, Button } from 'presentation/components/UI';
import {
  GetAddressData as RemoteGetAddressData,
  GetUfs as RemoteGetUfs,
  GetCitiesByUf as RemoteGetCitiesByUf,
} from 'domain/usecases/externalServices/remote';

import { makeRemoteGetCitiesByUf } from 'main/factories/usecases/externalServices/GetCitiesByUf';
import { makeRemoteGetAddressData } from 'main/factories/usecases/externalServices/GetAddressData';
import { getFormikMessageError } from 'utils/formikUtils';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { Container, InputFields, ContentButton } from './styles';
import { initialValuesOrg } from '..';

interface ownProps {
  org: OrgInfos;
  onChangeOrgInfo: Function;
}

const Address: React.FC<ownProps> = ({ org, onChangeOrgInfo }) => {
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const [address, setAddress] = useState(org.address);

  const { setFieldValue, errors, touched, handleBlur } =
    useFormikContext<initialValuesOrg>();

  const { resources } = useSelector((store: iStore) => store.auth.permissions);
  const { user } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);
        if (org?.address?.province) {
          onChangeOrgInfo({
            ...org,
            address: { ...org.address, province: org?.address?.province },
          });
        }
      })
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });
  }, []);

  useEffect(() => {
    if (org.address !== null) {
      if (org?.address?.province) {
        makeRemoteGetCitiesByUf()
          .getCitiesByUf({ uf: org.address.province })
          .then(res => {
            setCities(res);

            if (org?.address?.city)
              onChangeOrgInfo({
                ...org,
                address: { ...org.address, city: org?.address?.city },
              });
          })
          .catch(err => {
            console.log('Erro ao buscar cidades: ', err);
          });
      }
    }
  }, [org?.address?.province]);

  const onChangeZipcode = (value: string) => {
    if (value.length >= 9) return;

    onChangeOrgInfo({ ...org, address: { ...org.address, zipcode: value } });

    if (value.length >= 8 && value !== org?.address?.zipcode) {
      const dataToSend: RemoteGetAddressData.Params = {
        zipcode: value,
      };

      makeRemoteGetAddressData()
        .getAddressData(dataToSend)
        .then(response => {
          const updatedForm = {
            zipcode: value,
            city: response.localidade ?? '',
            neighborhood: response.bairro ?? '',
            street: response.logradouro ?? '',
            province: response.uf ?? '',
          };

          setFieldValue('zipcode', updatedForm.zipcode);
          setFieldValue('city', updatedForm.city);
          setFieldValue('neighborhood', updatedForm.neighborhood);
          setFieldValue('street', updatedForm.street);
          setFieldValue('province', updatedForm.province);
          setFieldValue('number', '');
          setFieldValue('complement', '');

          onChangeOrgInfo({
            ...org,
            address: {
              ...org.address,
              ...updatedForm,
              number: '',
              complement: '',
            },
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Erro: ', error);
        });
    }
  };

  const onChangeState = (value: string) => {
    onChangeOrgInfo({ ...org, address: { ...org.address, province: value } });
    setFieldValue('province', value);

    // setSelectedUf(value);
  };

  const onChangeComplement = (value: string) => {
    setFieldValue('complement', value);
    onChangeOrgInfo({ ...org, address: { ...org.address, complement: value } });
  };

  const onChangeCity = (value: string) => {
    setFieldValue('city', value);
    onChangeOrgInfo({ ...org, address: { ...org.address, city: value } });
  };

  const onChangeNeighborhood = (value: string) => {
    setFieldValue('neighborhood', value);
    onChangeOrgInfo({
      ...org,
      address: { ...org.address, neighborhood: value },
    });
  };

  const onChangeStreet = (value: string) => {
    setFieldValue('street', value);
    onChangeOrgInfo({ ...org, address: { ...org.address, street: value } });
  };

  const disableUpdate = useMemo(() => {
    if (user?.administrator?.system) return false;
    if (!resources) return true;

    // Go to resource ORG and check if user have UPDATE permission
    const haveUpdate = resources.find(
      res =>
        res.name === 'ORG' &&
        res.actions.find(item => item.name.includes('UPDATE')),
    );

    return !haveUpdate;
  }, [resources, user?.administrator?.system]);

  return (
    <Container>
      <InputFields>
        <Input
          mask="99999-999"
          label={`${Translator('CEP')}`}
          required
          name="zipcode"
          onBlur={handleBlur}
          defaultValue={org?.address?.zipcode}
          value={org?.address?.zipcode}
          message={touched.zipcode ? errors.zipcode : ''}
          error={Boolean(touched.zipcode && errors?.zipcode)}
          onChange={e => {
            setFieldValue('zipcode', e.target.value);
            onChangeZipcode(e.target.value.replace(/[^A-Z0-9]/gi, ''));
          }}
          autoFocus
          disabled={disableUpdate}
        />
        <Select
          label={`${Translator('Estado')}`}
          required
          name="province"
          onBlur={handleBlur}
          defaultValue={org?.address?.province}
          message={touched.province ? errors.province : ''}
          error={Boolean(touched.province && errors?.province)}
          onChange={e => {
            setFieldValue('province', e.target.value);
            onChangeState(e.target.value.trim());
          }}
          value={org?.address?.province}
          disabled={disableUpdate}
        >
          <option key={-1}>Selecione o Estado</option>
          {ufs &&
            ufs.length > 0 &&
            ufs.map(uf => (
              <option key={uf.id} value={uf.sigla}>
                {uf.sigla}
              </option>
            ))}
        </Select>
        <Select
          label={`${Translator('Cidade')}`}
          required
          name="city"
          onBlur={handleBlur}
          defaultValue={org?.address?.city}
          message={touched.city ? errors.city : ''}
          error={Boolean(touched.city && errors?.city)}
          onChange={e => {
            setFieldValue('city', e.target.value);
            onChangeCity(e.target.value.trim());
          }}
          value={org?.address?.city}
          disabled={disableUpdate}
        >
          <option key={-1} value="">
            Selecione a cidade
          </option>
          {cities &&
            cities.length > 0 &&
            cities.map(city => (
              <option key={city.id} value={city.nome}>
                {city.nome}
              </option>
            ))}
        </Select>
      </InputFields>
      <InputFields>
        <Input
          label={`${Translator('Bairro')}`}
          required
          name="neighborhood"
          onBlur={handleBlur}
          message={touched.neighborhood ? errors.neighborhood : ''}
          error={Boolean(touched.neighborhood && errors?.neighborhood)}
          defaultValue={org?.address?.neighborhood}
          onChange={e => {
            setFieldValue('neighborhood', e.target.value);
            onChangeNeighborhood(e.target.value);
          }}
          value={org?.address?.neighborhood}
          disabled={disableUpdate}
        />
        <Input
          label={`${Translator('Logradouro')}`}
          required
          name="street"
          onBlur={handleBlur}
          message={touched.street ? errors.street : ''}
          error={Boolean(touched.street && errors?.street)}
          defaultValue={org?.address?.street}
          onChange={e => {
            setFieldValue('street', e.target.value);
            onChangeStreet(e.target.value);
          }}
          value={org?.address?.street}
          disabled={disableUpdate}
        />
        <Input
          label={`${Translator('Número')}`}
          required
          name="number"
          onBlur={handleBlur}
          message={touched.number ? errors.number : ''}
          error={Boolean(touched.number && errors?.number)}
          defaultValue={org?.address?.number}
          onChange={e => {
            setFieldValue('number', e.target.value);
            onChangeOrgInfo({
              ...org,
              address: { ...org.address, number: e.target.value },
            });
          }}
          value={org?.address?.number}
          disabled={disableUpdate}
        />
      </InputFields>
      <InputFields>
        <Input
          label={`${Translator('Complemento')}`}
          name="complement"
          onBlur={handleBlur}
          message={touched.complement ? errors.complement : ''}
          error={Boolean(touched.complement && errors?.complement)}
          onChange={e => {
            setFieldValue('complement', e.target.value);
            onChangeComplement(e.target.value);
          }}
          defaultValue={org?.address?.complement}
          value={org?.address?.complement}
          disabled={disableUpdate}
        />
      </InputFields>
    </Container>
  );
};

export default Address;
