import { useFormikContext } from 'formik';
import React from 'react';
import Translator from 'presentation/components/i18n/Translator';
import { IconCalendar } from 'presentation/base/icons';
import { Input } from 'presentation/components/UI';
import InputKeyboardDate from 'presentation/components/UI/inputKeyboardDate';
import { UserByOrg, UserFieldsValidation } from '..';
import {
  Container,
  ContainerCheckbox,
  ContentFields,
  ContentInput,
  Fields,
} from './styles/StyledGeneralData';

interface ownProps {
  user: UserByOrg;
  onChangeUserInfo: Function;
  editing?: boolean;
}

const GeneralData: React.FC<ownProps> = ({
  user,
  onChangeUserInfo,
  editing = false,
}) => {
  const { errors, touched, setFieldValue, handleBlur } =
    useFormikContext<UserFieldsValidation>();

  return (
    <Container>
      <ContentFields>
        <Fields>
          <ContentInput>
            <Input
              autoFocus
              label={`${Translator('Nome')}`}
              name="name"
              required={!editing}
              placeholder={`${Translator('Digite seu nome')}`}
              defaultValue={editing ? user?.fullName : ''}
              value={user?.fullName}
              onBlur={handleBlur}
              message={touched.name ? errors.name : ''}
              error={Boolean(touched.name && errors.name)}
              onChange={e => {
                setFieldValue('name', e.target.value);
                onChangeUserInfo({ ...user, fullName: e.target.value });
              }}
            />
            <Input
              label={`${Translator('Email')}`}
              name="email"
              required={!editing}
              onBlur={handleBlur}
              placeholder={`${Translator('Digite seu e-mail')}`}
              message={touched.email ? errors.email : ''}
              error={Boolean(touched.email && errors.email)}
              defaultValue={editing ? user?.email : ''}
              value={user?.email}
              onChange={e => {
                setFieldValue('email', e.target.value);
                onChangeUserInfo({ ...user, email: e.target.value });
              }}
              disabled={editing}
            />
          </ContentInput>
        </Fields>
        <Fields>
          <ContentInput>
            <InputKeyboardDate
              label={`${Translator('Data de nascimento')}`}
              placeholder="Informe sua data de nascimento"
              name="birthdate"
              Icon={IconCalendar}
              state={user?.birthDate}
              message={touched.birthdate ? String(errors.birthdate) : ''}
              error={Boolean(touched.birthdate && errors.birthdate)}
              setState={(value: Date) => {
                setFieldValue('birthdate', value);
                onChangeUserInfo({ ...user, birthDate: value });
              }}
              maxDate={new Date()}
              disableFuture
            />
            <Input
              label={`${Translator('Telefone')}`}
              name="phone"
              required={!editing}
              defaultValue={editing ? user?.phone : ''}
              onBlur={handleBlur}
              message={touched.phone ? errors.phone : ''}
              error={Boolean(touched.phone && errors.phone)}
              mask="(99) 99999-9999"
              placeholder={`${Translator('Digite o número do seu celular')}`}
              value={user?.phone}
              onChange={e => {
                setFieldValue(
                  'phone',
                  e.target.value.replace(/[^A-Z0-9]/gi, ''),
                );
                onChangeUserInfo({
                  ...user,
                  phone: e.target.value.replace(/[^A-Z0-9]/gi, ''),
                });
              }}
            />
            <ContainerCheckbox>
              <input
                type="checkbox"
                defaultChecked={user?.notifyOptions?.whatsapp}
                onChange={e =>
                  onChangeUserInfo({
                    ...user,
                    notifyOptions: {
                      ...user.notifyOptions,
                      whatsapp: e.target.checked,
                    },
                  })
                }
              />
              {`${Translator('Este número é whatsapp')}`}
            </ContainerCheckbox>
          </ContentInput>
        </Fields>
      </ContentFields>
    </Container>
  );
};

export default GeneralData;
