import styled, { css } from 'styled-components';
import IconMenu from 'presentation/base/icons/menu.svg';
import { withAccess } from 'presentation/hooks/access';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.area.menu};
  width: 100%;
  box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, 0.25);
  padding: 0px 20px;
`;

export const Content = styled.div`
  width: 100%;
  /* max-width: 1920px; */
  margin: 0px auto;
  height: 72px;
  padding: 10px 0px;
  color: #656a6e;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ResponsiveMenu = styled.div`
  background-image: url(${IconMenu});
  display: none;
  width: 32px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
    margin-right: 15px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
`;
export const ButtonBack = styled.div``;

export const Left = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Right = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .icon {
    margin-right: 20px;
  }

  .iconOrg {
    /* margin-right: 30px; // This is margin when the Notification Icon Is enabled. */
  }
`;

interface iOptions {
  selected?: boolean;
}

export const Options = styled.div<iOptions>`
  height: 100%;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 18px;
  color: black;
  border-bottom: ${({ theme, selected }) =>
    selected && `2px solid ${theme.colors.primary.main}`};

  span {
    margin-left: 12px;
  }

  :hover {
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.primary.main}`};
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Avatar = styled.div`
  height: 100%;
  margin-left: 20px;
  cursor: pointer;
`;

interface iDrop {
  selected?: boolean;
}

export const Drop = styled.div<iDrop>`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 15px;
  color: black;
  /* background: ${({ theme }) => `${theme.colors.menu.unselected}`}; */
  cursor: pointer;
  border: none;

  span {
    margin-left: 15px;
  }

  :hover {
    background: ${({ theme }) => `${theme.colors.menu.hover}`};
  }

  ${props =>
    props.selected === true &&
    css`
      background-color: ${({ theme }) => theme.colors.menu.selected};
      color: white;
      cursor: default;

      :hover {
        background-color: ${({ theme }) => theme.colors.menu.selected};
      }
    `}
`;

export const DropInfo = styled.div`
  height: 81px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 20px;

  .name {
    font-weight: bold;
    font-size: 18px;
    text-align: right;
  }

  .role {
    font-size: 12px;
  }
`;

export const UserOptions = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 8px 8px;
  width: 235px;
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }
`;

export const AdministrationOptions = withAccess(styled.div``);

export const OrganizationsOption = withAccess(styled.div``);

export const UsersOption = withAccess(styled.div``);

export const DashboardOption = withAccess(styled.div``);
