import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { iStore } from 'domain/interfaces/models';
import { ListAdmin } from 'domain/usecases/admin/remote';
import { GetOrgUnit } from 'domain/usecases/orgUnit/remote';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { makeRemoteGetOrgUnit } from 'main/factories/usecases/orgUnit/GetOrgUnitFactory';
import { History } from 'main/routes';
import {
  IconHealth,
  IconPlusBlack,
  IconSharedLink,
} from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import { Icon } from 'presentation/components/icon';
import HeaderMenu from 'presentation/components/MenuNavigation/MenuNavigation';
import Pagination from 'presentation/components/Pagination';
import { Button } from 'presentation/components/UI/buttons';
import Popover from 'presentation/components/UI/popover/Popover';
import { closeModal } from 'utils/closeModal';
import { UpdateListAdmin } from 'utils/updateListUsers';
import OrgUnitName from './OrgUnitName';
import SectorOrganizers from './SectorOrganizers';
import {
  Body,
  Container,
  ContentSectors,
  DropUnit,
  Left,
  Right,
  RowContainer,
} from './styles/styledSectorDetails';

interface ownProps {
  id?: number;
  idOrg?: number;
  editing?: boolean;
  // TODO: Remove after everything is on Redux
  haveUpdated?: () => void;
}

const SectorDetails: React.FC<ownProps> = ({
  id,
  idOrg,
  editing = false,
  haveUpdated,
}): JSX.Element => {
  const url = window.location.pathname;
  const [orgUnitResponse, setOrgUnitResponse] = useState(
    {} as GetOrgUnit.Model,
  );
  const [orgUnitUsers, setOrgUnitUsers] = useState({} as ListAdmin.Model);
  const [tabNavigation, setTabNavigation] = useState(0);
  const [page, setPage] = useState<number>(1);

  const { push } = useHistory();
  const itemsPerPage = 15;

  const handleClick = useCallback(() => {
    makeReduxActiveMessage().active({
      active: 'selectBondAdmin',
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  }, []);

  const newOrgOptions = useMemo(
    () => (
      <div>
        <RowContainer
          data-testid="btn-new-admin"
          onClick={() => {
            History.getHistory().push(
              `/admin/user/create?org=${idOrg}&unit=${id}`,
              { from: url.replaceAll('/', '') },
            );
          }}
          resourcers={['USER']}
          actions={['CREATE_ANY', 'CREATE_ORG', 'CREATE_UNIT']}
        >
          <Icon src={IconPlusBlack} width="25px" height="25px" />
          <DropUnit>{Translator('Adicionar novo usuário')}</DropUnit>
        </RowContainer>
        <RowContainer
          data-testid="btn-add-existing"
          onClick={handleClick}
          resourcers={['USER']}
          actions={['PROMOTE_ANY', 'PROMOTE_ORG', 'PROMOTE_UNIT']}
        >
          <Icon src={IconSharedLink} width="25px" height="25px" />
          <DropUnit>{Translator('Vincular usuário existente')}</DropUnit>
        </RowContainer>
      </div>
    ),
    [handleClick, id, idOrg, url],
  );

  const displayData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    return {
      ...orgUnitUsers,
      records: orgUnitUsers.records?.slice(start, start + itemsPerPage),
    };
  }, [orgUnitUsers, page]);

  useEffect(() => {
    if (!id || !idOrg) return;

    makeRemoteGetOrgUnit()
      .get({ unitId: id, orgId: idOrg })
      .then(response => {
        setOrgUnitResponse(response);
      })
      .catch(e => {
        // toast.error('Não foi possível listar setores.');
      });
  }, [id, idOrg]);

  const { active } = useSelector((store: iStore) => store.message);
  const { resources } = useSelector((store: iStore) => store.auth.permissions);

  const canPromote = useMemo(() => {
    if (!resources) return true;

    // Go to resource USER and check if user have PROMOTE permission
    const havePromote = resources.find(
      res =>
        res.name === 'USER' &&
        res.actions.find(item => item.name.includes('PROMOTE')),
    );

    return havePromote;
  }, [resources]);

  useEffect(() => {
    if (!id || !idOrg || !canPromote) return;

    UpdateListAdmin(id, idOrg, data => setOrgUnitUsers(data));
  }, [id, idOrg, active, canPromote]);

  const options = useMemo(() => {
    const responseOptions = ['Nome'];

    if (editing && canPromote) responseOptions.push('Organizadores do setor');

    return responseOptions;
  }, [canPromote, editing]);

  const RenderSubPagesTab = useMemo(() => {
    const stepIndex: Record<number, JSX.Element> = {
      0: <OrgUnitName units={orgUnitResponse} editing={editing} />,
      1: <SectorOrganizers dataList={displayData} />,
    };

    return (
      <>
        <HeaderMenu
          options={options}
          setState={setTabNavigation}
          active={tabNavigation}
        />
        <ContentSectors renderButton={tabNavigation !== 0}>
          {tabNavigation !== 0 && (
            <>
              <div>
                <Pagination
                  currentPage={page}
                  pageSize={Math.ceil(
                    orgUnitUsers.records?.length / itemsPerPage,
                  )}
                  setPage={setPage}
                />
              </div>
              <div>
                <Popover
                  content={newOrgOptions}
                  originX="center"
                  originY="bottom"
                  resourcers={['USER']}
                  actions={[
                    'CREATE_ANY',
                    'CREATE_ORG',
                    'CREATE_UNIT',
                    'PROMOTE_ANY',
                    'PROMOTE_ORG',
                    'PROMOTE_UNIT',
                  ]}
                >
                  <Button
                    icon={IconHealth}
                    rounded
                    variant="primary"
                    size="medium"
                  >
                    {Translator('Novo organizador')}
                  </Button>
                </Popover>
              </div>
            </>
          )}
        </ContentSectors>
        {stepIndex[tabNavigation] || <div />}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayData,
    orgUnitResponse,
    orgUnitUsers.records?.length,
    page,
    tabNavigation,
  ]);

  return (
    <Container>
      <Body>
        <Left />
        <Right>{RenderSubPagesTab}</Right>
      </Body>
    </Container>
  );
};

export default SectorDetails;
