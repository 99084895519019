import { Translations } from '.';

const enUS: Record<Translations, string> = {
  Organizador: 'Organizer',
  Paciente: 'Patient',
  Profissional: 'Professional',
  Convidado: 'Guest',
  Administrador: 'Administrador',
  'Novo Administrador': 'New Admin',
  'Administrador Geral': 'General Administrator',
  'Documentos clínicos': 'Clinical docs',
  'Agenda de atendimentos': 'Appointment schedule',
  'Perfil do usuário': 'User profile',
  'Detalhes do atendimento': 'Appointment detail',
  'Documento clínico': 'Clinical doc',
  'Pacientes cadastrados': 'Registered patients',
  'Profissionais cadastrados': 'Registered professionals',
  'Organizadores cadastrados': 'Registered organizers',
  'Agendar novo atendimento': 'Schedule new appointment',
  'Cadastrar novo documento clínico': 'Register new clinical doc',
  'Cadastrar novo participante': 'Register new patient',
  'Digite o nome do participante': 'Enter the name of the participant',
  'Cadastrar novo profissional': 'Register new professional',
  'Cadastrar novo organizador': 'Register new organizer',
  'Digite o nome do organizador': 'Enter the name of the organizer',
  Cancelar: 'Cancel',
  Voltar: 'Back',
  Próximo: 'Next',
  Enviar: 'Send',
  Entrar: 'Enter',
  'Carregando...': 'Loading...',
  'Clique para ver as notas de release': 'Click to view the release notes',
  'Enviar e concluir': 'Send and conclude',
  'Sim, cancelar': 'Yes, cancel',
  'Não cancelar': 'Don`t cancel',
  'Excluir usuário': 'Delete user',
  'Novo paciente': 'New patient',
  'Novo profissional': 'new professional',
  'Novo organizador': 'New organizer',
  'Novo documento': 'New document',
  'Novo agendamento': 'New appointment',
  Disponível: 'Available',
  Cancelado: 'Canceled',
  Finalizado: 'Finished',
  Agendado: 'Scheduled',
  'Em andamento': 'In progress',
  Confirmado: 'Confirmed',
  'Documento não enviado': 'Document not sent',
  'Dados perfil': 'Profile data',
  'Mudar senha': 'Change password',
  Permissão: 'Professional',
  'Excluir conta': 'Delete account',
  'Login habilitado': 'Login enabled',
  'Login habilitado com sucesso!': 'Login enabled successfully!',
  'Login desabilitado com sucesso!': 'Login disabled successfully!',
  Sair: 'Exit',
  'Aviso importante': 'Important warning',
  'Ao excluir um usuário, as informações pertinentes a eles como dados de atendimentos, documentos clínicos e de apoio como também as conversas em chat, serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma V4H atendimento. ':
    "When removing the user, some of the information associated with it (medical appointment data, clinical and support documents, chat conversations), will be maintained in accordance with the service's privacy policies and terms of use.",
  'Dados profissionais': 'Professional data',
  Profissão: 'Profession',
  'Salvar alterações': 'Save changes',
  'Mudança de senha': 'Password change',
  'Nova senha': 'New password',
  'Digite nova senha': 'Type new password',
  'Não foi possível alterar senha': 'Unable to change password',
  'Senha atual': 'Current password',
  'Digite a senha atual': 'Enter current password',
  'Confirmar nova senha': 'Confirm new password',
  'Confime sua senha': 'Confirm your password',
  'Digite a confirmação da nova senha': 'Enter new password confirmation',
  'Repita nova senha': 'Repeat new password',
  'Digite novamente a nova senha': 'Retype new password',
  'Não foi possível alterar a senha': 'Unable to change password',
  'Senha alterada com sucesso!': 'Password changed successfully!',
  'Dados do perfil': 'Profile data',
  'Dados gerais': 'General data',
  'Enviar documento por e-mail': 'Send document by e-mail',
  'Dados Pessoais': 'Personal data',
  'Dados Profissionais': 'Professional data',
  'Selecione sua profissão': 'Select your profession',
  Médico: 'Medic',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Enter only uppercase and lowercase letters and space.',
  Dentista: 'Dentist',
  Contatos: 'Contacts',
  Status: 'Status',
  Ações: 'Actions',
  Visualização: 'Visualization',
  Período: 'Period',
  Tipo: 'Type',
  'Documentos de apoio': 'Supporting Documents',
  Atendimento: 'Attendance',
  Retorno: 'Return',
  Emergency: 'Emergency',
  Gravado: 'Recorded',
  'Cancelar atendimento?': 'Cancel appointment?',
  'Deseja realmente cancelar o atendimento: ':
    'Do you wish to cancel the schedule: ',
  'Nome completo': 'Full name',
  'Seu primeiro nome': 'Your first name',
  'Seu segundo nome': 'Your second name',
  'Digite seu nome': 'Insert your name',
  'Digite seu sobrenome': 'Insert your surname',
  Gênero: 'Gender',
  Masculino: 'Man',
  Feminino: 'Woman',
  Cpf: 'Cpf',
  Rg: 'Rg',
  'Nome da mãe': "Mother's name",
  Especialidade: 'Specialty',
  'Data do Atendimento': 'Date of Service',
  'Selecione uma data disponível': 'Select an available date',
  'Hora início': 'Start time',
  'Hora fim': 'End time',
  Selecione: 'Select',
  'Tipo de Atendimento': 'Type of service',
  Convidados: 'Guests',
  'Digite o(s) email(s) para convidar': 'Enter email(s) to invite',
  'Um Link estará disponível após a confirmação do agendamento.':
    'A link will be available after confirming the appointment.',
  'Data de nascimento': 'Date of birth',
  Endereço: 'Address',
  Cep: 'Cep',
  Número: 'Number',
  Complemento: 'Complement',
  Bairro: 'Neighborhood',
  UF: 'UF',
  State: 'State',
  Cidade: 'City',
  Contato: 'Contact',
  'E-mail': 'E-mail',
  Senha: 'Password',
  'Telefone celular': 'Cell phone',
  'Telefone alternativo': 'Backup phone',
  'Telefone celular 1': 'Mobile phone 1',
  'Telefone celular 2': 'Mobile phone 2',
  Salvar: 'Save',
  'Digite o seu email': 'Type your e-mail',
  'Digite sua Senha': 'Type your password',
  Código: 'Code',
  'Digite o código do atendimento': 'Type the service code',
  'Sua relação familiar com o paciente':
    'Your parental relationship with the patient',
  'Selecione uma opção': 'Choose an option',
  Anterior: 'Previous',
  'Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.':
    "When removing the user, some of the information associated with it (medical appointment data, clinical and support documents, chat conversations) will be kept in accordance with the service's privacy policies and terms of use.",
  'Termos de uso': 'Terms of use',
  'Política de privacidade': 'Privacy policy',
  Assinado: 'Signed Document',
  Pendente: 'Pending',
  'Lista de Documentos clínicos': 'Clinical documents',
  'Lista de Documentos de apoio': 'Supporting documents',
  Tamanho: 'size',
  Data: 'Status',
  Cod: 'Date',
  Assinatura: 'Patient',
  Hora: 'Full name',
  Origem: 'Specialty',
  CPF: 'Hora',
  'Profissional da saúde': 'Health professional',
  'Registro de conselho': 'Origin',
  'Data de inclusão': 'Inclusion date',
  Idade: 'Age',
  Início: 'End',
  Fim: 'Type',
  'Iniciar atendimento': 'Start appointment',
  Detalhes: 'Details',
  'Acessas farmácia': 'Accesss drugstore',
  'Ver perfil': 'See profile',
  'Editar setor': 'Edit sector',
  'Baixar documento': 'Download document',
  'Ir para sala de espera': 'Go to waiting room',
  'Compartilhar por email': 'Share by email',
  'Novo documento clínico': 'New clinical document',
  Painel: 'Dashboard',
  'Painel de monitoramento': 'Monitoring panel',
  'Painel de reuniões': 'Meeting panel',
  'Excluir usuario': 'Exclude user',
  'Cancelar atendimento': 'Cancel appointment',
  'Finalizar atendimento': 'Finish appointment',
  'Editar especialidade': 'Edit specialty',
  'Excluir especialidade': 'Exclude specialty',
  'Copiar link': 'Copy link',
  'Copiar link de atendimento': 'Copy service link',
  'URL copiada para a área de transferência': 'URL copied to clipboard',
  'Reenviar email': 'Resend email',
  'Enviar convite por Whatsapp': 'Send invite for Whatsapp',
  'Excluir documento': 'Exclude document',
  'Limite de reuniões mensais (horas)': 'Monthly meeting limit (hours)',
  Órgão: 'Organ',
  Avulso: 'Detached',
  Simples: 'Basic',
  'Atestado Médico': 'Medical Certificate',
  'Requisição de exame': 'Exam Request',
  'Status do atendimento': 'Appointment status',
  'Participantes da Consulta': 'Consultation participants',
  'Dados do paciente': 'Consultant data',
  Participante: 'Participant',
  Nome: 'Name',
  anos: 'years',
  'Pronto para participar?': 'Ready to join?',
  'Ações do atendimento': 'Service actions',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'To reschedule or change appointment data, please edit the information in the fields beside.',
  'Atendimento cancelado': 'Service was canceled',
  'Dados do profissional de saúde': 'Health professional data',
  'Registro no conselho profissional':
    'Registration with the professional council',
  'Data e Hora': 'Date and Time',
  Profissionais: 'Professionals',
  Concluir: 'Finish',
  'Paciente em questão': 'Patient',
  'Selecione um paciente': 'Select a patient',
  'Convidar paciente para participar': 'Invite a patient to participate',
  'Selecione uma especialidade': 'Select a speciality',
  'Data do atendimento': 'Date of service',
  'Hora de início': 'Start time',
  'Hora do fim': 'End time',
  'Selecione um profissional': 'Select a professional',
  'Convidar profissional externo': 'Invite an extern professional',
  'Digite o nome do profissinal': "Enter professional's name",
  'Nome do Profissional': "Professional's name",
  'Digite o e-mail do profissional': "Enter professional's e-mail",
  'E-mail do profissional': "Professional's e-mail",
  'Não foi possível enviar e-mail de recuperação':
    'Unable to send recovery email',
  'E-mail enviado com sucesso!': 'Email successfully sent!',
  'Um e-mail será enviado para o usuário com instruções para alteração de senha. O usuário ainda poderá fazer login com a senha atual até a alteração.':
    'An email will be sent to the user with instructions for changing the password. The user will still be able to log in with the current password until changed.',
  Add: 'Add',
  'Lista de profissionais participantes': 'Pofessionals participating',
  'Digite seu CEP': 'Enter your CEP',
  'Digite seu estado': 'Enter your state',
  Estado: 'State',
  'Digite sua cidade': 'Enter your city',
  'Digite seu país': 'Enter your country',
  País: 'Country',
  'Digite seu bairro': 'Enter your neighborhood',
  'Rua Av. Ladeira...': 'Enter your address',
  'Número da residência': 'Street number',
  'Digite um complemento': 'Enter a complement',
  'Seu nome completo': 'Enter your full name',
  'Nome Completo': 'Full name',
  'Cadastro de Pessoa Física': 'Individual registration',
  'Número da identidade': 'Enter your identity number',
  'Selecione seu Gênero': 'Choose your gender',
  Outros: 'Others',
  'DD/MM/AAAA': 'MM/DD/YYYY',
  'Data de Nascimento': 'Birth date',
  'Nome da sua mãe': 'Name of your mother',
  'Digite uma senha pessoal': 'Enter a password',
  'Senha de Acesso': 'Access password',
  'Confirme a senha': 'Confirm your password',
  'Repita a senha de acesso': 'Repeat password',
  Fraca: 'Weak',
  Médio: 'Medium',
  Forte: 'Strong',
  'Senha de': 'Password of',
  segurança: 'security',
  Segurança: 'Security',
  Avançar: 'Advance',
  Avançado: 'Advanced',
  Cadastro: 'Register',
  'Cadastro de conferência': 'Conference registration',
  'Selecione um papel': 'Select a role',
  'Permissões concedidas': 'Permissions Granted',
  Descrição: 'Description',
  Papel: 'Role',
  'Seleção de papeis': 'Role selection',
  '+Adicionar': '+Add',
  'Profissional de saúde': 'Healthcare Professional',
  'Última edição': 'Last edition',
  'Profissionais participantes': 'Professionals participanting',
  'Não foi possível carregar o arquivo': 'The file could not be loaded',
  'Dados Gerais': 'General Data',
  'Acesso à Receita': 'Access to Prescription',
  Acessar: 'Acess',
  'Digite o código da receita': 'Enter the prescription code',
  'Digite a senha da receita': 'Enter the password for the Prescription',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insert the CODE and PASSWORD available in the patients prescription',
  'para visualizar o arquivo assinado pelo médico':
    'to view the file signed by the doctor',
  'Criado em': 'Created at',
  'Acesso para farmácia': 'Pharmacy access',
  'Receita Especial': 'Special Prescription',
  'Validador ICP Brasil': 'ICP Brasil Validator',
  'Baixar PDF': 'Download PDF',
  'Um link estará disponível após confirmação do agendamento':
    'A link will be available after booking confirmation',
  'Digite o(s) e-mail(s) para convidar': 'Enter email(s) to invite',
  'Selecione um tipo': 'Select a type',
  'Tipo de serviço': 'Type of service',
  Hoje: 'Today',
  Domingo: 'Sunday,',
  'Segunda-Feira': 'Monday,',
  'Terça-Feira': 'Tuesday,',
  'Quarta-Feira': 'Wednesday,',
  'Quinta-Feira': 'Thursday,',
  'Sexta-Feira': 'Friday,',
  Sábado: 'Saturday,',
  ' de Janeiro': ' January',
  ' de Fevereiro': ' February',
  ' de Março': ' March',
  ' de Maio': ' May',
  ' de Abril': ' April',
  ' de Junho': ' June',
  ' de Julho': ' July',
  ' de Agosto': ' August',
  ' de Setembro': ' September',
  ' de Outubro': ' October',
  ' de Novembro': ' November',
  ' de Dezembro': ' December',
  'Receita simples': 'Simple recipe',
  'Solicite a farmácia que acesse o endereço':
    'Request the pharmacy to access the address',
  'para baixar sua receita digital.': 'to download your digital prescription.',
  'Utilize o código e senha para acessar':
    'Use the code and password to access',
  'Código:': 'Code:',
  'Senha:': 'Password:',
  'Esqueceu sua senha?': 'Forgot your password?',
  'Ver QR Code': 'See QR Code',
  'Código e Senha': 'Code and Password',
  'Acesse o documento': 'Access the document',
  'via o QR code do documento': "via the document's QR code",
  Perfil: 'Profile',
  'Minha conta': 'My account',
  Geral: 'All',
  Pacientes: 'Patients',
  'Profissionais de saúde': 'Health professionals',
  Organizações: 'Organizations',
  'Selecione uma Organização': 'Select an Organization',
  'Ranking de organizações': 'Organization Ranking',
  'Lista de organizações': 'Organization List',
  'Detalhes de organização': 'Organization Details',
  'Criação de organização': 'Organization creation',
  'Todas as organizações': 'All organizations',
  Configurações: 'Settings',
  'Configurações da organização atualizadas com sucesso!':
    'Organization settings updated successfully!',
  'Não foi possível atualizar configurações da organização':
    'Failed to update organization settings',
  'Organização expira?': 'Does the organization expire?',
  'Organização habilitada até:': 'Organization enabled until:',
  Painéis: 'Panels',
  'Log de atividade': 'Activity log',
  'Serviços e faturamentos': 'Services and billing',
  Faturamento: 'Billing',
  Valor: 'Value',
  Setores: 'Sectors',
  'Selecione um Setor': 'Select a Sector',
  'Detalhes do setor': 'Sector details',
  'Todos os setores': 'All sectors',
  Agenda: 'Schedule',
  Usuário: 'Users',
  Usuários: 'Users',
  usuários: 'users',
  'Ranking de usuários': 'User ranking',
  'Lista de usuários': 'User list',
  'Detalhes do usuário': 'User details',
  'Criação de usuário': 'User creation',
  Administração: 'Administration',
  'Limpar todas': 'Clear all',
  'Ocultar notificações': 'Hide notification',
  'criou um atendimento': 'created a new appointment',
  'cancelou um atendimento': 'canceled a appointment',
  'confirmou o atendimento': 'confirmed the appointment',
  'atualizou um atendimento': 'updated the appointment',
  'agendou o atendimento': 'appointment scheduled',
  'deletou um atendimento': 'appointment deleted',
  'criou um atendimento com profissional acompanhante':
    'appointment created with a professional companion',
  'entrou na sala de espera': 'join the waiting room',
  'saiu da sala de espera': 'left the waiting room',
  'Nova mensagem': 'New message',
  Notificações: 'Notifications',
  'Últimas conversas': 'Last conversations',
  'Selecione um tipo de documento clínico':
    'Select a type of clinical document',
  'Selecione um certificado': 'Select a certificate',
  'Receita Simples': 'Simple Prescription',
  'Receita Antimicrobiano': 'Antimicrobial Prescription',
  'Requisição de Exames': 'Exam requisition',
  Instituição: 'Institution',
  'Assinar Documento': 'Assinar Documento',
  'Data de emissão': 'Issue Date',
  Droga: 'Drug',
  Dosagem: 'Dosage',
  Posologia: 'Posology',
  'Certificado Digital': 'Digital Certificate',
  'Excluir Receita': 'Delete Prescription',
  'Enviar ao Paciente': 'Send To Patient',
  'Tipo de documento': 'Document Type',
  'Informações gerais': 'General information',
  Prescrição: 'Prescription',
  'Assinatura do documento': 'Document signature',
  Pesquisar: 'Search',
  'Buscar por...': 'Search for...',
  'Pesquisar usuário': 'Search user',
  Redefinir: 'Reset',
  'Redefinição de senha': 'Password reset',
  'Redefinir senha': 'Reset password',
  Qualquer: 'Any',
  Estatísticas: 'Statistics',
  Gravados: 'Recorded',
  'Não gravado': 'Not recorded',
  'Permite gravação de reuniões?': 'Is meeting recording allowed?',
  'Limite de gravações mensais (horas)': 'Monthly recording limit (hours)',
  'Documentos Clínicos': 'Clinical Docs',
  'Digite o nome do administrador': 'Enter the administrator name',
  'Digite o CPF do administrador': 'Enter an administrator CPF',
  'Digite o código do paciente': 'Enter an patient code',
  'Digite o nome do profissional': 'Enter the professional name',
  'Digite o nome do paciente': 'Enter the patient name',
  'Digite o nome de um organizador': 'Enter the organizer name',
  'Digite o CPF do organizador': 'Enter an organizer CPF',
  'Digite o CPF do paciente': 'Enter an patient CPF',
  'Selecione uma data': 'Select a date',
  Dia: 'Day',
  Mês: 'Month',
  Ontem: 'Yesterday',
  'Últimos 7 dias': 'Last 7 days',
  'Últimos 30 dias': 'Last 30 days',
  'Últimos 90 dias': 'Last 90 days',
  'Últimos 12 meses': 'Last 12 months',
  Ínicio: 'Start',
  'Em progresso': 'In progresss',
  'Digite o nome de um médico': 'Digite o nome de um médico',
  'atendimentos neste dia': 'appointments on this day',
  'O paciente foi informado que': 'The patient was informed that',
  'você está na sala de espera': 'you are in the waiting room',
  'Aguarde o início do atendimento': 'Wait for the service to start',
  'nesta sala de espera': 'in this waiting room',
  'Deixar sala': 'Leave room',
  'O paciente está pronto': 'The patient is ready',
  'Você já pode iniciar o atendimento': 'You can now start the service',
  'clicando em avançar': 'clicking next',
  Cadastrados: 'Registered',
  'Eventos do atendimento': 'Service events',
  'Criação de reunião': 'Meeting creation',
  'Nova reunião': 'New meeting',
  'Reunião instantânea': 'Instant meeting',
  Agendar: 'Schedule',
  'Agendar reunião': 'Schedule meeting',
  'Editar reunião': 'Edit meeting',
  'Reunião Agendada com sucesso!': 'Meeting scheduled successfully!',
  'Não foi possível criar a reunião': 'Failed to create the meeting',
  'Conferência atualizada com sucesso': 'Conference updated successfully',
  'Deseja realmente excluir a reunião?':
    'Do you really want to delete the meeting?',
  'Remoção de Reunião': 'Meeting removal',
  'Reunião excluida com sucesso': 'Meeting deleted successfully',
  'Não foi possível remover a reunião': 'Failed to remove the meeting',
  'Edição de conferência': 'Conference editing',
  'Não foi possível atualizar a reunião': 'Failed to update the meeting',
  'Adicione um título': 'Add a title',
  'Informações da reunião': 'Meeting information',
  'Descrição da reunião': 'Meeting description',
  'Adicione uma descrição': 'Add a description',
  'Adicione participantes ou digite um novo e-mail':
    'Add participants or enter a new email',
  'Formas de convite': 'Invitation methods',
  Título: 'Title',
  Anotações: 'Notes',
  Gravação: 'Recordings',
  'Você poderá fazer o download da gravação assim que disponível.':
    'You will be able to download the recording as soon as it becomes available.',
  Chat: 'Chat',
  'Gravações do atendimento': 'Call recordings',
  'Novo documento de apoio': 'New supporting document',
  'Antes de entrar na sala de espera': 'Before entering the waiting room',
  'Você precisa concordar com os termos de uso da':
    'You must agree to the terms of use for',
  'plataforma e também concordar que o atendimento poderá':
    'platform and also agree that the service may',
  'ser gravado mediante escolha do médico.':
    "`be recorded upon the doctor's choice.`",
  'Aceito os': 'I accept the',
  'termos de uso.': 'terms of use.',
  'Aceito que o atendimento poderá ser gravado.':
    'I accept that the service can be recorded.',
  'O médico foi informado que': 'The doctor was informed that',
  'Aguarde nesta tela.': 'Wait on this screen.',
  'Você será avisado quando o': 'You will be notified when the',
  'médico estiver pronto': 'doctor is ready',
  'Entrar na consulta': 'Join the appointment',
  'Deixar a sala': 'Leave the room',
  'O médico está pronto': 'The doctor is ready',
  'O médico já está na sala de atendimento':
    'The doctor is already in the care room',
  'Você já pode iniciar seu atendimento': 'You can now start your service',
  'Insira seu nome completo.': 'Enter your full name.',
  'CPF inválido.': 'Invalid CPF.',
  'RG inválido.': 'Invalid RG.',
  'RG inválido, existe(m) digitos faltando':
    'Invalid RG, there are missing digits',
  'RG inválido, número de digitos ultrapassou o limite':
    'Invalid RG, number of digits exceeded the limit',
  'Email inválido.': 'Invalid Email.',
  'E-mail já cadastrado': 'E-mail already registered',
  'Telefone inválido.': 'Invalid Phone.',
  'CEP inválido.': 'Invalid CEP.',
  'CEP inválido, existe(m) digitos faltando':
    'Invalid zip code, missing digit(s)',
  'CEP inválido, número de digitos ultrapassou o limite':
    'Invalid zip code, number of digits exceeded limit',
  'Data de nascimento é obrigatório.': 'Birth date is required.',
  'Data inválida.': 'Invalid Date.',
  'Nome da mãe é obrigatório.': 'Mother name is required.',
  'UF inválido.': 'Invalid UF.',
  'UF inválido, existe(m) caracteres faltando':
    'Invalid UF, there are characters missing',
  'UF inválido, número de caracteres ultrapassados':
    'Invalid UF, number of characters exceeded',
  'Insira o nome da sua cidade.': 'Enter your city name.',
  'Insira o nome do seu bairro.': 'Enter your neighborhood name.',
  'Insira o seu endereço.': 'Enter your address.',
  'Insira o número de sua residência': 'Enter your home number',
  'Número inválido, ultrapassou o limite': 'Invalid number, exceeded the limit',
  'Consultante inválido ou não encontrado.': 'Invalid or missing query.',
  'Profissional inválido ou não encontrado.':
    'Invalid or not found professional.',
  'Especialidade inválida ou não encontrada': 'Invalid or not found specialty',
  'Opção inválida': 'Invalid option',
  'Data inválida': 'Invalid date',
  'Hora de ínicio incorreta': 'Incorrect start time',
  'Hora final incorreta': 'Incorrect end time',
  'Senha inválida': 'Invalid password',
  'Insira o nome da droga': 'Enter the name of the drug ',
  'Insira a dosagem': 'Enter the dosage',
  'Insira a posologia da medicação': 'Enter the dosage of the medication',
  'Insira o tempo de afastamento': 'Enter the withdrawal time',
  'Insira uma indicação clínica.': 'Enter a clinical indication.',
  'Insira o número do seu documento profissional':
    'Enter your professional document number',
  'Insira a UF do seu documento profissional':
    'Enter your professional document state',
  'Insira a data de emissão do seu documento profissional':
    'Enter the emission date of your professional document',
  'Selecione a área de atendimento': 'Select the service area',
  'Falha no servidor, tente novamente mais tarde.':
    'Server failed, please try again later.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Your appointments will appear here. You do not have any appointment for this date.',
  'Assinar agora': 'Signed Now',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Select your digital certificate and finish creating the document now.',
  'Assinar depois': 'Signed After',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'This medical document will be listed as a Pending Document and will not be sent to the patient until the signature is finalized.',
  'Você não tem nenhum documento associado ao atendimento.':
    'There is no docs associated with the appointment.',
  'Você não tem nenhuma gravação.': 'You do not have any recording.',
  'Antes de iniciar': 'Before you start',
  'Não foi possível desativar a câmera.':
    'The camera could not be deactivated.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'We recommend that you enter with the microphone and camera turned on.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Accept the terms of use and recording to start.',
  'Não foi possível buscar usuário.': 'Could not find user.',
  'O administrador geral tem permissões irrestritas no sistema. Esta alteração tem implicações no gerenciamento do sistema pois remove permissões de administração deste usuário.':
    'The general administrator has unrestricted permissions on the system. This change has system management implications as it removes administration permissions for this user.',
  'Usuário promovido com sucesso!': 'User promoted with success!',
  'Usuário não é mais administrador do sistema!':
    'User is no longer a system administrator!',
  'Você não pode editar o usuário sem uma organização':
    'You cannot edit the user without an organization',
  'Não foi possível buscar detalhes de administração do usuário':
    'Unable to fetch user administration details',
  'Informações do usuário atualizadas com sucesso!':
    'User information successfully updated!',
  'Não foi possível atualizar as informações do usuário':
    'Unable to update user information',
  'Usuário criado com sucesso!': 'User successfully created!',
  'Não foi possível criar usuário': 'Unable to create user',
  'Não foi possível carregar dados do usuário': 'Unable to load user data',
  DESATIVADA: 'DISABLED',
  'Entrar com:': 'Login with:',
  Ligado: 'Switched on',
  Desligado: 'Switched of',
  Ligada: 'On',
  Desligada: 'Off',
  'termos de uso': 'terms of use',
  'Aceito que o atendimento poderá ser gravado':
    'I accept that the call may be recorded.',
  'Participar do atendimento': 'Participate in attendance',
  'Sair do atendimento': 'Exit care',
  Sim: 'Yes',
  Não: 'No',
  Ativado: 'Activated',
  Desativado: 'Deactivated',
  'Organizadores do setor': 'Sector organizers',
  'Nome do setor': 'Sector name',
  Sigla: 'Initials',
  'Não foi possível buscar dados da organização do setor':
    'Unable to get sector organization data',
  'Altere algum campo para atualizar o setor.':
    'Change any field to update the sector.',
  'Setor atualizado com sucesso!': 'Sector updated successfully!',
  'Atualizar setor': 'Update sector',
  'Não foi possível atualizar o setor.': 'Unable to update sector',
  'Setor criado com sucesso!': 'Sector created successfully',
  'Já existe um setor com este nome.':
    'There is already a sector with this name.',
  'Não foi possível criar o setor.': 'Unable to create sector',
  Login: 'Login',
  'Último acesso': 'Last access',
  'Desvinculo de usuário do setor': 'Unlink user from sector',
  'Deseja realmente desvincular esse usuário do setor?':
    'Do you really want to unlink this user from the sector?',
  'Usuário desassociado do setor com sucesso!': 'User unlinked from sector!',
  'Não foi possível desassociar o usuário do setor.': 'Unable to unlink user',
  'Adicionar novo usuário': 'Add new user',
  'Vincular usuário existente': 'Link existing user',
  'Desvincular Organizador do Setor': 'Unlink Sector Organizer',
  Confirmar: 'Confirm',
  Fechar: 'Close',
  'Novo setor': 'New sector',
  'Usuários lotados': 'Users assigned',
  'Remover setor': 'Remove sector',
  'Ver setor': 'View sector',
  'Remoção de setor': 'Sector removal',
  'Não foi possível remover setor.': 'Unable to remove sector',
  'Deseja realmente excluir o setor?':
    'Do you really want to delete the sector?',
  'Setor excluído com sucesso!': 'Sector deleted successfully!',
  'Campo obrigatório': 'Required field',
  'O nome do setor deve conter no máximo 64 caracteres.':
    'Maximum 64 characters',
  'A sigla deve conter no máximo 32 caracteres.': 'Maximum 32 characters',
  'Criação de setor': 'Sector creation',
  'Atualizar organização': 'Update organization',
  'Deseja realmente excluir a organização':
    'Do you really want to delete the organization?',
  'Deseja realmente excluir o usuário':
    'Do you really want to delete the user?',
  'Digite o número do seu celular': 'Enter your mobile number',
  'Digite seu e-mail': 'Enter your email',
  'Editar organização': 'Edit organization',
  'Editar usuário': 'Edit user',
  'Erro ao buscar dados da organização': 'Error fetching organization data',
  'Este número é whatsapp': 'This number is whatsapp',
  'Informações do usuário atualizadas com sucesso':
    'User information successfully updated',
  'Já existe uma organização com este nome':
    'There is already an organization with this name',
  'Nome da Organização': 'Organization name',
  'Nome da organização': 'Organization name',
  'Nome da pessoa de contato': 'Contact name',
  'Nova Organização': 'New Organization',
  'Novo Usuário': 'New User',
  'Não foi possível atualizar organização': 'Unable to update organization',
  'Não foi possível cadastrar a organização': 'Unable to register organization',
  'Não foi possível remover usuário': 'Unable to remove user',
  'Não é possível deletar organização com usuários ativos':
    'Unable to delete organization with active users',
  'O e-mail não está em formato válido': 'Invalid email format',
  'O e-mail é obrigatório': 'Email is required',
  'O nome é obrigatório': 'Name is required',
  'O telefone é obrigatório': 'Phone is required',
  'Organização atualizada com sucesso': 'Organization successfully updated',
  'Organização criada com sucesso': 'Organization successfully created',
  'Organização removida com sucesso': 'Organization successfully removed',
  'Organizações cadastradas': 'Registered organizations',
  'Remover organização': 'Remove organization',
  'Remover usuário': 'Remove user',
  'Remoção de Organização': 'Organization removal',
  'Remoção de Usuário': 'User removal',
  'Telefone 1': 'Phone 1',
  'Telefone 2': 'Phone 2',
  'Usuário criado com sucesso': 'User successfully created',
  'Usuário removido com sucesso': 'User successfully removed',
  'Usuários Cadastrados': 'Registered users',
  'Ver organização': 'View organization',
  'Recursos Humanos': 'Human resources',
  'Centro de Atendimento ao cliente': 'Customer Support Center',
  Manutenção: 'Maintenance',
  'Tecnologia da Informação': 'Information Technology',
  'Serviços Gerais': 'General Services',
  'Assessoria de Imprensa': 'Press office',
  'Consumo por setor': 'Consumption by sector',
  'Consumo por serviço': 'Consumption per service',
  'Consumo por usuário': 'Consumption per user',
  'Consumo por dia do mês': 'Consumption by day of the month',
  'Extrato de consumo detalhado': 'Detailed consumption statement',
  'Valor Total': 'Amount',
  'Atualizar logo da organização': 'Update organization logo',
  'Logo da organização alterado com sucesso!':
    'Organization logo changed successfully!',
  'Não foi possível atualizar logo da organização':
    'Unable to update organization logo',
  'Atualizar imagem do usuário': 'Update user image',
  'Imagem do usuário alterada com sucesso!': 'User image changed successfully!',
  'Não foi possível atualizar imagem do usuário': 'Unable to update user image',
  'Desvinculo de Usuário da organização':
    'Unlinking User from the organization',
  'Deseja realmente desvincular esse usuário da organização?':
    'Do you really want to unlink this user from the organization?',
  'Usuário desassociado com sucesso': 'User disassociated successfully',
  'Não foi possível desvincular o usuário da organização':
    'Unable to unlink user from organization',
  'Confirmação de conta': 'Account Confirmation',
  'Reenviar confirmação': 'Resend confirmation',
  'Uma mensagem será enviada para o endereço de e-mail acima para verificação da autenticidade e associação do endereço de e-mail ao usuário.':
    'A message will be sent to the above email address to verify authenticity and associate the email address with the user.',
  'E-mail reenviado com sucesso!': 'Email resent successfully!',
  'Não foi possível reenviar e-mail de confirmação':
    'Unable to resend confirmation email',
  'Confirmação do cadastro de usuário': 'Confirmation of user registration',
  'Cadastrado em': 'Registered in',
  'Prazo para confirmação': 'Deadline for confirmation',
  'sem prazo para confirmação': 'no deadline for confirmation',
  'E-mail confirmado em': 'Email confirmed at',
  'Confirmação de e-mail': 'Email confirmation',
  'Não foi possível enviar e-mail': 'Unable to send email',
  'não confirmado': 'not confirmed',
  'Último login em': 'Last login on',
  'não fez login': 'not logged in',
  'Não foi possível confirmar conta': 'Unable to confirm account',
  'Conta confirmada com sucesso!': 'Account confirmed successfully!',
  Vigência: 'Validity',
  Vencimento: 'Maturity',
  Assessoria: 'Advice',
  CEP: 'Zip code',
  Email: 'Email',
  Logradouro: 'Street',
  Organização: 'Organization',
  Setor: 'Sector',
  Telefone: 'Phone',
  Vínculo: 'Link',
  AUTH_MISSING_AUTHORIZATION_HEADER: 'Missing authorization header',
  AUTH_FORBIDDEN: 'You have not permission to execute action',
  AUTH_FAILED: 'Authentication failed',
  AUTH_REFRESH_FAILED: 'Could not refresh login',
  AUTH_NOT_CREATED_USER: 'Could not create user in authentication service',
  AUTH_NOT_UPDATED_USER: 'Could not update user in authentication service',
  AUTH_NOT_DELETED_USER: 'Could not delete user in authentication service',
  AUTH_COULD_NOT_RESET_PASSWORD:
    'Could not reset user password in authentication service',
  AUTH_COULD_NOT_LIST_USER:
    'Could not list user password in authentication service',
  AUTH_MULTIPLES_ORG_AVAILABLE: 'Auth multiples org available',
  AUTH_REFRESH_INVALID_TOKEN: 'Invalid refresh token',
  AUTH_USER_DOES_NOT_MATCH_WITH_TOKEN: 'The user id does not match with token',
  AUTH_ACL_NOT_PROVIDED: "User\\'s ACL must be provided for this action",
  AUTH_PWD_RECOVERY_TOKEN_INCORRECT: 'Password recovery token is incorrect',
  AUTH_CONFIRMATION_TOKEN_INCORRECT: 'Confirmation token is incorrect',
  VALIDATOR_REQUIRED_FIELD: 'Required field is missing',
  VALIDATOR_UNRECOGNIZED_FIELD: 'Unrecognized field',
  VALIDATOR_DEFAULT_ISSUE: 'Default zod issue',
  FILE_REQUIRED: 'File is required to this action',
  AUTHORIZATION_API_LOGIN_ERROR: 'Could not login into authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_RESOURCE:
    'Could not create resource in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_ROLE:
    'Could not create role in authorization system',
  AUTHORIZATION_API_COULD_NOT_CREATE_ACTION:
    'Could not create action in authorization system',
  AUTHORIZATION_API_COULD_NOT_LIST_RESOURCES:
    'Could not list authorization resources',
  AUTHORIZATION_API_COULD_NOT_LIST_ACTIONS:
    'Could not list authorization actions',
  AUTHORIZATION_API_COULD_NOT_LIST_ROLES: 'Could not list authorization roles',
  AUTHORIZATION_API_COULD_NOT_CREATE_USER:
    'Could not create user in authorization system',
  AUTHORIZATION_API_COULD_NOT_UPDATE_USER:
    'Could not update user in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_USER:
    'Could not remove user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_ROLE_TO_USER:
    'Could not give role to user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_USER:
    'Could not give permission to user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GIVE_PERMISSION_TO_ROLE:
    'Could not give permission to role in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_ROLE_TO_USER:
    'Could not remove role from user in authorization system',
  AUTHORIZATION_API_COULD_NOT_REMOVE_PERMISSION_TO_USER:
    'Could not remove permission from user in authorization system',
  AUTHORIZATION_API_COULD_NOT_GET_USER_ACL:
    'Could not get user ACL in authorization system',
  CONFERENCE_NOT_FOUND: 'Could not find conference',
  CONFERENCE_NOT_CREATED: 'Could not create conference in database',
  CONFERENCE_NOT_DELETED: 'Could not delete conference in database',
  CONFERENCE_COULD_NOT_LIST: 'Could not list conferences in the database',
  CONFERENCE_IS_CANCELED: 'Conference is cancelled or has ended',
  CONFERENCE_NOT_STARTED: "Conference hasn\\'t been started by the owner",
  CONFERENCE_ALREADY_STARTED: 'Conference has already started',
  CONFERENCE_MEMBER_NOT_CREATED:
    'Could not create conference member in database',
  CONFERENCE_MEMBER_NOT_DELETED:
    "Could not delete conference\\'s participant in database",
  CONFERENCE_MEMBER_NOT_FOUND: 'Could not find conference',
  CONFERENCE_MEMBERS_USER_NOT_FOUND: "Could not find paticipant\\'s user",
  CONFERENCE_MEMBER_COULD_NOT_LIST:
    'Could not list participants in the database',
  CONFERENCE_MEMBER_ALREADY_WITH_ROLE:
    'Participant is already assign to this role',
  CONFERENCE_MEMBER_ALREADY: 'User is already a participant',
  CONFERENCE_OWNER_CANNOT_QUIT: 'Owner cannot quit his own conference',
  CONFERENCE_OWNER_NOT_IN_ORG: 'Owner is not a member of this organization',
  CONFERENCE_RECORDINGS_NOT_FOUND: 'No recordings found for this conference',
  CONFERENCE_SUMMARY_NOT_FOUND: 'Could not find conference summary information',
  CONFERENCE_LIMIT_REACHED: 'Limit reached. Payment is required',
  NOTIFICATION_API_LOGIN_ERROR:
    'Could not login in notification center service',
  NOTIFICATION_API_CHECK_MESSAGE_EXIST:
    'Could not check if message exist in notification center service',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE:
    'Could not send message in notification center service',
  NOTIFICATION_API_COULD_NOT_SEND_MESSAGE_WITH_ATTACHEMENTS:
    'Could not send message with attachments in notification center service',
  SETTING_DYNAMIC_GET: 'The System appears to be missing mandatory settings.',
  STORAGE_COULD_NOT_GET_URL: 'Could not get file url from storage service',
  STORAGE_COULD_NOT_SEND_FILE: 'Could not send file from storage service',
  ORG_NOT_FOUND: 'Could not find org',
  ORG_SHORT_ALREADY_IN_USE: 'Org shortname already in use',
  ORG_NOT_CREATED: 'Could not create org',
  ORG_NOT_UPDATED: 'Could not update org',
  ORG_NOT_DELETED: 'Could not update org',
  ORG_EXPIRED: 'Org expired',
  ORG_COULD_NOT_LINK_USER: 'Could not link user to org',
  ORG_LIMIT_REACHED: 'OrgUnit limit reached',
  ORG_USER_LIMIT_REACHED: 'Org has already reached the users amount limit',
  ORG_APIKEY_DECRYPT_ERROR:
    'Could not decrypt API key: Missing or invalid API key',
  ORG_COULD_NOT_LIST: 'Could not list orgs',
  ORG_ADMIN_NOT_CREATED: 'Could not create org administrator',
  ORG_ADMIN_NOT_DELETED: 'Could not remove org administrator',
  ORG_USER_NOT_FOUND: 'Could not find org member',
  ORG_ADMIN_NOT_FOUND: 'Could not find org administrator',
  ORG_USER_NOT_DELETED: 'Could not remove org administrator',
  ORG_ADMIN_COULD_NOT_LIST: 'Could not list administrators',
  ORG_USER_COULD_NOT_LIST: 'Could not list org members',
  ORG_CANNOT_DELETE_ACTIVE_USERS: 'Cannot delete org with active users',
  ORGUNIT_EXPIRED: 'Organization expired',
  ORGUNIT_NOT_FOUND: 'Could not find org unit',
  ORGUNIT_NOT_CREATED: 'Could not create org unit',
  ORGUNIT_NOT_UPDATED: 'Could not update org unit',
  ORGUNIT_NOT_DELETED: 'Could not update org unit',
  ORGUNIT_LIMIT_REACHED: 'Org unit has already reached the units amount limit',
  ORGUNIT_USER_LIMIT_REACHED:
    'Org unit has already reached the users amount limit',
  ORGUNIT_CANNOT_DELETE_ACTIVE_USERS:
    'Cannot delete org unit with active users',
  ORGUNIT_PARENT_NOT_FOUND: "Could not find orgUnit's parent",
  ORGUNIT_SHORT_ALREADY_IN_USE: 'Org unit with this shortname already exists',
  ORGUNIT_NAME_ALREADY_IN_USE: 'Org unit with this name already exists',
  ORGUNIT_COULD_NOT_LIST: 'Could not list org units',
  USER_NOT_FOUND: 'Could not find user',
  USER_NOT_CREATED: 'Could not create user',
  USER_NOT_UPDATED: 'Could not update user',
  USER_NOT_DELETED: 'Could not update user',
  USER_ALREADY_EXISTS: 'User login or email already in use',
  USER_SUSPENDED: 'User is suspended',
  USER_NOT_IN_ORG: 'User does not exist in this organization',
  USER_COULD_NOT_FIND_ORG: "Could not find user's organization",
  USER_ALREADY_IN_ORG: 'User is already assigned to this org & unit',
  USER_COULD_NOT_LIST: 'Could not list conferences in the database',
  USER_ALREADY_CONFIRMED: 'Could not list conferences in the database',
  USER_ALREADY_AGREED: 'User has already agreed to Terms',
  DASHBOARD_COULD_NOT_GET: 'Could not get dashboard',
  ADDRESS_NOT_CREATED: 'Could not create address',
  ADDRESS_NOT_UPDATED: 'Could not update address',
  ADDRESS_INCOMPLETE: 'Address is incomplete',
  SKIN_NOT_FOUND: 'Could not find skin',
  SKIN_NOT_CREATED: 'Could not create skin',
  SKIN_NAME_ALREADY_IN_USE: 'Skin name already in use',
  SKIN_NOT_UPDATED: 'Could not update skin',
  SKIN_NOT_DELETED: 'Could not update skin',
  SYSTEM_SETTING_NOT_FOUND: 'Could not find systemSetting',
  SYSTEM_SETTING_NOT_CREATED: 'Could not create systemSetting',
  SYSTEM_SETTING_NAME_ALREADY_IN_USE: 'SystemSetting name already in use',
  SYSTEM_SETTING_NOT_UPDATED: 'Could not update systemSetting',
  SYSTEM_SETTING_NOT_RESETED: 'Could not reset system settings',
  SYSTEM_SETTING_NOT_DELETED: 'Could not update systemSetting',
  SESSION_MANAGER_COULD_NOT_CREATE_ROOM:
    'Could not create room in Session Manager',
  SESSION_MANAGER_COULD_NOT_OPEN_ROOM: 'Could not open room in Session Manager',
  SESSION_MANAGER_COULD_NOT_JOIN_ROOM: 'Could not join room in Session Manager',
  SESSION_MANAGER_COULD_NOT_LIST_RECORDING:
    'Could not retrieve recordings from Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_RECORDING:
    'Could not find recording in Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_DASHBOARD:
    'Could not get dashboard information from Session Manager',
  SESSION_MANAGER_COULD_NOT_GET_USER:
    'Could not get user information from Session Manager',
  SESSION_MANAGER_BILLING_NOT_FOUND: 'Could not find billing',
  SESSION_MANAGER_ORG_ALREADY_EXISTS: 'Org with this shortname already exists',
  SESSION_MANAGER_ORGUNIT_ALREADY_EXISTS:
    'Org unit with this shortname already exists',
  SESSION_MANAGER_USER_ALREADY_EXISTS: 'User with this login already exists',
  SESSION_MANAGER_COULD_NOT_GET_INFO: 'Could not get information',
  SESSION_MANAGER_COULD_NOT_CREATE_ORG: 'Could not create org',
  SESSION_MANAGER_COULD_NOT_CREATE_ORGUNIT:
    'Could not create org unit in Session Manager',
  SESSION_MANAGER_COULD_NOT_CREATE_USER:
    'Could not create user in Session Manager',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORG: 'Could not update organization',
  SESSION_MANAGER_COULD_NOT_UPDATE_ORGUNIT: 'Could not update org unit',
  SESSION_MANAGER_COULD_NOT_UPDATE_USER: 'Could not update org unit',
  SESSION_MANAGER_COULD_NOT_DELETE_ORG: 'Could not delete organization',
  SESSION_MANAGER_COULD_NOT_DELETE_ORGUNIT: 'Could not delete org unit',
  SESSION_MANAGER_COULD_NOT_DELETE_USER: 'Could not delete user',
  FACE_RECOGNITION_API_COULD_NOT_UPDT_PICTURE: 'Could not update user picture',
  FACE_RECOGNITION_API_NO_FACE_RECORD:
    'User does not have a facial record attached to the profile',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_FACE: "Could not verify user's face",
  FACE_RECOGNITION_API_COULD_NOT_CREATE_SUBJECT: 'Could not create subject',
  FACE_RECOGNITION_API_COULD_NOT_GET_SUBJECT: 'Could not get subject',
  FACE_RECOGNITION_API_COULD_NOT_SEND_PICTURE: 'Could not send picture',
  FACE_RECOGNITION_API_COULD_NOT_VERIFY_SUBJECT: 'Could not verify subject',
  FACE_RECOGNITION_API_COULD_NOT_ADD_FILE_TO_FORM: 'Error reading file',
  USERACTION_ALREADY_EXISTS: 'UserAction with this name already exists',
  USERACTION_GROUP_ALREADY_EXISTS:
    'UserActionGroup with this name already exists',
  USERACTION_NOT_CREATED: 'Could not create UserAction in database',
  USERACTION_GROUP_NOT_CREATED: 'Could not create UserActionGroup in database',
  USERACTION_NOT_FOUND: 'Could not find UserAction',
  USERACTION_GROUP_NOT_FOUND: 'Could not find UserActionGroup',
  USERACTION_COULD_NOT_LIST: 'Could not list UserActions in the database',
  USERACTION_GROUP_COULD_NOT_LIST:
    'Could not list userActionGroup in the database',
  USERACTION_LOG_COULD_NOT_LIST: 'Could not list user logs in the database',
  UNRECOGNIZED_ORDERBY: 'Unrecognized order column',
  LINK_GEN_API_LOGIN_ERROR: 'Could not login into LinkGenerator system',
  LINK_GEN_API_COULD_NOT_CREATE_LINK: 'Could not create link',
  'Classificação de organizações': 'Organization ranking',
  'Classificação de usuários': 'User ranking',
  'Duração média': 'Average duration',
  'Duração total': 'Total duration',
  'Horas de reuniões': 'Hours of meetings',
  'Informações históricas': 'Historical information',
  'Média de consumo diário': 'Average daily consumption',
  'Média de participantes': 'Average participants',
  'Não gravadas': 'Not recorded',
  'Número de reuniões': 'Number of meetings',
  'Reuniões gravadas': 'Recorded meetings',
  'Reuniões não gravadas': 'Unrecorded meetings',
  Agendadas: 'Scheduled',
  Armazenamento: 'Storage',
  Consumo: 'Consumption',
  Disponíveis: 'Available',
  Gravadas: 'Recorded',
  Gravações: 'Recordings',
  Horas: 'Hours',
  Realizadas: 'Realized',
  Total: 'Total',
  Utilizados: 'Used',
  usado: 'used',
  Reuniões: 'Meetings',
  'Usuários padrão': 'Default users',
  Administradores: 'Administrators',
  'Administradores de sistema': 'System administrators',
  'Novos usuários': 'New users',
  'Sem atividade': 'No activity',
  'Usuários ativos': 'Active users',
  Logados: 'Logged',
  'Nome do usuário': "User's name",
  Agendamentos: 'Appointments',
  Agora: 'Now',
  Organizadores: 'Organizers',
  'Armazenamento total': 'Total storage',
  'Horas gravadas': 'Recorded hours',
  'Quantidade de reuniões': 'Number of meetings',
};

export default enUS;
