import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  gap: 11px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 24px;
`;

export const RowContainer = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
